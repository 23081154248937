<div class="row footer_sec">
  <div class="col-12">
    <div class="footer_menu">
      <div class="footer_logo cursor-pointer" [routerLink]="['/']">
        <img src="assets/images/Logo.png"/>
      </div>
      <div class="f_menu">
        <ul>
          <li>
            <a routerLink="/artist-support">Support</a>
          </li>
          <li>
            <a href="{{appConfig.artistUrl}}" target="_blank">Sign in</a>
          </li>
          <li>
            <a href="{{appConfig.artistUrl}}" target="_blank">Get access</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
<div class="row footer_sec pt-0">
  <div class="col-12">
    <div class="footer_copyright">
      <div class="footer_main">
        <div class="privacy">
          <a (click)="showPrivacyTerms('privacy')">Privacy Policy</a>
          <a (click)="showPrivacyTerms('terms')">Terms of Use</a>
        </div>
        <div class="copyright">
          <p>©{{year}} Kwot</p>
        </div>
      </div>
      <div class="social_media">
        <ul>
          <li>
            <a href="https://www.facebook.com/kwotmusic" target="_blank">
              <img src="assets/images/facebook.svg"/>
            </a>
          </li>
          <li>
            <a href="https://www.youtube.com/@KwotMusic" target="_blank">
              <img src="assets/images/youtube.svg"/>
            </a>
          </li>
          <li>
            <a href="https://instagram.com/kwotmusic" target="_blank">
              <img src="assets/images/instagram.svg"/>
            </a>
          </li>
          <li>
            <a href="https://twitter.com/kwotmusic" target="_blank">
              <img src="assets/images/twitter.svg"/>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>

import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { catchError, map, of, switchMap } from 'rxjs';
import { LoaderService } from '@frontend/app-config';
import { PublicService } from '../services/public.service';
import {
  GetShowDetails,
  GetShowDetailsError,
  GetShowDetailsSuccess,
  GetTokenDetails,
  GetTokenDetailsError,
  GetTokenDetailsSuccess,
  GenerateRtmToken,
  GenerateRtmTokenError,
  GenerateRtmTokenSuccess,
  GetArtistDetails,
  GetArtistDetailsSuccess,
  GetArtistDetailsError
} from './public.actions';

@Injectable()
export class PublicEffects {
  constructor(
    private actions$: Actions,
    private publicService: PublicService,
    private loaderService: LoaderService
  ) {}


  getTokenDetail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GetTokenDetails),
      switchMap((action) => {
        this.loaderService.show();
        return this.publicService.getToken().pipe(
          map((resp: any) => {
            this.loaderService.hide();
            if (resp.success) {
              return GetTokenDetailsSuccess({tokenDetails: resp.data});
            }
            return GetTokenDetailsError({
              error: this.loaderService.getErrorMessage({
                error: this.loaderService.getErrorMessage(resp),
              }),
            });
          }),
          catchError((error) => {
            this.loaderService.hide();
            return of(
              GetTokenDetailsError({
                error: this.loaderService.getErrorMessage({
                  error: this.loaderService.getErrorMessage(error),
                }),
              })
            );
          })
        );
      })
    )
  );

  getShowDetail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GetShowDetails),
      switchMap((action) => {
        this.loaderService.show();
        return this.publicService.getShowById({params: action.params}).pipe(
          map((resp: any) => {
            this.loaderService.hide();
            if (resp.success) {
              return GetShowDetailsSuccess({showDetails: resp.data});
            }
            return GetShowDetailsError({
              error: this.loaderService.getErrorMessage({
                error: this.loaderService.getErrorMessage(resp),
              }),
            });
          }),
          catchError((error) => {
            this.loaderService.hide();
            return of(
              GetShowDetailsError({
                error: this.loaderService.getErrorMessage({
                  error: this.loaderService.getErrorMessage(error),
                }),
              })
            );
          })
        );
      })
    )
  );

  GenerateRtmTokens$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GenerateRtmToken),
      switchMap((action) => {
        this.loaderService.show();
        return this.publicService.GenerateRtmTokens(action.params).pipe(
          map((resp: any) => {
            console.log('resp.data' , resp.data)
            this.loaderService.hide();
            if (resp.success) {
              return GenerateRtmTokenSuccess({ rtmToken: resp.data });
            }
            return GenerateRtmTokenError({
              error: this.loaderService.getErrorMessage(resp),
            });
          }),
          catchError((error) => {
            this.loaderService.hide();
            return of(
              GenerateRtmTokenError({
                error: this.loaderService.getErrorMessage(error),
              })
            );
          })
        );
      })
    )
  );

  getArtistDetail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GetArtistDetails),
      switchMap((action) => {
        this.loaderService.show();
        return this.publicService.getArtistDetails(action.artist_id).pipe(
          map((resp: any) => {
            this.loaderService.hide();
            if (resp.success) {
              return GetArtistDetailsSuccess({artistDetails: resp.data});
            }
            return GetArtistDetailsError({
              error: this.loaderService.getErrorMessage({
                error: this.loaderService.getErrorMessage(resp),
              }),
            });
          }),
          catchError((error) => {
            this.loaderService.hide();
            return of(
              GetArtistDetailsError({
                error: this.loaderService.getErrorMessage({
                  error: this.loaderService.getErrorMessage(error),
                }),
              })
            );
          })
        );
      })
    )
  );

}

<div class="main">
  <br />
  <br />
  <br />
  <br />
  <div class="modal-body d-flex align-items-center justify-content-center flex-column">
    <br/>
    <br/>

    <h3 class="text-center">Congratulations! <br/> You have successfully purchased {{tokenDetail.tokens}} KM-Beats.</h3>
    <p class="text-center">Your account balance is now {{totalToken || 0}} KM Beats</p>
    <button class="btn-confirm active" (click)="confirm()">
      Continue
    </button>
  </div>
</div>

import {Injectable} from "@angular/core";

@Injectable({providedIn: 'root'})
export class LocalstorageService {

  userKey = '[KWOT]-kwotUser';
  commonKey = '[KWOT]-commonKey';
  registerUserKey = '[KWOT]-registerUser';
  // cartKey = '[KWOT]-cart';
  // redirectUrlKey = '[KWOT]-redirectUrl';
  // countryCodeKey = '[KWOT]-countryCode';
  // registerNumberKey = '[KWOT]-registerNumber';
  // deviceId = '[KWOT]-deviceId';
  // recentlyViewProduct = '[KWOT]-recentlyView';
  // latestWishList = '[KWOT]-latest wishList';
  // geolocationData = '[KWOT]-geolocationData';
  // recentlySearch = '[KWOT]-recent search'
  // guestUserKey = '[KWOT]-guestUser'
  stripePayment = '[KWOT]-stripePayment'
  leaveStream = '[KWOT]-leaveStream'
  currencyData = '[KWOT]-currencyData'
  // isB2B = '[KWOT]-isB2B'
  // cartDetails = '[KWOT]-cart-details'
  constructor() {
  }

  clearAllLocalStorage() {
    this.updateRegisterUser('remove');
    // this.updateRedirectUrl('remove');
    // this.updateCountryCode('remove');
    // this.updateRegisterNumber('remove');
    this.updateUserKey('remove');
    // this.updateCart('remove');
    this.updateCommonKey('remove');
    // this.updateGeolocation('remove');
    // this.updateRecentSearch('remove');
    // this.updateGuestUserKey('remove');
    this.updateStripePayment('remove');
    this.updateLeaveStream('remove');
    this.updateCurrencyData('remove');
    // this.updateWishlist('remove');
    // this.updateIsB2B('remove');
    // this.updateCartDetails('remove');
  }

  updateRegisterUser(type: string, data: any = null) {
    return LocalstorageService.updateLocalStorage(this.registerUserKey, type, data, true)
  }

  // updateCartDetails(type: string, data: any = null) {
  //   return LocalstorageService.updateLocalStorage(this.cartDetails, type, data, true)
  // }
  //
  // updateCart(type: string, data: any = null) {
  //   return LocalstorageService.updateLocalStorage(this.cartKey, type, data, true)
  // }
  //
  // updateWishlist(type: string, data: any = null) {
  //   return LocalstorageService.updateLocalStorage(this.latestWishList, type, data, true)
  // }
  //
  // updateRedirectUrl(type: string, data: any = null) {
  //   return LocalstorageService.updateLocalStorage(this.redirectUrlKey, type, data)
  // }
  //
  // updateCountryCode(type: string, data: any = null) {
  //   return LocalstorageService.updateLocalStorage(this.countryCodeKey, type, data)
  // }
  //
  // updateRegisterNumber(type: string, data: any = null) {
  //   return LocalstorageService.updateLocalStorage(this.registerNumberKey, type, data)
  // }

  updateUserKey(type: string, data: any = null) {
    return LocalstorageService.updateLocalStorage(this.userKey, type, data, true)
  }

  updateCommonKey(type: string, data: any = null) {
    return LocalstorageService.updateLocalStorage(this.commonKey, type, data, true)
  }

  // updateDeviceId(type: string, data: any = null) {
  //   return LocalstorageService.updateLocalStorage(this.deviceId, type, data, true)
  // }
  //
  // updateRecentlyViewProduct(type: string, data: any = null) {
  //   return LocalstorageService.updateLocalStorage(this.recentlyViewProduct, type, data, true)
  // }
  //
  // updateGeolocation(type: string, data: any = null) {
  //   return LocalstorageService.updateLocalStorage(this.geolocationData, type, data, true)
  // }
  //
  // updateRecentSearch(type: string, data: any = null) {
  //   return LocalstorageService.updateLocalStorage(this.recentlySearch, type, data, true)
  // }
  //
  // updateGuestUserKey(type: string, data: any = null) {
  //   return LocalstorageService.updateLocalStorage(this.guestUserKey, type, data, true)
  // }
  //
  updateStripePayment(type: string, data: any = null) {
    return LocalstorageService.updateLocalStorage(this.stripePayment, type, data, true)
  }

  updateLeaveStream(type: string, data: any = null) {
    return LocalstorageService.updateLocalStorage(this.leaveStream, type, data, true)
  }

  updateCurrencyData(type: string, data: any = null) {
    return LocalstorageService.updateLocalStorage(this.currencyData, type, data, true)
  }

  // updateIsB2B(type: string, data: any = null) {
  //   return LocalstorageService.updateLocalStorage(this.isB2B, type, data)
  // }

  private static updateLocalStorage(key: string, type: string, data: any = null, isJson = false) {
    if (type === 'store') {
      return localStorage.setItem(key, isJson ? JSON.stringify(data) : data);
    } else if (type === 'remove') {
      return localStorage.removeItem(key)
    } else if (type === 'get') {
      const data = localStorage.getItem(key);
      return isJson ? (data ? JSON.parse(data) : null) : (data || '');
    }
    return null;
  }
}

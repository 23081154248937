import {Component, Inject, OnInit} from '@angular/core';
import AgoraRTC from 'agora-rtc-sdk-ng';
import AgoraRTM from 'agora-rtm-sdk';
import {ActivatedRoute} from "@angular/router";
import {APP_CONFIG, LoaderService, SocketService} from "@frontend/app-config";
import {GenerateRtmToken, generateRtmToken, getShowDetails, GetShowDetails, PublicState} from "@frontend/public-store";
import {select, Store} from "@ngrx/store";
import {Subject, takeUntil} from "rxjs";
@Component({
  selector: 'frontend-join-live-stream',
  templateUrl: './join-live-stream.component.html',
  styleUrls: ['./join-live-stream.component.scss'],
})
export class JoinLiveStreamComponent implements OnInit {
  unsubscriber = new Subject();

  options: any = {
    appId: '', // Pass your App ID here.
    channel: '', // Set the channel name.
    token: '', // Pass your temp token here.
    uid: 0, // Set the user ID.
    role: 'audience' // Set the user role
  };
  rtmOptions: any = {}
  rtmToken = '';
  rtmTokenUid = '';
  channelParameters: any = {
    remoteAudioTrack: null, // A variable to hold a remote audio track.
    remoteVideoTrack: null, // A variable to hold a remote video track.
    remoteUid: null // A variable to hold the remote user id.s
  };
  showId: any = ''
  agoraEngine: any
  remotePlayerContainer: any
  channelRTM: any
  clientRTM: any
  public socketSubscription: any;
  messagesList: any[] = [];
  message: any;

  constructor(
    private route: ActivatedRoute,
    @Inject(APP_CONFIG) public appConfig: any,
    private publicStore: Store<PublicState>,
    private loaderService: LoaderService,
  private readonly socketService: SocketService,

  ) {
    this.socketService.resetSocketData();

    const data = {
      account: '630d944436893eeb9502c490'
    };
    this.publicStore.dispatch(GenerateRtmToken({params: data}));
    this.publicStore
          .pipe(select(getShowDetails))
          .pipe(takeUntil(this.unsubscriber))
          .subscribe((showDetails) => {
            if (showDetails) {
                this.options.token = showDetails.rtc_token
            }
          });
  }

  ngOnInit() {
    this.route.queryParams.subscribe((params: any) => {
      console.log('params.show_id' , params.show_id)
      this.showId = params.show_id
      this.publicStore.dispatch(
        GetShowDetails({
          params: {show_id: params.show_id},
        })
      );
      this.options.appId = this.appConfig.agoraAppId
      this.options.channel = decodeURI(params?.channel_name)
      this.options.uid = 0;
      this.startBasicCall().then(r => console.log('SUCCESS RESPONSE' , r)).catch(e => console.log('ERROR', e));
      this.joinRtm();
      this.subscribeToSocket()
    });

    const message = {
      action: "COMMENTS_LIST",
      payload: {
        show_id: this.showId,
        pagination: {
          page: 1,
          limit: 20
        }
      },
    };
    this.socketService.sendMessage(message);

  }

  subscribeToSocket() {
    let isCall = true;
    this.socketSubscription = this.socketService.sourceData.subscribe(
      (message: any) => {
        console.log('message' , message)

        if (message?.action === 'NEW_COMMENTS') {
          // if (message?.data.data.comment_type === 'reaction') {
          //   this.reactionCount++;
          // }
          this.messagesList.push(message?.data);
        }

        if (message?.action === 'COMMENTS_LIST') {
          this.messagesList = message.data.data
        }
      },
      (err) => console.error(err),
      () => console.warn('Completed!')
    );
    console.log('isCallisCall' , isCall)
    if (isCall) {
      const message = {
        action: "COMMENTS_LIST",
        payload: {
          show_id: this.showId,
          pagination: {
            page: 1,
            limit: 20
          }
        },
      };
      setTimeout(() => {
        this.socketService.sendMessage(message);
      }, 1000)
    }
  }

  async sendMessage(e: any) {
    const updateMessage = {
      action: "NEW_COMMENTS",
      payload: {
        sender: '630d944436893eeb9502c490',
        show_id: this.showId,
        comment: this.message
      }
    };
    this.socketService.sendMessage(updateMessage);
    this.message = ''
  }

  async startBasicCall() {
    this.agoraEngine = AgoraRTC.createClient({ mode: 'live', codec: 'vp8', role: 'audience' });
    this.remotePlayerContainer = document.createElement('div');
    this.remotePlayerContainer.style.width = '640px';
    this.remotePlayerContainer.style.height = '480px';
    this.remotePlayerContainer.style.padding = '15px 5px 5px 5px';
    this.loaderService.showUpload();

    setTimeout(async () => {
      await this.agoraEngine.join(this.options.appId, this.options.channel, this.options.token, this.options.uid);
      await this.agoraEngine.on('user-published', async (user: any, mediaType: any) => {
        await this.agoraEngine.subscribe(user, mediaType);
        if (mediaType === 'video') {
          this.channelParameters.remoteVideoTrack = user.videoTrack;
          this.channelParameters.remoteAudioTrack = user.audioTrack;
          this.channelParameters.remoteUid = user.uid.toString();
          this.remotePlayerContainer.id = user.uid.toString();
          this.channelParameters.remoteUid = user.uid.toString();
          // remotePlayerContainer.textContent = 'Remote user ' + user.uid.toString();
          const eleHost = document.getElementById("live-stream") as HTMLInputElement
          eleHost.append(this.remotePlayerContainer);
          this.channelParameters.remoteVideoTrack.play(this.remotePlayerContainer);
        }
        if (mediaType === 'audio') {
          this.channelParameters.remoteAudioTrack = user.audioTrack;
          this.channelParameters.remoteAudioTrack.play();
        }
        this.agoraEngine.on('user-unpublished', (user: any) => {
        });
      });
      window.onload = async () => {
        const eleLeave = document.getElementById("leave") as HTMLInputElement;
        eleLeave.onclick = async () => {
          this.removeVideoDiv(this.remotePlayerContainer.id);
          await this.agoraEngine.leave();
          window.location.reload();
        };
        if (this.channelParameters.localAudioTrack !== null && this.channelParameters.localVideoTrack !== null) {
          if (this.channelParameters.remoteVideoTrack !== null) {
            await this.channelParameters.localVideoTrack?.replaceTrack(this.channelParameters.remoteVideoTrack, true);
          }
        }
        await this.agoraEngine.setClientRole(this.options.role);
      }
    }, 10000)
    this.loaderService.hideUpload();

  }


  async joinRtm () {
    this.clientRTM = AgoraRTM.createInstance(this.appConfig.agoraAppId);

    this.clientRTM.on('ConnectionStateChanged', function (state: any, reason: any) {
      console.log('state', state)
      console.log('reason', reason)
    })
    console.log('this.options.channel' , this.options.channel)
    this.channelRTM = this.clientRTM.createChannel(this.options.channel);
    this.publicStore
      .pipe(select(generateRtmToken))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(async (success) => {
        if (success && success.rtm_token && success.uid) {
          console.log('success', success)
          this.rtmToken = success.rtm_token
          this.rtmTokenUid = success.uid

          this.rtmOptions = {
            uid: this.rtmTokenUid,
            token: this.rtmToken
          }
          console.log('this.rtmOptions' , this.rtmOptions)
          await this.clientRTM.login(this.rtmOptions)
          this.channelRTM.join().then(() => {
            console.error("You have successfully joined channel " + this.channelRTM.channelId)
          }).catch((err: any) => {
            console.error('ERROROROOOOOOOOOOOOOOOOOOOOOO', err);
          })
        }
      });

  }

  async leveFromShow() {
    // this.removeVideoDiv(this.remotePlayerContainer.id);
    // await this.agoraEngine.leave();
    console.log('this.channelRTM' , this.channelRTM)
    await this.clientRTM.logout()

    if (this.channelRTM !== null) {
      console.log('HERE')
      await this.channelRTM.leave()
    } else {
      console.log("Channel is empty")
    }
    window.location.reload();
  }
  removeVideoDiv(elementId: string) {
    console.log('elementId' , elementId)
    const div = document.getElementById(elementId);
    if (div) {
      div.remove();
    }
  }
}

<div class="body_bg">
  <frontend-header></frontend-header>
  <section class="about_us_top">
    <div class="container top_banner_img">
      <div class="row">
        <div class="col-12">
          <div class="about_heading">
            <h1>About us</h1>
            <p>Streaming from Africa to the World!</p>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <div class="no_music_img">
            <div class="img">
              <img src="assets/images/Captura.png" />
            </div>
            <p>
              Kwot app is an African music, podcast, video and radio
              player consisting of the best content Africa has to offer to the
              world. Stream music including the best new songs, albums and
              curated playlists on Kwot for free or download and enjoy all
              the content offline, data-free!
            </p>
          </div>
        </div>
      </div>

      <div class="row for_web">
        <div class="col-lg-3">
          <div class="support_box">
            <div class="support_ic">
              <img src="assets/images/company.svg" />
            </div>
            <h1>Music</h1>
            <p>We are focused on providing the best music and content.</p>
          </div>
        </div>
        <div class="col-lg-3">
          <div class="support_box">
            <div class="support_ic">
              <img src="assets/images/team.svg" />
            </div>
            <h1>Community</h1>
            <p>Building a community of African music lovers.</p>
          </div>
        </div>
        <div class="col-lg-3">
          <div class="support_box">
            <div class="support_ic">
              <img src="assets/images/network.svg" />
            </div>
            <h1>Discoverability</h1>
            <p>Find the best of African content on Kwot.</p>
          </div>
        </div>
        <div class="col-lg-3">
          <div class="support_box">
            <div class="support_ic">
              <img src="assets/images/graph.svg" />
            </div>
            <h1>Revenue</h1>
            <p>Earn revenue and monetize your content on Kwot.</p>
          </div>
        </div>
      </div>
      <div class="row box_for_mobile">
        <div class="col-12">
          <swiper [config]="config">
            <div class="swiper-wrapper">
              <div class="swiper-slide">
                <div class="support_box">
                  <div class="support_ic">
                    <img src="assets/images/company.svg" />
                  </div>
                  <h1>Music</h1>
                  <p>We are focused on providing the best music and content.</p>
                </div>
              </div>
              <div class="swiper-slide">
                <div class="support_box">
                  <div class="support_ic">
                    <img src="assets/images/team.svg" />
                  </div>
                  <h1>Community</h1>
                  <p>Building a community of African music lovers.</p>
                </div>
              </div>

              <div class="swiper-slide">
                <div class="support_box">
                  <div class="support_ic">
                    <img src="assets/images/network.svg" />
                  </div>
                  <h1>Discoverability</h1>
                  <p>Find the best of African content on Kwot.</p>
                </div>
              </div>

              <div class="swiper-slide">
                <div class="support_box">
                  <div class="support_ic">
                    <img src="assets/images/graph.svg" />
                  </div>
                  <h1>Revenue</h1>
                  <p>Earn revenue and monetize your content on Kwot.</p>
                </div>
              </div>
            </div>
          </swiper>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="about_img">
            <img src="assets/images/ab_img.png" />
          </div>
        </div>
        <div class="col-md-6">
          <div class="about_sub_heading">
            <p>
              At Kwot, our mission is to expose the rest of the world to the
              rhythmic beats of African music as well as the innovative and
              highly entertaining podcasts and videos. Come and discover
              new music and trending songs.
            </p>
            <p>
              The Kwot app boasts of an extensive collection of all the
              best African content from the underground all the way to the pop
              stars. Come and search for your favorite songs, artists, or
              podcasts. Kwot also allows you to play your favorite songs,
              podcasts and videos through its download feature and
              offline-listening capability.
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="about_more">
    <div class="about_more_main">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="content_main">
              <div class="sub_text">
                <p>
                  Our in-house team and editorial partners provide personalized
                  playlists curated just for listeners. Kwot knows your music
                  and content consumption taste better than anyone. You will be
                  surprised by what new music and songs our recommendations will
                  help you discover.
                </p>
                <p>
                  We are focused on supporting African artists and content
                  creators by giving them an avenue to share their content and
                  build global fan bases on our App. They are able to access
                  their analytics to determine where their content is mostly being
                  accepted.
                </p>
              </div>
              <div class="text_img">
                <img src="assets/images/Illustration.svg" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="row">
          <div class="col-md-8 order-2 order-lg-1">
            <div class="msub_heading_text">
              <p>
                The Kwot app comes with social features where you can
                follow and interact with your favourite creators and stay
                up-to-date with their news and music! With Kwot, you get
                access to a world of new African music, playlists, artists,
                podcasts and videos you would love.
              </p>
              <p>
                With unlimited music downloads, the subscriber enjoys an
                experience with unlimited downloads, so you can even listen to
                music offline, without using your data! With our premium
                subscription, the Kwot app allows you to listen to an
                album, playlist, songs or podcast without ads or ad
                breaks. Enjoy the premium playlists that are created exclusively
                for subscribers. Discover the best music and add the playlists
                to your favourites.
              </p>
            </div>
          </div>
          <div class="col-md-4 order-1 order-lg-2">
            <div class="music_img">
              <img src="assets/images/m_img.png" />
            </div>
          </div>
        </div>
      </div>
<!--      <div class="container-fluid">-->
<!--        <div class="row">-->
<!--          <div class="col-md-12">-->
<!--            <div class="team"><h1>Our team</h1></div>-->
<!--          </div>-->
<!--        </div>-->
<!--        <swiper [config]="OrganiceMusicConfig">-->
<!--          <div class="swiper-wrapper">-->
<!--            <div class="swiper-slide">-->
<!--              <div class="slide_card">-->
<!--                <div class="slide_image">-->
<!--                  <img src="assets/images/a_slide1.png" />-->
<!--                </div>-->
<!--                <div class="slide_content">-->
<!--                  <h1>New Funk</h1>-->
<!--                  <p>Marvin McKinney</p>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="swiper-slide">-->
<!--              <div class="slide_card">-->
<!--                <div class="slide_image">-->
<!--                  <img src="assets/images/a_slide2.png" />-->
<!--                </div>-->
<!--                <div class="slide_content">-->
<!--                  <h1>Xavier Ropin</h1>-->
<!--                  <p>CEO</p>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->

<!--            <div class="swiper-slide">-->
<!--              <div class="slide_card">-->
<!--                <div class="slide_image">-->
<!--                  <img src="assets/images/a_slide3.png" />-->
<!--                </div>-->
<!--                <div class="slide_content">-->
<!--                  <h1>Sandra Paimond</h1>-->
<!--                  <p>Dev Lead</p>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->

<!--            <div class="swiper-slide">-->
<!--              <div class="slide_card">-->
<!--                <div class="slide_image">-->
<!--                  <img src="assets/images/a_slide4.png" />-->
<!--                </div>-->
<!--                <div class="slide_content">-->
<!--                  <h1>Stuart Sevens</h1>-->
<!--                  <p>Public relations</p>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->

<!--            <div class="swiper-slide">-->
<!--              <div class="slide_card">-->
<!--                <div class="slide_image">-->
<!--                  <img src="assets/images/a_slide5.png" />-->
<!--                </div>-->
<!--                <div class="slide_content">-->
<!--                  <h1>Amanda Fend</h1>-->
<!--                  <p>Marketing Lead</p>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->

<!--            <div class="swiper-slide">-->
<!--              <div class="slide_card">-->
<!--                <div class="slide_image">-->
<!--                  <img src="assets/images/a_slide6.png" />-->
<!--                </div>-->
<!--                <div class="slide_content">-->
<!--                  <h1>Albert Hemsworf</h1>-->
<!--                  <p>UX · UI Design Lead</p>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->

<!--            <div class="swiper-slide">-->
<!--              <div class="slide_card">-->
<!--                <div class="slide_image">-->
<!--                  <img src="assets/images/a_slide7.png" />-->
<!--                </div>-->
<!--                <div class="slide_content">-->
<!--                  <h1>Maku Tope</h1>-->
<!--                  <p>Dev senior</p>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->

<!--            <div class="swiper-slide">-->
<!--              <div class="slide_card">-->
<!--                <div class="slide_image">-->
<!--                  <img src="assets/images/a_slide8.png" />-->
<!--                </div>-->
<!--                <div class="slide_content">-->
<!--                  <h1>Cristina Rips</h1>-->
<!--                  <p>UI Design</p>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </swiper>-->
<!--      </div>-->
      <div class="container findus_padding">
        <div class="row">
          <div class="col-md-6">
            <div class="find_us">
              <img src="assets/images/users.svg" />
              <h1>Find us</h1>
              <p>
                Get in touch by emailing <br /><a href="#">hello@kwot.com</a>
              </p>
              <ul>
                <li>
                  <a href="https://www.facebook.com/kwotmusic" target="_blank"
                    ><img src="assets/images/facebook.svg"
                  /></a>
                </li>
                <li>
                  <a
                    href="https://www.linkedin.com/company/kwotmusic"
                    target="_blank"
                    ><img src="assets/images/linkedin.svg"
                  /></a>
                </li>
                <li>
                  <a href="https://instagram.com/kwotmusic" target="_blank"
                    ><img src="assets/images/instagram.svg"
                  /></a>
                </li>
                <li>
                  <a href="https://twitter.com/kwotmusic" target="_blank"
                    ><img src="assets/images/twitter.svg"
                  /></a>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-md-6">
            <div class="find_us_content">
              <p class="find_us_title">We are all over social media on:</p>
              <div>
                <p>Facebook : <a href="https://www.facebook.com/Kwotmusic">https://www.facebook.com/Kwotmusic</a> </p>
              </div>
              <div>
                <p>Instagram : <a href="https://instagram.com/Kwotmusic">https://instagram.com/Kwotmusic</a> </p>
              </div>
              <div>
                <p>Twitter : <a href="https://twitter.com/Kwotmusic">https://twitter.com/Kwotmusic</a> </p>
              </div>
              <div>
                <p>YouTube : <a href="https://www.youtube.com/Kwotmusic">https://www.youtube.com/Kwotmusic</a> </p>
              </div>

              <div class="problem">
                <p>PROBLEMS? : <a href="mailto: hello@kwot.com">hello@kwot.com</a> </p>
              </div>

              <div>
                <p>FEEDBACK? : <a href="mailto: hello@kwot.com">hello@kwot.com</a> </p>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="footer_sec">
    <div class="container ">
      <div class="row footer_sec">
        <div class="col-12">
          <div class="footer_menu">
            <div class="footer_logo">
              <img src="assets/images/Logo.png" />
            </div>
            <div class="f_menu">
              <ul>
                <li><a routerLink="/plans">Plans</a></li>
                <li><a routerLink="/support">Support</a></li>
                <li><a routerLink="/artist">Are you an artist?</a></li>
                <li><a routerLink="/about-us">About Us</a></li>
                <li><a routerLink="/contact-us">Contact Us</a></li>
                <li><a routerLink="/sign-in">Sign in</a></li>
                <li><a href="#">Get access</a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="row footer_sec pt-0">
        <div class="col-12">
          <div class="footer_copyright">
            <div class="footer_main">
              <!-- <div class="privacy">
                <a (click)="showPrivacyTerms('privacy')">Privacy Policy</a>
                <a (click)="showPrivacyTerms('terms')">Terms of Use</a>
              </div> -->
              <div class="privacy">
                <a routerLink="/privacy-policy">Privacy Policy</a>
                <a routerLink="/terms-conditions">Terms of Use</a>
              </div>

              <div class="copyright">
                <p>©2023 Kwot</p>
              </div>
            </div>
            <div class="social_media">
              <ul>
                <li>
                  <a href="https://www.facebook.com/kwotmusic" target="_blank"
                    ><img src="assets/images/facebook.svg"
                  /></a>
                </li>
                <li>
                  <a href="https://www.linkedin.com/company/kwotmusic" target="_blank"
                    ><img src="assets/images/linkedin.svg"
                  /></a>
                </li>
                <li>
                  <a href="https://instagram.com/kwotmusic" target="_blank"
                    ><img src="assets/images/instagram.svg"
                  /></a>
                </li>
                <li>
                  <a href="https://twitter.com/kwotmusic" target="_blank"
                    ><img src="assets/images/twitter.svg"
                  /></a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

  </section>
</div>


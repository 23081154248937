import {Component, HostListener} from '@angular/core';
import {SwiperOptions} from 'swiper';

@Component({
  selector: 'frontend-plans',
  templateUrl: './plans.component.html',
  styleUrls: ['./plans.component.scss'],
})
export class PlansComponent {
  config: SwiperOptions = {
    // pagination: {
    //   el: '.swiper-pagination',
    //   clickable: true
    // },
    // navigation: {
    //   nextEl: '.swiper-button-next',
    //   prevEl: '.swiper-button-prev'
    // },
    autoplay: true,
    spaceBetween: 20,
    slidesPerView: 1.5,
    breakpoints: {
      992: {
        slidesPerView: 3,
      },
      768: {
        slidesPerView: 2,
      }

    }
  };

  scrollToBottom() {
    window.scrollTo(0, document.body.scrollHeight);
  }

  scrollToElement(element: any) {
    element.scrollIntoView({behavior: "smooth"});
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    const number = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    if (number > 100) {
      document.querySelectorAll('.appendClass').forEach((c) => {
        c.classList.add('text-smaller');
      });
    } else {
      document.querySelectorAll('.appendClass').forEach((c) => {
        c.classList.remove('text-smaller');
      });
    }
  }
}

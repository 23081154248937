import {Component} from '@angular/core';

@Component({
  selector: 'frontend-callback-signin-apple',
  templateUrl: './callback-signin-apple.component.html',
  styleUrls: ['./callback-signin-apple.component.scss'],
})
export class CallbackSigninAppleComponent {

  constructor(
  ) {}

}

import {Component} from '@angular/core';
import {animate, keyframes, state, style, transition, trigger} from "@angular/animations";
import {Toast} from "ngx-toastr";

@Component({
  selector: 'frontend-toast',
  styles: [],
  template: `
    <div>
      <div *ngIf="message && options.enableHtml" role="alert" aria-live="polite"
           [class]="options.messageClass" [innerHTML]="message">
      </div>
      <div *ngIf="message && !options.enableHtml" role="alert" aria-live="polite"
           [class]="options.messageClass" [attr.aria-label]="message">
        {{ message }}
      </div>
    </div>
    <div class="toast_cross_icon">
      <button type="button" *ngIf="options.closeButton" aria-label="Close" (click)="remove()"
              class="toast-close-button">
        <img src="assets/images/white_cross.png"/>
      </button>
    </div>
  `,
  animations: [
    trigger('flyInOut', [
      state('inactive', style({
        opacity: 0,
      })),
      transition('inactive => active', animate('400ms ease-in', keyframes([
        style({
          transform: 'translate3d(100%, 0, 0) skewX(-30deg)',
          opacity: 0,
        }),
        style({
          transform: 'skewX(20deg)',
          opacity: 1,
        }),
        style({
          transform: 'skewX(-5deg)',
          opacity: 1,
        }),
        style({
          transform: 'none',
          opacity: 1,
        }),
      ]))),
      transition('active => removed', animate('400ms ease-out', keyframes([
        style({
          opacity: 1,
        }),
        style({
          transform: 'translate3d(100%, 0, 0) skewX(30deg)',
          opacity: 0,
        }),
      ]))),
    ]),
  ],
  preserveWhitespaces: false,
})
export class ToastComponent extends Toast {

}

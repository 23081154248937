import {
  AfterViewInit,
  Component,
  EventEmitter,
  Inject,
  Input,
  Output, Renderer2,
} from '@angular/core';
import {APP_CONFIG, LoaderService} from "@frontend/app-config";
import {DOCUMENT} from "@angular/common";

@Component({
  selector: 'frontend-alat-pay',
  templateUrl: './alat-pay.component.html',
  styleUrls: ['./alat-pay.component.scss']
})
export class AlatPayComponent implements AfterViewInit {
  customerDetails: any;
  customizations: any;
  meta: any;

  @Input() tokenDetails: any;
  @Input() currentuser: any;
  @Input() calculatedAmount: any;
  @Output() paymentConfirmed = new EventEmitter();
  @Input() currencyData: any;
  popup: any;

  constructor(
    @Inject(APP_CONFIG) public appConfig: any,
    private loaderService: LoaderService,
    private renderer: Renderer2,
    @Inject(DOCUMENT) private document: Document
  ) {
  }

  ngAfterViewInit() {
    this.loaderService.show();
    const scriptElement = this.loadJsScript();
    scriptElement.onload = () => {
      this.loaderService.hide();
    }
    scriptElement.onerror = () => {
      this.loaderService.hide();
      console.log('Could not load the Script!');
    }
  }

  payViaPromise() {
    let firstName = 'Guest';
    let lastName = 'Guest';
    if (this.currentuser) {
      if (this.currentuser.name) {
        const name = this.currentuser.name.split(/(.{25})/).filter((O: any) => O);
        if (name[0]) {
          firstName = name[0];
        }
        if (name[1]) {
          lastName = name[1];
        }
      } else {
        firstName = this.currentuser.first_name || '';
        lastName = this.currentuser.last_name || '';
      }
      this.customerDetails = {firstName, lastName, email: this.currentuser?.email || '', phone_number: this.currentuser?.phone || ''}
    } else {
      this.customerDetails = {
        firstName: 'Guest',
        lastName: 'User',
        email: 'guest@yopmail.com'
      }
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.popup = Alatpay.setup({
      apiKey: this.appConfig.alatPayApiKey, // subscription primary key or secondary key
      businessId: this.appConfig.alatPayBusinessId,// business Id
      email: this.customerDetails.email, // [Required] customer email
      // phone: "2347012345678",   // customer phone number (optional)
      firstName: this.customerDetails.firstName,        // customer name (Optional)
      lastName: this.customerDetails.lastName,         // customer last name   (Optional)
      metadata: this.tokenDetails, //Pass in extra info for the purpose warehousing   (Optional)
      currency: "NGN", //Specify currency
      amount: Number(Number(this.calculatedAmount).toFixed(2)), // [Required] Payment Amount

      // callback on tranaction(optional). response payload is similar to callBackUrl payload
      onTransaction: (response: any) => {
        if (response.data.status.toLowerCase() === 'completed' || response.data.status.toLowerCase() === 'pending') {
          this.paymentConfirmed.next(response.data);
          this.popup.onClose();
        }
      },

      // callback on dialog closed. (optional)
      onClose: () => {
        console.log("Payment gateway is closed")
      }
    });
    this.popup.show();
  }

  loadJsScript(): HTMLScriptElement {
    const script = this.renderer.createElement('script');
    script.type = 'text/javascript';
    script.src = this.appConfig.alatPayUrl;
    this.renderer.appendChild(this.document.body, script);
    return script;
  }
}

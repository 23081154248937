import {Component, Inject} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {APP_CONFIG} from "@frontend/app-config";

@Component({
  selector: 'frontend-artist-header',
  templateUrl: './artist-header.component.html',
  styleUrls: ['./artist-header.component.scss'],
})
export class ArtistHeaderComponent {
  currentRoute: string;

  constructor(private router: Router, @Inject(APP_CONFIG) public appConfig: any) {
    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        const url = event.url.split('/').filter((t) => t);
        if (url[0]) {
          this.currentRoute = url[0].indexOf('?') !== -1 ? url[0].substr(0, url[0].indexOf('?')) : url[0];
        } else {
          this.currentRoute = '';
        }
      }
    });
  }
}

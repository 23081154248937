<div class="row app_ad align-items-center pt-150">
  <div class="col-md-12 col-lg-8">
    <div class="find_music">
      <h1>
        Discover the latest in exclusive African<br />
        Content!
      </h1>
    
    </div>
  </div>
  <div class="col-md-12 col-lg-4">
    <div class="download_app">
      <p>Download the App</p>
      <div class="app_icons">
        <a
          href="https://apps.apple.com/us/app/kwot-music/id1641283024"
          target="_blank"
        ><img src="assets/images/appstore.png"
        /></a>
        <a
          href="https://play.google.com/store/apps/details?id=com.kwot.music.android"
          target="_blank"
        ><img src="assets/images/play_store.png"
        /></a>
      </div>
    </div>
  </div>
</div>
<div class="row footer_sec">
  <div class="col-12">
    <div class="footer_menu">
      <div class="footer_logo cursor-pointer" [routerLink]="['/']">
        <img src="assets/images/Logo.png"/>
      </div>
      <div class="f_menu">
        <ul>
          <li><a routerLink="/plans">Plans</a></li>
          <li><a routerLink="/support">Support</a></li>
          <li><a routerLink="/artist">Are you a creator?</a></li>
          <li><a routerLink="/about-us">About Us</a></li>
          <li><a routerLink="/contact-us">Contact Us</a></li>
          <li><a>Sign in</a></li>
          <li><a [routerLink]="['artist']">Get access</a></li>
        </ul>
      </div>
    </div>
  </div>
</div>
<div class="row footer_sec pt-0">
  <div class="col-12">
    <div class="footer_copyright">
      <div class="footer_main">
        <!-- <div class="privacy">
          <a (click)="showPrivacyTerms('privacy')">Privacy Policy</a>
          <a (click)="showPrivacyTerms('terms')">Terms of Use</a>
        </div> -->
        <div class="privacy">
          <a routerLink="/privacy-policy">Privacy Policy</a>
          <a routerLink="/terms-conditions">Terms of Use</a>
        </div>

        <div class="copyright">
          <p>©{{year}} Kwot</p>
        </div>
      </div>
      <div class="social_media">
        <ul>
          <li>
            <a href="https://www.facebook.com/kwotmusic" target="_blank"
            ><img src="assets/images/facebook.svg"
            /></a>
          </li>
          <li>
            <a href="https://www.linkedin.com/company/kwotmusic" target="_blank"
            ><img src="assets/images/linkedin.svg"
            /></a>
          </li>
          <li>
            <a href="https://instagram.com/kwotmusic" target="_blank"
            ><img src="assets/images/instagram.svg"
            /></a>
          </li>
          <li>
            <a href="https://twitter.com/kwotmusic" target="_blank"
            ><img src="assets/images/twitter.svg"
            /></a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>

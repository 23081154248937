<ng-container *ngIf="tag === 'p'">
  <p [class]="class">
    <ng-container [ngTemplateOutlet]="priceData"></ng-container>
  </p>
</ng-container>
<ng-container *ngIf="tag === 'span'">
  <span [class]="class"><ng-container
    [ngTemplateOutlet]="priceData"></ng-container></span>
</ng-container>
<ng-container *ngIf="tag === 'h6'">
  <h6 [class]="class">
    <ng-container [ngTemplateOutlet]="priceData"></ng-container>
  </h6>
</ng-container>
<ng-container *ngIf="tag === 'h5'">
  <h5 [class]="class">
    <ng-container [ngTemplateOutlet]="priceData"></ng-container>
  </h5>
</ng-container>
<ng-container *ngIf="tag === 'h4'">
  <h4 [class]="class">
    <ng-container [ngTemplateOutlet]="priceData"></ng-container>
  </h4>
</ng-container>
<ng-container *ngIf="tag === 'h3'">
  <h3 [class]="class">
    <ng-container [ngTemplateOutlet]="priceData"></ng-container>
  </h3>
</ng-container>
<ng-container *ngIf="tag === 'h2'">
  <h2 [class]="class">
    <ng-container [ngTemplateOutlet]="priceData"></ng-container>
  </h2>
</ng-container>
<ng-container *ngIf="tag === 'h1'">
  <h1 [class]="class">
    <ng-container [ngTemplateOutlet]="priceData"></ng-container>
  </h1>
</ng-container>
<ng-container *ngIf="tag === 'div'">
  <div [class]="class">
    <ng-container [ngTemplateOutlet]="priceData"></ng-container>
  </div>
</ng-container>

<ng-template #priceData>
  {{(price) | currencyConvert:{
  type: type,
  item: item
} | async}}
</ng-template>




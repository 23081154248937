import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
@Component({
  selector: 'frontend-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  currentRoute: string;
  constructor(private router: Router) {
    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        const url = event.url.split('/').filter((t) => t);
        if (url[0]) {
          this.currentRoute = url[0].indexOf('?') !== -1 ? url[0].substr(0, url[0].indexOf('?')): url[0];
        } else {
          this.currentRoute = '';
        }
      }
    });
  }

  scrollToBottom() {
    window.scrollTo(0, 400);
  }
}

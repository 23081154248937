import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { APP_CONFIG } from '@frontend/app-config';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  ApiUrl!: string;
  getCountriesData: any;

  constructor(
    private http: HttpClient,
    @Inject(APP_CONFIG) private appConfig: any
  ) {
    this.ApiUrl = `${this.appConfig.apiUrl}`;
  }

  register(user: any) {
    let Api;

    if (user.email) {
      Api = 'account/signup/email';
    } else {
      Api = 'account/signup/phone';
    }

    return this.http.post(`${this.ApiUrl}${Api}`, user, {
      headers: new HttpHeaders().set('Skip-auth', 'true'),
    });
  }

  validateOtp(otp: any) {
    let url = '';
    const headers = new HttpHeaders().set('Skip-auth', 'true');

    url = 'account/signup/phone/verify';
    return this.http.post(`${this.ApiUrl}/${url}`, otp, {
      headers: headers,
    });
  }

  login(user: any) {
    let Api;

    if (user.email) {
      if (user.provider) {
        Api = 'account/socialLogin'
      } else {
        Api = 'account/signin/email';
      }
    } else {
      Api = 'account/signin/phone';
    }
    return this.http.post(`${this.ApiUrl}${Api}`, user, {
      headers: new HttpHeaders().set('Skip-auth', 'true'),
    });
  }

  sendOtp(phone_number: string, country_code: string) {
    return this.http.post(
      `${this.ApiUrl}/account/signup/phone/otp`,
      {
        phone_number: phone_number.toString(),
        country_code: country_code.toString(),
      },
      {
        headers: new HttpHeaders().set('Skip-auth', 'true'),
      }
    );
  }

  forgotPassword(user: any) {
    let Api;
    if (user.email) {
      Api = 'account/recovery/email';
    } else {
      Api = 'account/recovery/phone';
    }
    return this.http.post(`${this.ApiUrl}${Api}`, user, {
      headers: new HttpHeaders().set('Skip-auth', 'true'),
    });
  }

  resetPassword(passwordBody: any) {
    let url = '';
    let headers = new HttpHeaders().set('Skip-auth', 'true');
    if (passwordBody.id) {
      url = `account/reset-password-by-reset-link`;
    } else {
      url = 'account/recovery/set-password';
      headers = headers.append('AddTempAuth', 'true');
    }
    return this.http.put(
      `${this.ApiUrl}${url}`,
      { ...passwordBody },
      { headers }
    );
  }

  checkAccountVerifyLink(token: string) {
    return this.http.get(
      `${this.ApiUrl}/account/profile/add-email/verify/${token}`,
      {
        headers: new HttpHeaders().set('Skip-auth', 'true'),
      }
    );
  }

  verifyData(params: any) {
    return this.http.post(
      `${this.ApiUrl}account/sendProfileVerification`,
      params,
      {
        headers: new HttpHeaders().set('Skip-auth', 'true'),
      }
    );
  }

  getCountryId() {
    return this.http.get(`${this.ApiUrl}/miscellaneous/countries`, {
      headers: new HttpHeaders().set('Skip-auth', 'true'),
    });
  }

  getProvincesByCountry(countryId: string) {
    return this.http.get(
      `${this.ApiUrl}/miscellaneous/countries/${countryId}/provinces`,
      {
        headers: new HttpHeaders().set('Skip-auth', 'true'),
      }
    );
  }

  getPrivacy() {
    return this.http.get(`${this.ApiUrl}policy`, {
      headers: new HttpHeaders().set('Skip-auth', 'true'),
    });
  }

  contactUs(user: any) {
    return this.http.post(`${this.ApiUrl}contact`, user, {
      // headers: new HttpHeaders().set('Skip-auth', 'true'),
    });
  }

  getFiltersOptionData(params: any = {}) {
    return this.http.get(`${this.ApiUrl}/music/filters-option`, { params });
  }

  getMiscellaneousCountries(params: any = {}) {
    return this.http.get(`${this.ApiUrl}/miscellaneous/countries`, { params });
  }

  getMiscellaneousProvinces(id: string) {
    return this.http.get(
      `${this.ApiUrl}/miscellaneous/countries/${id}/provinces`
    );
  }

  loginWithToken(token: string) {
    return this.http.post(`${this.ApiUrl}/account/login_with_token`, { token });
  }
}

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {StoreModule} from "@ngrx/store";
import {EffectsModule} from "@ngrx/effects";
import * as fromPublic from "./+state/public.reducer";
import {PublicEffects} from "./+state/public.effects";

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('public', fromPublic.reducer),
    EffectsModule.forFeature([PublicEffects])
  ],
})
export class PublicStoreModule {
}

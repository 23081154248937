<div class="body_bg">
  <frontend-artist-header></frontend-artist-header>
  <section class="artist_header_main">
    <div class="container top_banner_img">
      <div class="row content_zindex">
        <div class="col-md-12 col-lg-6 order-md-2 order-2 order-lg-1">
          <div class="section_content">
            <h1>Share your <br/>content to the world.</h1>
            <p>
              Join this great African content community and<br/>
              share your art with a multitude of people.
            </p>
            <a href="{{appConfig.artistUrl}}" target="_blank">
              <button class="btn-action">Get access</button>
            </a>
          </div>
        </div>

        <div class="col-md-12 col-lg-6 order-md-1 order-1 order-lg-2">
          <div class="artist_banner_right_img">
            <div class="banner_img">
              <img src="assets/images/artist_banner.png "/>
            </div>
          </div>
        </div>
      </div>

      <div class="row mt-190 mb-200 premium_for_web">
        <div class="col-lg-3">
          <div class="premium_box">
            <div class="premium_icon">
              <img src="assets/images/comunity.svg"/>
            </div>
            <h1>
              Large <br/>
              Community
            </h1>
          </div>
        </div>

        <div class="col-lg-3">
          <div class="premium_box">
            <div class="premium_icon">
              <img src="assets/images/control_number.svg"/>
            </div>
            <h1>
              Control your <br/>
              numbers
            </h1>
          </div>
        </div>

        <div class="col-lg-3">
          <div class="premium_box">
            <div class="premium_icon">
              <img src="assets/images/status-up.svg"/>
            </div>
            <h1>
              Get best <br/>
              stats
            </h1>
          </div>
        </div>

        <div class="col-lg-3">
          <div class="premium_box">
            <div class="premium_icon">
              <img src="assets/images/fan.svg"/>
            </div>
            <h1>
              Learn from your <br/>
              fans
            </h1>
          </div>
        </div>
      </div>

      <div class="row premium_for_mobile">
        <div class="col-12">
          <swiper [config]="configPriceConfig">
            <div class="swiper-wrapper">
              <div class="swiper-slide">
                <div class="premium_box">
                  <div class="premium_icon">
                    <img src="assets/images/comunity.svg"/>
                  </div>
                  <h1>
                    Large <br/>
                    Community
                  </h1>
                </div>
              </div>

              <div class="swiper-slide">
                <div class="premium_box">
                  <div class="premium_icon">
                    <img src="assets/images/control_number.svg"/>
                  </div>
                  <h1>Control your <br/>numbers</h1>
                </div>
              </div>

              <div class="swiper-slide">
                <div class="premium_box">
                  <div class="premium_icon">
                    <img src="assets/images/status-up.svg"/>
                  </div>
                  <h1>
                    Get best <br/>
                    stats
                  </h1>
                </div>
              </div>

              <div class="swiper-slide">
                <div class="premium_box">
                  <div class="premium_icon">
                    <img src="assets/images/fan.svg"/>
                  </div>
                  <h1>Learn from your <br/>fans</h1>
                </div>
              </div>
            </div>
          </swiper>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-4">
          <div class="play_list_radio">
            <div class="radio_icon">
              <img src="assets/images/radio.svg"/>
            </div>
            <div class="radio_detials">
              <h1>Playlists, Radio, Video & Podcasts</h1>
              <p>
                Helping African content creators scale their audience. Start
                uploading now.
              </p>
              <a href="{{appConfig.artistUrl}}" target="_blank">
                <button class="btn-action">Get access</button>
              </a>
            </div>
          </div>
        </div>
        <div class="col-lg-8">
          <div class="radio_playlist_img">
            <img src="assets/images/Images.png"/>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="share_music_slider_section">
    <div class="container">
      <div class="music_slider">
        <swiper [config]="shareMusicConfig">
          <div class="swiper-wrapper">
            <div class="swiper-slide">
              <div class="swiper_slide_content">
                <div class="swiper_img">
                  <img src="assets/images/landing_artist_share.png"/>
                </div>
                <div class="swiper_detail_content">
                  <h1>
                    Build a global<br/>
                    fanbase.
                  </h1>
                  <p>
                    Join the Kwot community and <br/>
                    share your content to the rest of the world.
                  </p>
                  <a href="{{appConfig.artistUrl}}" target="_blank">
                    <button class="btn-action">Get access</button>
                  </a
                  >
                </div>
              </div>
            </div>
          </div>
        </swiper>
      </div>
    </div>
  </section>

  <section class="create_show_live">
    <div class="container">
      <div class="row live_containers">
        <div class="col-md-12 col-lg-4">
          <div class="create_live_heading">
            <div class="live_icon"><img src="assets/images/live.png"/></div>
            <div class="live_heading">
              <h1>
                Create live shows <br/>
                for your viewers
              </h1>
              <p>Interact with them live <br/>from anywhere</p>
              <a href="{{appConfig.artistUrl}}" target="_blank" class="btn-action p-3">Create your live show</a>
            </div>
          </div>
        </div>
        <div class="col-md-12 col-lg-8">
          <div class="shows_images">
            <div class="showcase_mobile">
              <img src="assets/images/showcase_mobile.png"/>
            </div>
            <div class="showcase_mobile_comments">
              <img src="assets/images/comments.png"/>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container">
      <div class="row">
        <div class="col-lg-4 offset-lg-4 col-md-12">
          <div class="organise_music">
            <div class="organise_icon">
              <img src="assets/images/playlist_book.png"/>
            </div>
            <div class="organise_icon_details">
              <h1>Curated playlists!</h1>
              <p>Create or curate unique compilations for your fans.</p>
              <a href="{{appConfig.artistUrl}}" target="_blank">
                <button class="btn-action">Get access</button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <div class="container-fluid mt-75">
    <swiper [config]="OrganiceMusicConfig">
      <div class="swiper-wrapper">
        <div class="swiper-slide">
          <div class="slide_card">
            <div class="slide_image"><img src="assets/images/Pic_1.png"/></div>
            <div class="slide_content">
              <h1>No Stress</h1>
              <p>Robert Fox</p>
            </div>
          </div>
        </div>
        <div class="swiper-slide">
          <div class="slide_card">
            <div class="slide_image"><img src="assets/images/Pic_2.png"/></div>
            <div class="slide_content">
              <h1>Sport Motivation</h1>
              <p>Jacob Jones</p>
            </div>
          </div>
        </div>

        <div class="swiper-slide">
          <div class="slide_card">
            <div class="slide_image"><img src="assets/images/Pic_3.png"/></div>
            <div class="slide_content">
              <h1>Hip-Hop</h1>
              <p>Eleanor Pena</p>
            </div>
          </div>
        </div>

        <div class="swiper-slide">
          <div class="slide_card">
            <div class="slide_image"><img src="assets/images/Pic_4.png"/></div>
            <div class="slide_content">
              <h1>Soirée Afro</h1>
              <p>Brooklyn Simmons</p>
            </div>
          </div>
        </div>

        <div class="swiper-slide">
          <div class="slide_card">
            <div class="slide_image"><img src="assets/images/Pic_5.png"/></div>
            <div class="slide_content">
              <h1>New Afro</h1>
              <p>Jerome Bell</p>
            </div>
          </div>
        </div>

        <div class="swiper-slide">
          <div class="slide_card">
            <div class="slide_image"><img src="assets/images/Pic_6.png"/></div>
            <div class="slide_content">
              <h1>Top Rap party</h1>
              <p>Savannah Nguyen</p>
            </div>
          </div>
        </div>

        <div class="swiper-slide">
          <div class="slide_card">
            <div class="slide_image"><img src="assets/images/Pic_7.png"/></div>
            <div class="slide_content">
              <h1>New Funk</h1>
              <p>Marvin McKinney</p>
            </div>
          </div>
        </div>

        <div class="swiper-slide">
          <div class="slide_card">
            <div class="slide_image"><img src="assets/images/Pic_7.png"/></div>
            <div class="slide_content">
              <h1>New Funk</h1>
              <p>Marvin McKinney</p>
            </div>
          </div>
        </div>

        <div class="swiper-slide">
          <div class="slide_card">
            <div class="slide_image"><img src="assets/images/Pic_7.png"/></div>
            <div class="slide_content">
              <h1>New Funk</h1>
              <p>Marvin McKinney</p>
            </div>
          </div>
        </div>
      </div>
    </swiper>
  </div>
  <section class="financial_benifit" id="payments">
    <div class="container">
      <div class="row">
        <div class="col-md-12 col-lg-6">
          <div class="financial_benifit_heading">
            <div class="financial_icon">
              <img src="assets/images/finance.svg"/>
            </div>
            <div class="financial_benifit_details">
              <h1>Financial benefits</h1>
              <ul>
                <li>Make money based on your streams</li>
                <li>Sell your live shows</li>
                <li>Monetize your global audience.</li>
              </ul>
              <a href="{{appConfig.artistUrl}}" target="_blank">
                <button class="btn-action">Get access</button>
              </a>
            </div>
            <div class="finance_icon">
              <ul>
                <li>
                  <img src="assets/images/up.svg"/>
                </li>
                <li>
                  <img src="assets/images/same.svg"/>
                </li>
                <li>
                  <img src="assets/images/down.svg"/>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-md-12 col-lg-6">
          <div class="financial_benifit_image">
            <img src="assets/images/f_benifit.png"/>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="faq">
    <div class="container">
      <div class="row">
        <div class="col-md-12 col-lg-4">
          <div class="faqs_details">
            <div class="faq_icon">
              <img src="assets/images/chat.svg"/>
            </div>
            <h1>Frequently asked questions</h1>
            <p>Have questions? We have answers!</p>
          </div>
        </div>
        <div class="col-md-12 col-lg-8">
          <div class="faqs_list">
            <ul *ngIf="!isShowAll">
              <li *ngFor="let faq of faqs.slice(0, 4); let i = index">
                {{ faq }}
              </li>
            </ul>

            <ul *ngIf="isShowAll">
              <li *ngFor="let faq of faqs; let i = index">{{ faq }}</li>
            </ul>

            <div
              class="all_faq cursor-pointer"
              [routerLink]="['/artist-support']"
            >
              <a (click)="showAllFaqs()"
              >{{
                !isShowAll
                  ? 'See all FAQs'
                  : 'See less FAQs'
                }}
                <span><img src="assets/images/arrow.png"/></span
                ></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="review_section">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="community">
            <h1>Building the largest community of African creators.</h1>
            <p>
              A global community brought together through their love for African
              music.
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="tutorial_slider container-fluid">
      <swiper [config]="TutorialConfig">
        <div class="swiper-wrapper">
          <div class="swiper-slide">
            <div class="slide_data">
              <div class="client_star">
                <img src="assets/images/client_star.png"/>
              </div>
              <div class="client_feedback">
                <p>
                  “Hands down the best streaming app for all things African!”
                </p>
                <h1>Tunde Balogun</h1>
              </div>
            </div>
          </div>

          <div class="swiper-slide">
            <div class="slide_data">
              <div class="client_star">
                <img src="assets/images/client_star.png"/>
              </div>
              <div class="client_feedback">
                <p>“Finally an audio comedy experience! Kwot for the win!”</p>
                <h1>Mary Okoye</h1>
              </div>
            </div>
          </div>
          <div class="swiper-slide">
            <div class="slide_data">
              <div class="client_star">
                <img src="assets/images/client_star.png"/>
              </div>
              <div class="client_feedback">
                <p>
                  “I can't believe I can finally listen to my favorite Nigeria
                  radio stations right here in New York!”
                </p>
                <h1>Funke Ladipo</h1>
              </div>
            </div>
          </div>
        </div>
      </swiper>
    </div>
  </section>

  <section class="artist_footer_section">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="section_footer_heading">
            <h1>
              Start sharing your amazing content now!
            </h1>

            <a href="{{appConfig.artistUrl}}" target="_blank">
              <button class="btn-action">Get access</button>
            </a>
          </div>
        </div>
      </div>
      <frontend-artist-footer></frontend-artist-footer>
    </div>
  </section>
</div>

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {StripeComponent} from "./components/stripe/stripe.component";
import {NgxStripeModule} from "ngx-stripe";
import {FlutterwaveModule} from "flutterwave-angular-v3";
import {FlutterwavePaymentComponent} from "./components/flutterwave-payment/flutterwave-payment.component";
import {AlatPayComponent} from "./components/alat-pay/alat-pay.component";
import {PaymentsComponent} from "./components/payments/payments.component";
import {SharedModule} from "@frontend/shared";

@NgModule({
  imports: [
    CommonModule,
    NgxStripeModule.forRoot(),
    FlutterwaveModule,
    SharedModule
  ],
  declarations: [StripeComponent, FlutterwavePaymentComponent, AlatPayComponent, PaymentsComponent],
  exports: [StripeComponent, FlutterwavePaymentComponent, AlatPayComponent, PaymentsComponent]
})
export class PaymentGatwaysModule {
}

import {Component, Input} from '@angular/core';

@Component({
  selector: 'frontend-currency',
  templateUrl: './currency.component.html',
  styleUrls: ['./currency.component.scss']
})
export class CurrencyComponent {
  @Input() price: any;
  @Input() tag: any;
  @Input() class: any;
  @Input() type: any;
  @Input() item: any;

  constructor() {
  }

}

import {createFeatureSelector, createSelector} from '@ngrx/store';
import {AuthState} from './auth.reducer';

const getAuthState = createFeatureSelector<AuthState>('auth');

export const getAuthError = createSelector(
  getAuthState,
  (state: AuthState) => state?.error
);

export const getAuthSuccess = createSelector(
  getAuthState,
  (state: AuthState) => state?.success
);

export const getIsForgotPassword = createSelector(
  getAuthState,
  (state: AuthState) => state?.isforgotPassword
);

export const getIsPasswordUpdated = createSelector(
  getAuthState,
  (state: AuthState) => state?.isPasswordUpdated
);

export const getToken = createSelector(
  getAuthState,
  (state: AuthState) => state?.loginUser?.token
);

export const getRegisterUser = createSelector(
  getAuthState,
  (state: AuthState) => state?.registerUser
);

export const getLoggedInUser = createSelector(
  getAuthState,
  (state: AuthState) => state?.loginUser
);

export const getSignInWithSocialUser = createSelector(
  getAuthState,
  (state: AuthState) => state?.signInUserSocial
);

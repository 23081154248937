import {createAction, props} from '@ngrx/store';

const PublicActions = {

  GET_TOKEN_DETAILS: '[PUBLIC] Get Token Details',
  GET_TOKEN_DETAILS_SUCCESS:'[PUBLIC] Get Token Details Success',
  GET_TOKEN_DETAILS_ERROR:'[PUBLIC] Get Token Details Error',

  GET_SHOW_DETAILS: '[PUBLIC] Get Show Details',
  GET_SHOW_DETAILS_SUCCESS:'[PUBLIC] Get Show Details Success',
  GET_SHOW_DETAILS_ERROR:'[PUBLIC] Get Show Details Error',

  GENERATE_RTM_TOKEN: '[PUBLIC] Generate Rtm Token',
  GENERATE_RTM_TOKEN_SUCCESS: '[PUBLIC] Generate Rtm Token Success',
  GENERATE_RTM_TOKEN_ERROR: '[PUBLIC] Generate Rtm Token Error',

  GET_ARTIST_DETAILS: '[PUBLIC] Get Artist Details',
  GET_ARTIST_DETAILS_SUCCESS:'[PUBLIC] Get Artist Details Success',
  GET_ARTIST_DETAILS_ERROR:'[PUBLIC] Get Artist Details Error',

  RESET_PUBLIC_STATE: '[PUBLIC] Reset public State',
};

export const GetTokenDetails = createAction(PublicActions.GET_TOKEN_DETAILS);
export const GetTokenDetailsSuccess = createAction(PublicActions.GET_TOKEN_DETAILS_SUCCESS, props<{ tokenDetails: any }>());
export const GetTokenDetailsError = createAction(PublicActions.GET_TOKEN_DETAILS_ERROR, props<{ error: string }>());

export const GetShowDetails = createAction(PublicActions.GET_SHOW_DETAILS, (params: any = {}) => params);
export const GetShowDetailsSuccess = createAction(PublicActions.GET_SHOW_DETAILS_SUCCESS, props<{ showDetails: any }>());
export const GetShowDetailsError = createAction(PublicActions.GET_SHOW_DETAILS_ERROR, props<{ error: string }>());

export const GenerateRtmToken = createAction(PublicActions.GENERATE_RTM_TOKEN,(params: any = {}) => params );
export const GenerateRtmTokenSuccess = createAction(PublicActions.GENERATE_RTM_TOKEN_SUCCESS,props<{ rtmToken: any }>());
export const GenerateRtmTokenError = createAction(PublicActions.GENERATE_RTM_TOKEN_ERROR,props<{ error: string }>());

export const GetArtistDetails = createAction(PublicActions.GET_ARTIST_DETAILS, props<{ artist_id: string }>());
export const GetArtistDetailsSuccess = createAction(PublicActions.GET_ARTIST_DETAILS_SUCCESS, props<{ artistDetails: any }>());
export const GetArtistDetailsError = createAction(PublicActions.GET_ARTIST_DETAILS_ERROR, props<{ error: string }>());

export const ResetPublicState = createAction(PublicActions.RESET_PUBLIC_STATE, (params: any = {}) => params);

<div class="body_bg">
  <frontend-header></frontend-header>
  <section class="plan_header_section">
    <div class="container plan_banner">
      <div class="row">
        <div class="col-12">
          <div class="plan_banner_heading">
            <h1>
              Discover the latest in exclusive African<br />
              Content!
            </h1>
            <p>
              With Kwot you can listen to the songs you love, discover new
              podcasts and laugh out loud to the best in video from all over
              Africa all in one place. Download now from the Google Play store
              or Apple App store to start listening.
            </p>
            <div class="plan_header_btn">
              <button class="btn-action" (click)="scrollToBottom()">
                Get started
              </button>
              <button class="viewplan-btn" (click)="scrollToElement(plans2)">
                View plans
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="switch_premium">
            <div class="premium_icon">
              <img src="assets/images/premium.svg" />
            </div>
            <!-- <h1>Why switch to Premium?</h1> -->
            <h1>Premium offers the best experience</h1>
            <!-- <p>Donec vel ultricies orci ut vel orci ut leo ultricies donec</p> -->
            <p>Get access to premium features and enjoy your music more!</p>
          </div>
        </div>
      </div>

      <div class="row premium_for_web">
        <div class="col-lg-3">
          <div class="premium_box">
            <div class="premium_icon">
              <img src="assets/images/comunity.svg" />
            </div>
            <h1>
              Big <br />
              Community
            </h1>
          </div>
        </div>
        <div class="col-lg-3">
          <div class="premium_box">
            <div class="premium_icon">
              <img src="assets/images/control_number.svg" />
            </div>
            <h1>Control your <br />numbers</h1>
          </div>
        </div>

        <div class="col-lg-3">
          <div class="premium_box">
            <div class="premium_icon">
              <img src="assets/images/status-up.svg" />
            </div>
            <h1>
              Get best <br />
              stats
            </h1>
          </div>
        </div>

        <div class="col-lg-3">
          <div class="premium_box">
            <div class="premium_icon">
              <img src="assets/images/fan.svg" />
            </div>
            <h1>
              Build Your <br />
              Audience
            </h1>
          </div>
        </div>
      </div>

      <div class="row premium_for_mobile">
        <div class="col-12">
          <swiper [config]="config">
            <div class="swiper-wrapper">
              <div class="swiper-slide">
                <div class="premium_box">
                  <div class="premium_icon">
                    <img src="assets/images/comunity.svg" />
                  </div>
                  <h1>
                    Big <br />
                    Community
                  </h1>
                </div>
              </div>

              <div class="swiper-slide">
                <div class="premium_box">
                  <div class="premium_icon">
                    <img src="assets/images/control_number.svg" />
                  </div>
                  <h1>Control your <br />numbers</h1>
                </div>
              </div>

              <div class="swiper-slide">
                <div class="premium_box">
                  <div class="premium_icon">
                    <img src="assets/images/status-up.svg" />
                  </div>
                  <h1>
                    Get best <br />
                    stats
                  </h1>
                </div>
              </div>

              <div class="swiper-slide">
                <div class="premium_box">
                  <div class="premium_icon">
                    <img src="assets/images/fan.svg" />
                  </div>
                  <h1>Learn from your <br />fans</h1>
                </div>
              </div>
            </div>
          </swiper>
        </div>
      </div>
    </div>
  </section>

  <section class="choose_plan plans_page" #plans2>
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="choose_plan_heading">
            <h1>Choose your plan</h1>
            <p>
              Listen or watch without limits by selecting the right plan that works for
              you!
            </p>
          </div>
        </div>
      </div>
      <div class="pricing_slider_for_mobile">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <swiper [config]="config">
                <div class="swiper-wrapper">
                  <div class="swiper-slide">
                    <div class="plan_details">
<!--                      <h5>$4.99</h5>-->
                      <h5 class="d-flex align-items-center">
                        <span class="pe-1">
                          <svg
                            width="10"
                            height="10"
                            viewBox="0 0 10 10"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M0.200195 3.05763V5.29018V6.94228C0.200195 8.51846 2.35192 9.79995 5.0002 9.79995C7.64847 9.79995 9.8002 8.51846 9.8002 6.94228V5.29018V3.05763C9.8002 2.28963 9.2904 1.56628 8.37012 1.01707C7.46309 0.490185 6.27137 0.199951 5.0002 0.199951C3.72902 0.199951 2.53068 0.490184 1.63027 1.02153C0.736572 1.55054 0.229988 2.24799 0.201469 2.99119C0.200735 3.01031 0.200318 3.02945 0.200219 3.04863C0.200203 3.05163 0.200195 3.05463 0.200195 3.05763ZM8.80709 4.74888V5.29018C8.80709 6.46005 7.17178 7.34414 5.0002 7.34414C3.10006 7.34414 1.61051 6.66725 1.26781 5.71432C1.21885 5.57818 1.1933 5.43642 1.1933 5.29018V4.74888C2.07907 5.39565 3.47507 5.78135 5.0002 5.78135C6.52532 5.78135 7.92132 5.39565 8.80709 4.74888ZM1.1933 3.05763C1.1933 3.39251 1.3257 3.70507 1.57729 3.98637C2.20626 4.68293 3.51716 5.11158 5.0002 5.11158C6.48323 5.11158 7.79414 4.67846 8.4231 3.98637C8.68131 3.70507 8.80709 3.39251 8.80709 3.05763C8.80709 3.02191 8.80559 2.98629 8.80261 2.9508C8.75783 2.41849 8.37882 1.91511 7.71468 1.52163C7.62448 1.46916 7.53054 1.41963 7.43321 1.37311C6.75189 1.04749 5.90392 0.869719 5.0002 0.869719C4.0373 0.869719 3.15663 1.07649 2.48542 1.41672C2.41465 1.45297 2.34583 1.49092 2.2791 1.53056C2.14278 1.61035 2.01844 1.69521 1.90658 1.78433C1.45896 2.14244 1.19464 2.58097 1.1933 3.05402C1.1933 3.05522 1.1933 3.05643 1.1933 3.05763ZM1.89713 7.40088C2.72247 7.78722 3.79655 8.01391 5.0002 8.01391C5.55189 8.01391 6.07636 7.96628 6.56213 7.87749C7.47161 7.71146 8.2462 7.40168 8.80709 6.99139C8.76075 8.17465 7.07247 9.13018 5.0002 9.13018C2.92792 9.13018 1.23964 8.17465 1.1933 6.99139C1.40102 7.14218 1.63684 7.27939 1.89713 7.40088Z"
                              fill="white"
                            />
                          </svg>
                        </span>
                        <span>100</span>
                      </h5>
                      <h6>Per month</h6>
                      <h1>Elite Plan</h1>
                      <p>Our most affordable plan offers so much!</p>
                      <div class="get_started">
                        <button class="btn-action" (click)="scrollToBottom()">
                          Get started
                        </button>
                      </div>
                      <ul>
                        <li>Browse all Content</li>
                        <li>
                          Uninterrupted-Ad-free Access to
                          <b>all -</b> form Music,Vidoe and Podcast
                        </li>
                        <li>Listen Online</li>
                        <li>Share Playlists with friends</li>
                        <li>Lyrics View</li>
                        <li>See What Friends are listening to</li>
                        <li>Offline Download</li>
                        <li>Up to 4 devices</li>
                        <li>Up to 4 Profiles</li>
                      </ul>
                    </div>
                  </div>
                  <div class="swiper-slide">
                    <div class="plan_details">
<!--                      <h5>$3.99</h5>-->
                      <h5 class="d-flex align-items-center">
                        <span class="pe-1">
                          <svg
                            width="10"
                            height="10"
                            viewBox="0 0 10 10"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M0.200195 3.05763V5.29018V6.94228C0.200195 8.51846 2.35192 9.79995 5.0002 9.79995C7.64847 9.79995 9.8002 8.51846 9.8002 6.94228V5.29018V3.05763C9.8002 2.28963 9.2904 1.56628 8.37012 1.01707C7.46309 0.490185 6.27137 0.199951 5.0002 0.199951C3.72902 0.199951 2.53068 0.490184 1.63027 1.02153C0.736572 1.55054 0.229988 2.24799 0.201469 2.99119C0.200735 3.01031 0.200318 3.02945 0.200219 3.04863C0.200203 3.05163 0.200195 3.05463 0.200195 3.05763ZM8.80709 4.74888V5.29018C8.80709 6.46005 7.17178 7.34414 5.0002 7.34414C3.10006 7.34414 1.61051 6.66725 1.26781 5.71432C1.21885 5.57818 1.1933 5.43642 1.1933 5.29018V4.74888C2.07907 5.39565 3.47507 5.78135 5.0002 5.78135C6.52532 5.78135 7.92132 5.39565 8.80709 4.74888ZM1.1933 3.05763C1.1933 3.39251 1.3257 3.70507 1.57729 3.98637C2.20626 4.68293 3.51716 5.11158 5.0002 5.11158C6.48323 5.11158 7.79414 4.67846 8.4231 3.98637C8.68131 3.70507 8.80709 3.39251 8.80709 3.05763C8.80709 3.02191 8.80559 2.98629 8.80261 2.9508C8.75783 2.41849 8.37882 1.91511 7.71468 1.52163C7.62448 1.46916 7.53054 1.41963 7.43321 1.37311C6.75189 1.04749 5.90392 0.869719 5.0002 0.869719C4.0373 0.869719 3.15663 1.07649 2.48542 1.41672C2.41465 1.45297 2.34583 1.49092 2.2791 1.53056C2.14278 1.61035 2.01844 1.69521 1.90658 1.78433C1.45896 2.14244 1.19464 2.58097 1.1933 3.05402C1.1933 3.05522 1.1933 3.05643 1.1933 3.05763ZM1.89713 7.40088C2.72247 7.78722 3.79655 8.01391 5.0002 8.01391C5.55189 8.01391 6.07636 7.96628 6.56213 7.87749C7.47161 7.71146 8.2462 7.40168 8.80709 6.99139C8.76075 8.17465 7.07247 9.13018 5.0002 9.13018C2.92792 9.13018 1.23964 8.17465 1.1933 6.99139C1.40102 7.14218 1.63684 7.27939 1.89713 7.40088Z"
                              fill="white"
                            />
                          </svg>
                        </span>
                        <span>80</span>
                      </h5>
                      <h6>Per month</h6>
                      <h1>Premium Duo Plan</h1>
                      <p>Enjoy 2 or more content types!</p>
                      <div class="get_started">
                        <button class="btn-action" (click)="scrollToBottom()">
                          Get started
                        </button>
                      </div>
                      <ul>
                        <li>Browse all Content</li>
                        <li>
                          Ad-free Access to <b>any two</b> From Music, Video OR
                          Podcast
                        </li>
                        <li>Listen Online</li>
                        <li>Share Playlists with friends</li>
                        <li>Lyrics View</li>
                        <li>See What Friends are listening to</li>
                        <li>Offline Download</li>
                        <li>Up to Two Devices</li>
                        <li>Up to Two Profiles</li>
                      </ul>
                    </div>
                  </div>
                  <div class="swiper-slide">
                    <div class="plan_details">
<!--                      <h5>$2.99</h5>-->
                      <h5 class="d-flex align-items-center">
                        <span class="pe-1">
                          <svg
                            width="10"
                            height="10"
                            viewBox="0 0 10 10"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M0.200195 3.05763V5.29018V6.94228C0.200195 8.51846 2.35192 9.79995 5.0002 9.79995C7.64847 9.79995 9.8002 8.51846 9.8002 6.94228V5.29018V3.05763C9.8002 2.28963 9.2904 1.56628 8.37012 1.01707C7.46309 0.490185 6.27137 0.199951 5.0002 0.199951C3.72902 0.199951 2.53068 0.490184 1.63027 1.02153C0.736572 1.55054 0.229988 2.24799 0.201469 2.99119C0.200735 3.01031 0.200318 3.02945 0.200219 3.04863C0.200203 3.05163 0.200195 3.05463 0.200195 3.05763ZM8.80709 4.74888V5.29018C8.80709 6.46005 7.17178 7.34414 5.0002 7.34414C3.10006 7.34414 1.61051 6.66725 1.26781 5.71432C1.21885 5.57818 1.1933 5.43642 1.1933 5.29018V4.74888C2.07907 5.39565 3.47507 5.78135 5.0002 5.78135C6.52532 5.78135 7.92132 5.39565 8.80709 4.74888ZM1.1933 3.05763C1.1933 3.39251 1.3257 3.70507 1.57729 3.98637C2.20626 4.68293 3.51716 5.11158 5.0002 5.11158C6.48323 5.11158 7.79414 4.67846 8.4231 3.98637C8.68131 3.70507 8.80709 3.39251 8.80709 3.05763C8.80709 3.02191 8.80559 2.98629 8.80261 2.9508C8.75783 2.41849 8.37882 1.91511 7.71468 1.52163C7.62448 1.46916 7.53054 1.41963 7.43321 1.37311C6.75189 1.04749 5.90392 0.869719 5.0002 0.869719C4.0373 0.869719 3.15663 1.07649 2.48542 1.41672C2.41465 1.45297 2.34583 1.49092 2.2791 1.53056C2.14278 1.61035 2.01844 1.69521 1.90658 1.78433C1.45896 2.14244 1.19464 2.58097 1.1933 3.05402C1.1933 3.05522 1.1933 3.05643 1.1933 3.05763ZM1.89713 7.40088C2.72247 7.78722 3.79655 8.01391 5.0002 8.01391C5.55189 8.01391 6.07636 7.96628 6.56213 7.87749C7.47161 7.71146 8.2462 7.40168 8.80709 6.99139C8.76075 8.17465 7.07247 9.13018 5.0002 9.13018C2.92792 9.13018 1.23964 8.17465 1.1933 6.99139C1.40102 7.14218 1.63684 7.27939 1.89713 7.40088Z"
                              fill="white"
                            />
                          </svg>
                        </span>
                        <span>60</span>
                      </h5>
                      <h6>Per month</h6>
                      <h1>Premium Solo Plan</h1>
                      <p>Make your pick and enjoy all its content!</p>
                      <div class="get_started">
                        <button class="btn-action" (click)="scrollToBottom()">
                          Get started
                        </button>
                      </div>
                      <ul>
                        <li>Browse all Content</li>
                        <li>
                          Ad-free Access to <b>any one</b> from Music OR Video
                          OR Podcast
                        </li>
                        <li>Listen Online</li>
                        <li>Share Playlists with friends</li>
                        <li>Lyrics View</li>
                        <li>See What Friends are listening to</li>
                        <li>Offline Download</li>
                        <li>Single Device</li>
                        <li>Single Profile</li>
                      </ul>
                    </div>
                  </div>
                  <div class="swiper-slide">
                    <div class="plan_details">
<!--                      <h5>$0.00</h5>-->
                      <h5 class="d-flex align-items-center">
                        <span class="pe-1">
                          <svg
                            width="10"
                            height="10"
                            viewBox="0 0 10 10"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M0.200195 3.05763V5.29018V6.94228C0.200195 8.51846 2.35192 9.79995 5.0002 9.79995C7.64847 9.79995 9.8002 8.51846 9.8002 6.94228V5.29018V3.05763C9.8002 2.28963 9.2904 1.56628 8.37012 1.01707C7.46309 0.490185 6.27137 0.199951 5.0002 0.199951C3.72902 0.199951 2.53068 0.490184 1.63027 1.02153C0.736572 1.55054 0.229988 2.24799 0.201469 2.99119C0.200735 3.01031 0.200318 3.02945 0.200219 3.04863C0.200203 3.05163 0.200195 3.05463 0.200195 3.05763ZM8.80709 4.74888V5.29018C8.80709 6.46005 7.17178 7.34414 5.0002 7.34414C3.10006 7.34414 1.61051 6.66725 1.26781 5.71432C1.21885 5.57818 1.1933 5.43642 1.1933 5.29018V4.74888C2.07907 5.39565 3.47507 5.78135 5.0002 5.78135C6.52532 5.78135 7.92132 5.39565 8.80709 4.74888ZM1.1933 3.05763C1.1933 3.39251 1.3257 3.70507 1.57729 3.98637C2.20626 4.68293 3.51716 5.11158 5.0002 5.11158C6.48323 5.11158 7.79414 4.67846 8.4231 3.98637C8.68131 3.70507 8.80709 3.39251 8.80709 3.05763C8.80709 3.02191 8.80559 2.98629 8.80261 2.9508C8.75783 2.41849 8.37882 1.91511 7.71468 1.52163C7.62448 1.46916 7.53054 1.41963 7.43321 1.37311C6.75189 1.04749 5.90392 0.869719 5.0002 0.869719C4.0373 0.869719 3.15663 1.07649 2.48542 1.41672C2.41465 1.45297 2.34583 1.49092 2.2791 1.53056C2.14278 1.61035 2.01844 1.69521 1.90658 1.78433C1.45896 2.14244 1.19464 2.58097 1.1933 3.05402C1.1933 3.05522 1.1933 3.05643 1.1933 3.05763ZM1.89713 7.40088C2.72247 7.78722 3.79655 8.01391 5.0002 8.01391C5.55189 8.01391 6.07636 7.96628 6.56213 7.87749C7.47161 7.71146 8.2462 7.40168 8.80709 6.99139C8.76075 8.17465 7.07247 9.13018 5.0002 9.13018C2.92792 9.13018 1.23964 8.17465 1.1933 6.99139C1.40102 7.14218 1.63684 7.27939 1.89713 7.40088Z"
                              fill="white"
                            />
                          </svg>
                        </span>
                        <span>0.00</span>
                      </h5>
                      <h6>Per month</h6>
                      <h1>Free Plan</h1>
                      <p>All the content you can consume for FREE!</p>
                      <div class="get_started">
                        <button class="btn-action" (click)="scrollToBottom()">
                          Get started
                        </button>
                      </div>
                      <ul>
                        <li>Browse all Content</li>
                        <li>Listen Online</li>
                        <li>Single Device</li>
                        <li>Radio</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </swiper>
            </div>
          </div>
        </div>
      </div>
      <div class="row pricing_for_web">
        <div class="col-lg-3 col-md-12">
          <div class="plan_details">
            <h5>
              <svg
                width="10"
                height="10"
                viewBox="0 0 10 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M0.200195 3.05763V5.29018V6.94228C0.200195 8.51846 2.35192 9.79995 5.0002 9.79995C7.64847 9.79995 9.8002 8.51846 9.8002 6.94228V5.29018V3.05763C9.8002 2.28963 9.2904 1.56628 8.37012 1.01707C7.46309 0.490185 6.27137 0.199951 5.0002 0.199951C3.72902 0.199951 2.53068 0.490184 1.63027 1.02153C0.736572 1.55054 0.229988 2.24799 0.201469 2.99119C0.200735 3.01031 0.200318 3.02945 0.200219 3.04863C0.200203 3.05163 0.200195 3.05463 0.200195 3.05763ZM8.80709 4.74888V5.29018C8.80709 6.46005 7.17178 7.34414 5.0002 7.34414C3.10006 7.34414 1.61051 6.66725 1.26781 5.71432C1.21885 5.57818 1.1933 5.43642 1.1933 5.29018V4.74888C2.07907 5.39565 3.47507 5.78135 5.0002 5.78135C6.52532 5.78135 7.92132 5.39565 8.80709 4.74888ZM1.1933 3.05763C1.1933 3.39251 1.3257 3.70507 1.57729 3.98637C2.20626 4.68293 3.51716 5.11158 5.0002 5.11158C6.48323 5.11158 7.79414 4.67846 8.4231 3.98637C8.68131 3.70507 8.80709 3.39251 8.80709 3.05763C8.80709 3.02191 8.80559 2.98629 8.80261 2.9508C8.75783 2.41849 8.37882 1.91511 7.71468 1.52163C7.62448 1.46916 7.53054 1.41963 7.43321 1.37311C6.75189 1.04749 5.90392 0.869719 5.0002 0.869719C4.0373 0.869719 3.15663 1.07649 2.48542 1.41672C2.41465 1.45297 2.34583 1.49092 2.2791 1.53056C2.14278 1.61035 2.01844 1.69521 1.90658 1.78433C1.45896 2.14244 1.19464 2.58097 1.1933 3.05402C1.1933 3.05522 1.1933 3.05643 1.1933 3.05763ZM1.89713 7.40088C2.72247 7.78722 3.79655 8.01391 5.0002 8.01391C5.55189 8.01391 6.07636 7.96628 6.56213 7.87749C7.47161 7.71146 8.2462 7.40168 8.80709 6.99139C8.76075 8.17465 7.07247 9.13018 5.0002 9.13018C2.92792 9.13018 1.23964 8.17465 1.1933 6.99139C1.40102 7.14218 1.63684 7.27939 1.89713 7.40088Z"
                  fill="white"
                />
              </svg>
              100
            </h5>
            <h6>Per month</h6>
            <h1>Elite Plan</h1>
            <p class="plan_des">Our most affordable plan offers so much!</p>
            <div class="get_started">
              <button class="btn-action" (click)="scrollToBottom()">
                Get started
              </button>
            </div>
            <ul>
              <li>Browse all Content</li>
              <li>
                Uninterrupted-Ad-free Access to <b>all -</b> from Music,Vidoe
                and Podcast
              </li>
              <li>Listen Online</li>
              <li>Share Playlists with friends</li>
              <li>Lyrics View</li>
              <li>See What Friends are listening to</li>
              <li>Offline Download</li>
              <li>Up to 4 devices</li>
              <li>Up to 4 Profiles</li>
            </ul>
          </div>
        </div>

        <div class="col-lg-3 col-md-12">
          <div class="plan_details">
            <h5>
              <svg
                width="10"
                height="10"
                viewBox="0 0 10 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M0.200195 3.05763V5.29018V6.94228C0.200195 8.51846 2.35192 9.79995 5.0002 9.79995C7.64847 9.79995 9.8002 8.51846 9.8002 6.94228V5.29018V3.05763C9.8002 2.28963 9.2904 1.56628 8.37012 1.01707C7.46309 0.490185 6.27137 0.199951 5.0002 0.199951C3.72902 0.199951 2.53068 0.490184 1.63027 1.02153C0.736572 1.55054 0.229988 2.24799 0.201469 2.99119C0.200735 3.01031 0.200318 3.02945 0.200219 3.04863C0.200203 3.05163 0.200195 3.05463 0.200195 3.05763ZM8.80709 4.74888V5.29018C8.80709 6.46005 7.17178 7.34414 5.0002 7.34414C3.10006 7.34414 1.61051 6.66725 1.26781 5.71432C1.21885 5.57818 1.1933 5.43642 1.1933 5.29018V4.74888C2.07907 5.39565 3.47507 5.78135 5.0002 5.78135C6.52532 5.78135 7.92132 5.39565 8.80709 4.74888ZM1.1933 3.05763C1.1933 3.39251 1.3257 3.70507 1.57729 3.98637C2.20626 4.68293 3.51716 5.11158 5.0002 5.11158C6.48323 5.11158 7.79414 4.67846 8.4231 3.98637C8.68131 3.70507 8.80709 3.39251 8.80709 3.05763C8.80709 3.02191 8.80559 2.98629 8.80261 2.9508C8.75783 2.41849 8.37882 1.91511 7.71468 1.52163C7.62448 1.46916 7.53054 1.41963 7.43321 1.37311C6.75189 1.04749 5.90392 0.869719 5.0002 0.869719C4.0373 0.869719 3.15663 1.07649 2.48542 1.41672C2.41465 1.45297 2.34583 1.49092 2.2791 1.53056C2.14278 1.61035 2.01844 1.69521 1.90658 1.78433C1.45896 2.14244 1.19464 2.58097 1.1933 3.05402C1.1933 3.05522 1.1933 3.05643 1.1933 3.05763ZM1.89713 7.40088C2.72247 7.78722 3.79655 8.01391 5.0002 8.01391C5.55189 8.01391 6.07636 7.96628 6.56213 7.87749C7.47161 7.71146 8.2462 7.40168 8.80709 6.99139C8.76075 8.17465 7.07247 9.13018 5.0002 9.13018C2.92792 9.13018 1.23964 8.17465 1.1933 6.99139C1.40102 7.14218 1.63684 7.27939 1.89713 7.40088Z"
                  fill="white"
                />
              </svg>
              80
            </h5>
            <h6>Per month</h6>
            <h1>Premium Duo Plan</h1>
            <p class="plan_des">Enjoy 2 or more content types!</p>
            <div class="get_started">
              <button class="btn-action" (click)="scrollToBottom()">
                Get started
              </button>
            </div>
            <ul>
              <li>Browse all Content</li>
              <li>
                Ad-free Access to <b>any two</b> From Music, Video OR Podcast
              </li>
              <li>Listen Online</li>
              <li>Share Playlists with friends</li>
              <li>Lyrics View</li>
              <li>See What Friends are listening to</li>
              <li>Offline Download</li>
              <li>Up to Two Devices</li>
              <li>Up to Two Profiles</li>
            </ul>
          </div>
        </div>

        <div class="col-lg-3 col-md-12">
          <div class="plan_details">
            <h5>
              <svg
                width="10"
                height="10"
                viewBox="0 0 10 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M0.200195 3.05763V5.29018V6.94228C0.200195 8.51846 2.35192 9.79995 5.0002 9.79995C7.64847 9.79995 9.8002 8.51846 9.8002 6.94228V5.29018V3.05763C9.8002 2.28963 9.2904 1.56628 8.37012 1.01707C7.46309 0.490185 6.27137 0.199951 5.0002 0.199951C3.72902 0.199951 2.53068 0.490184 1.63027 1.02153C0.736572 1.55054 0.229988 2.24799 0.201469 2.99119C0.200735 3.01031 0.200318 3.02945 0.200219 3.04863C0.200203 3.05163 0.200195 3.05463 0.200195 3.05763ZM8.80709 4.74888V5.29018C8.80709 6.46005 7.17178 7.34414 5.0002 7.34414C3.10006 7.34414 1.61051 6.66725 1.26781 5.71432C1.21885 5.57818 1.1933 5.43642 1.1933 5.29018V4.74888C2.07907 5.39565 3.47507 5.78135 5.0002 5.78135C6.52532 5.78135 7.92132 5.39565 8.80709 4.74888ZM1.1933 3.05763C1.1933 3.39251 1.3257 3.70507 1.57729 3.98637C2.20626 4.68293 3.51716 5.11158 5.0002 5.11158C6.48323 5.11158 7.79414 4.67846 8.4231 3.98637C8.68131 3.70507 8.80709 3.39251 8.80709 3.05763C8.80709 3.02191 8.80559 2.98629 8.80261 2.9508C8.75783 2.41849 8.37882 1.91511 7.71468 1.52163C7.62448 1.46916 7.53054 1.41963 7.43321 1.37311C6.75189 1.04749 5.90392 0.869719 5.0002 0.869719C4.0373 0.869719 3.15663 1.07649 2.48542 1.41672C2.41465 1.45297 2.34583 1.49092 2.2791 1.53056C2.14278 1.61035 2.01844 1.69521 1.90658 1.78433C1.45896 2.14244 1.19464 2.58097 1.1933 3.05402C1.1933 3.05522 1.1933 3.05643 1.1933 3.05763ZM1.89713 7.40088C2.72247 7.78722 3.79655 8.01391 5.0002 8.01391C5.55189 8.01391 6.07636 7.96628 6.56213 7.87749C7.47161 7.71146 8.2462 7.40168 8.80709 6.99139C8.76075 8.17465 7.07247 9.13018 5.0002 9.13018C2.92792 9.13018 1.23964 8.17465 1.1933 6.99139C1.40102 7.14218 1.63684 7.27939 1.89713 7.40088Z"
                  fill="white"
                />
              </svg>
              60
            </h5>
            <h6>Per month</h6>
            <h1>Premium Solo Plan</h1>
            <p class="plan_des">Make your pick and enjoy all its content!</p>
            <div class="get_started">
              <button class="btn-action" (click)="scrollToBottom()">
                Get started
              </button>
            </div>
            <ul>
              <li>Browse all Content</li>
              <li>
                Ad-free Access to <b>any one</b> from Music OR Video OR Podcast
              </li>
              <li>Listen Online</li>
              <li>Share Playlists with friends</li>
              <li>Lyrics View</li>
              <li>See What Friends are listening to</li>
              <li>Offline Download</li>
              <li>Single Device</li>
              <li>Single Profile</li>
            </ul>
          </div>
        </div>
        <div class="col-lg-3 col-md-12">
          <div class="plan_details">
            <h5>
              <svg
                width="10"
                height="10"
                viewBox="0 0 10 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M0.200195 3.05763V5.29018V6.94228C0.200195 8.51846 2.35192 9.79995 5.0002 9.79995C7.64847 9.79995 9.8002 8.51846 9.8002 6.94228V5.29018V3.05763C9.8002 2.28963 9.2904 1.56628 8.37012 1.01707C7.46309 0.490185 6.27137 0.199951 5.0002 0.199951C3.72902 0.199951 2.53068 0.490184 1.63027 1.02153C0.736572 1.55054 0.229988 2.24799 0.201469 2.99119C0.200735 3.01031 0.200318 3.02945 0.200219 3.04863C0.200203 3.05163 0.200195 3.05463 0.200195 3.05763ZM8.80709 4.74888V5.29018C8.80709 6.46005 7.17178 7.34414 5.0002 7.34414C3.10006 7.34414 1.61051 6.66725 1.26781 5.71432C1.21885 5.57818 1.1933 5.43642 1.1933 5.29018V4.74888C2.07907 5.39565 3.47507 5.78135 5.0002 5.78135C6.52532 5.78135 7.92132 5.39565 8.80709 4.74888ZM1.1933 3.05763C1.1933 3.39251 1.3257 3.70507 1.57729 3.98637C2.20626 4.68293 3.51716 5.11158 5.0002 5.11158C6.48323 5.11158 7.79414 4.67846 8.4231 3.98637C8.68131 3.70507 8.80709 3.39251 8.80709 3.05763C8.80709 3.02191 8.80559 2.98629 8.80261 2.9508C8.75783 2.41849 8.37882 1.91511 7.71468 1.52163C7.62448 1.46916 7.53054 1.41963 7.43321 1.37311C6.75189 1.04749 5.90392 0.869719 5.0002 0.869719C4.0373 0.869719 3.15663 1.07649 2.48542 1.41672C2.41465 1.45297 2.34583 1.49092 2.2791 1.53056C2.14278 1.61035 2.01844 1.69521 1.90658 1.78433C1.45896 2.14244 1.19464 2.58097 1.1933 3.05402C1.1933 3.05522 1.1933 3.05643 1.1933 3.05763ZM1.89713 7.40088C2.72247 7.78722 3.79655 8.01391 5.0002 8.01391C5.55189 8.01391 6.07636 7.96628 6.56213 7.87749C7.47161 7.71146 8.2462 7.40168 8.80709 6.99139C8.76075 8.17465 7.07247 9.13018 5.0002 9.13018C2.92792 9.13018 1.23964 8.17465 1.1933 6.99139C1.40102 7.14218 1.63684 7.27939 1.89713 7.40088Z"
                  fill="white"
                />
              </svg>
              0.00
            </h5>
            <h6>Per month</h6>
            <h1>Free Plan</h1>
            <p class="plan_des">All the content you can consume for FREE!</p>
            <div class="get_started">
              <button class="btn-action" (click)="scrollToBottom()">
                Get started
              </button>
            </div>
            <ul>
              <li>Browse all Content</li>
              <li>Listen Online</li>
              <li>Single Device</li>
              <li>Radio</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="contact_us plans_contact_us">
    <div class="container footer_bg_sec">
      <frontend-footer></frontend-footer>
    </div>
  </section>
</div>

import { createFeatureSelector, createSelector } from '@ngrx/store';
import { PublicState } from './public.reducer';

const getState = createFeatureSelector<PublicState>('public');

export const getError = createSelector(
  getState,
  (state: PublicState) => state?.error
);
export const getSuccess = createSelector(
  getState,
  (state: PublicState) => state?.success
);

export const getTokenDetails = createSelector(
  getState,
  (state: PublicState) => state?.tokenDetails
);

export const generateRtmToken = createSelector(
  getState,
  (state: PublicState) => state.rtmToken
);

export const getShowDetails = createSelector(
  getState,
  (state: PublicState) => state?.showDetails
);

export const getArtistDetails = createSelector(
  getState,
  (state: PublicState) => state?.artistDetails
);

<div class="body_bg" #sectionContainer>
  <frontend-header></frontend-header>
  <section class="top_banner banner_sec">
    <div class="container top_banner_img">
      <div class="row align-items-center">
        <div class="col-md-12 col-lg-4 col-12">
          <div class="mobile_image">
            <img src="assets/images/bannerimg1.png " />
          </div>
        </div>
        <div class="col-md-12 col-lg-8 col-12">
          <div class="banner_heading">
            <h1>Get closer to your favorite creator than ever before!</h1>
            <p>Access to Exclusive Videos, Photos, and Behind-the-Scenes Content,
               Members-Only Events and Giveaways from your favorite creators, Connect with Fellow Fans in Our Exclusive Community!
            </p>
            <div class="appstore_link">
              <div class="btn_link">
                <a
                  href="https://apps.apple.com/us/app/kwot-music/id1641283024"
                  target="_blank"
                  class="appstore"
                  ><img src="assets/images/appstore.png "
                /></a>
                <a
                  href="https://play.google.com/store/apps/details?id=com.kwot.music.android"
                  target="_blank"
                  class="playstore"
                  ><img src="assets/images/play_store.png "
                /></a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row fan_club_section">
        <div class="fan-culb-heading text-center">
          <p>Fan Club by Kwot</p>
          <h5>Built for creators, powered by fans!</h5>
        </div>
        <div
          class="join_section col-md-12 col-lg-6 order-md-2 order-2 order-lg-1"
        >
          <div>
            <h3 class="heading">
              Join your favorite creators' FanClub and get access to a wide range
              of Special Benefits including:
            </h3>
            <ul>
              <li class="color-white">Exclusive Songs</li>
              <li class="color-white">
                Exclusive Videos and Behind The Scenes Content
              </li>
              <li class="color-white">
                Discounts to your favorite Creators' Shows
              </li>
              <li class="color-white">
                Access to exclusive Merchandise and much more
              </li>
            </ul>
            <div class="join-steps">
              <img src="assets/images/user-img-fan.png " />
              <p>Join in 3 easy steps</p>
            </div>
          </div>
        </div>
        <div
          class="col-md-12 col-lg-6 image-banner order-md-2 order-2 order-lg-1"
        >
          <img src="assets/images/fan_clib_mbl.png" />
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <div class="fan-club-values">
            <!-- <img src="assets/images/fan-club-inner-bg.png" /> -->
            <div>
              <div class="inner-values d-flex step-1">
                <span>1</span>
                <p>Sign Up on Kwot</p>
              </div>
              <div class="inner-values d-flex step-2">
                <p>Find your favorite Creators’ Fan Club</p>
                <span>2</span>
              </div>
              <div class="inner-values step-3">
                <div class="row d-flex m-0">
                  <span class="col-3">3</span>
                  <p class="col-9">
                    Join any of their membership plans and begin enjoying
                    awesome benefits and deals.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="choose_plan">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="choose_plan_heading">
            <img src="assets/images/plan.svg" />
            <h1>Choose your plan</h1>
            <p>
              Listen or watch without limits by selecting the right plan that works for
              you!
            </p>
          </div>
        </div>
      </div>
      <div class="pricing_slider_for_mobile">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <swiper [config]="config">
                <div class="swiper-wrapper">
                  <div class="swiper-slide">
                    <div class="plan_details">
<!--                      <h5>$4.99</h5>-->
                      <h5 class="d-flex align-items-center">
                        <span class="pe-1">
                          <svg
                            width="10"
                            height="10"
                            viewBox="0 0 10 10"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M0.200195 3.05763V5.29018V6.94228C0.200195 8.51846 2.35192 9.79995 5.0002 9.79995C7.64847 9.79995 9.8002 8.51846 9.8002 6.94228V5.29018V3.05763C9.8002 2.28963 9.2904 1.56628 8.37012 1.01707C7.46309 0.490185 6.27137 0.199951 5.0002 0.199951C3.72902 0.199951 2.53068 0.490184 1.63027 1.02153C0.736572 1.55054 0.229988 2.24799 0.201469 2.99119C0.200735 3.01031 0.200318 3.02945 0.200219 3.04863C0.200203 3.05163 0.200195 3.05463 0.200195 3.05763ZM8.80709 4.74888V5.29018C8.80709 6.46005 7.17178 7.34414 5.0002 7.34414C3.10006 7.34414 1.61051 6.66725 1.26781 5.71432C1.21885 5.57818 1.1933 5.43642 1.1933 5.29018V4.74888C2.07907 5.39565 3.47507 5.78135 5.0002 5.78135C6.52532 5.78135 7.92132 5.39565 8.80709 4.74888ZM1.1933 3.05763C1.1933 3.39251 1.3257 3.70507 1.57729 3.98637C2.20626 4.68293 3.51716 5.11158 5.0002 5.11158C6.48323 5.11158 7.79414 4.67846 8.4231 3.98637C8.68131 3.70507 8.80709 3.39251 8.80709 3.05763C8.80709 3.02191 8.80559 2.98629 8.80261 2.9508C8.75783 2.41849 8.37882 1.91511 7.71468 1.52163C7.62448 1.46916 7.53054 1.41963 7.43321 1.37311C6.75189 1.04749 5.90392 0.869719 5.0002 0.869719C4.0373 0.869719 3.15663 1.07649 2.48542 1.41672C2.41465 1.45297 2.34583 1.49092 2.2791 1.53056C2.14278 1.61035 2.01844 1.69521 1.90658 1.78433C1.45896 2.14244 1.19464 2.58097 1.1933 3.05402C1.1933 3.05522 1.1933 3.05643 1.1933 3.05763ZM1.89713 7.40088C2.72247 7.78722 3.79655 8.01391 5.0002 8.01391C5.55189 8.01391 6.07636 7.96628 6.56213 7.87749C7.47161 7.71146 8.2462 7.40168 8.80709 6.99139C8.76075 8.17465 7.07247 9.13018 5.0002 9.13018C2.92792 9.13018 1.23964 8.17465 1.1933 6.99139C1.40102 7.14218 1.63684 7.27939 1.89713 7.40088Z"
                              fill="white"
                            />
                          </svg>
                        </span>
                        <span>100</span>
                      </h5>
                      <h6>Per month</h6>
                      <h1>Elite Plan</h1>
                      <p>Our most affordable plan offers so much!</p>
                      <div class="get_started">
                        <button class="btn-action" (click)="scrollToBottom()">
                          Get started
                        </button>
                      </div>
                      <ul>
                        <li>Browse all Content</li>
                        <li>
                          Uninterrupted-Ad-free Access to
                          <b>all -</b>Music, Video and Podcast
                        </li>
                        <li>Listen Online</li>
                        <li>Share Playlists with friends</li>
                        <li>Lyrics View</li>
                        <li>See What Friends are listening to</li>
                        <li>Offline Download</li>
                        <li>Up to 4 device Devices</li>
                        <li>Early Access to Newly Released Music</li>
                        <li>Up to 4 Profiles</li>
                      </ul>
                    </div>
                  </div>

                  <div class="swiper-slide">
                    <div class="plan_details">
<!--                      <h5>$3.99</h5>-->
                      <h5 class="d-flex align-items-center">
                        <span class="pe-1">
                          <svg
                            width="10"
                            height="10"
                            viewBox="0 0 10 10"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M0.200195 3.05763V5.29018V6.94228C0.200195 8.51846 2.35192 9.79995 5.0002 9.79995C7.64847 9.79995 9.8002 8.51846 9.8002 6.94228V5.29018V3.05763C9.8002 2.28963 9.2904 1.56628 8.37012 1.01707C7.46309 0.490185 6.27137 0.199951 5.0002 0.199951C3.72902 0.199951 2.53068 0.490184 1.63027 1.02153C0.736572 1.55054 0.229988 2.24799 0.201469 2.99119C0.200735 3.01031 0.200318 3.02945 0.200219 3.04863C0.200203 3.05163 0.200195 3.05463 0.200195 3.05763ZM8.80709 4.74888V5.29018C8.80709 6.46005 7.17178 7.34414 5.0002 7.34414C3.10006 7.34414 1.61051 6.66725 1.26781 5.71432C1.21885 5.57818 1.1933 5.43642 1.1933 5.29018V4.74888C2.07907 5.39565 3.47507 5.78135 5.0002 5.78135C6.52532 5.78135 7.92132 5.39565 8.80709 4.74888ZM1.1933 3.05763C1.1933 3.39251 1.3257 3.70507 1.57729 3.98637C2.20626 4.68293 3.51716 5.11158 5.0002 5.11158C6.48323 5.11158 7.79414 4.67846 8.4231 3.98637C8.68131 3.70507 8.80709 3.39251 8.80709 3.05763C8.80709 3.02191 8.80559 2.98629 8.80261 2.9508C8.75783 2.41849 8.37882 1.91511 7.71468 1.52163C7.62448 1.46916 7.53054 1.41963 7.43321 1.37311C6.75189 1.04749 5.90392 0.869719 5.0002 0.869719C4.0373 0.869719 3.15663 1.07649 2.48542 1.41672C2.41465 1.45297 2.34583 1.49092 2.2791 1.53056C2.14278 1.61035 2.01844 1.69521 1.90658 1.78433C1.45896 2.14244 1.19464 2.58097 1.1933 3.05402C1.1933 3.05522 1.1933 3.05643 1.1933 3.05763ZM1.89713 7.40088C2.72247 7.78722 3.79655 8.01391 5.0002 8.01391C5.55189 8.01391 6.07636 7.96628 6.56213 7.87749C7.47161 7.71146 8.2462 7.40168 8.80709 6.99139C8.76075 8.17465 7.07247 9.13018 5.0002 9.13018C2.92792 9.13018 1.23964 8.17465 1.1933 6.99139C1.40102 7.14218 1.63684 7.27939 1.89713 7.40088Z"
                              fill="white"
                            />
                          </svg>
                        </span>
                        <span>80</span>
                      </h5>
                      <h6>Per month</h6>
                      <h1>Premium Duo Plan</h1>
                      <p>Enjoy 2 or more content types!</p>
                      <div class="get_started">
                        <button class="btn-action" (click)="scrollToBottom()">
                          Get started
                        </button>
                      </div>
                      <ul>
                        <li>Browse all Content</li>
                        <li>
                          Ad-free Access to <b>any two</b> for Music OR Video
                          OR Podcast
                        </li>
                        <li>Listen Online</li>
                        <li>Share Playlists with friends</li>
                        <li>Lyrics View</li>
                        <li>See What Friends are listening to</li>
                        <li>Offline Download</li>
                        <li>Single Device</li>
                      </ul>
                    </div>
                  </div>
                  <div class="swiper-slide">
                    <div class="plan_details">
<!--                      <h5>$2.99</h5>-->
                      <h5 class="d-flex align-items-center">
                        <span class="pe-1">
                          <svg
                            width="10"
                            height="10"
                            viewBox="0 0 10 10"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M0.200195 3.05763V5.29018V6.94228C0.200195 8.51846 2.35192 9.79995 5.0002 9.79995C7.64847 9.79995 9.8002 8.51846 9.8002 6.94228V5.29018V3.05763C9.8002 2.28963 9.2904 1.56628 8.37012 1.01707C7.46309 0.490185 6.27137 0.199951 5.0002 0.199951C3.72902 0.199951 2.53068 0.490184 1.63027 1.02153C0.736572 1.55054 0.229988 2.24799 0.201469 2.99119C0.200735 3.01031 0.200318 3.02945 0.200219 3.04863C0.200203 3.05163 0.200195 3.05463 0.200195 3.05763ZM8.80709 4.74888V5.29018C8.80709 6.46005 7.17178 7.34414 5.0002 7.34414C3.10006 7.34414 1.61051 6.66725 1.26781 5.71432C1.21885 5.57818 1.1933 5.43642 1.1933 5.29018V4.74888C2.07907 5.39565 3.47507 5.78135 5.0002 5.78135C6.52532 5.78135 7.92132 5.39565 8.80709 4.74888ZM1.1933 3.05763C1.1933 3.39251 1.3257 3.70507 1.57729 3.98637C2.20626 4.68293 3.51716 5.11158 5.0002 5.11158C6.48323 5.11158 7.79414 4.67846 8.4231 3.98637C8.68131 3.70507 8.80709 3.39251 8.80709 3.05763C8.80709 3.02191 8.80559 2.98629 8.80261 2.9508C8.75783 2.41849 8.37882 1.91511 7.71468 1.52163C7.62448 1.46916 7.53054 1.41963 7.43321 1.37311C6.75189 1.04749 5.90392 0.869719 5.0002 0.869719C4.0373 0.869719 3.15663 1.07649 2.48542 1.41672C2.41465 1.45297 2.34583 1.49092 2.2791 1.53056C2.14278 1.61035 2.01844 1.69521 1.90658 1.78433C1.45896 2.14244 1.19464 2.58097 1.1933 3.05402C1.1933 3.05522 1.1933 3.05643 1.1933 3.05763ZM1.89713 7.40088C2.72247 7.78722 3.79655 8.01391 5.0002 8.01391C5.55189 8.01391 6.07636 7.96628 6.56213 7.87749C7.47161 7.71146 8.2462 7.40168 8.80709 6.99139C8.76075 8.17465 7.07247 9.13018 5.0002 9.13018C2.92792 9.13018 1.23964 8.17465 1.1933 6.99139C1.40102 7.14218 1.63684 7.27939 1.89713 7.40088Z"
                              fill="white"
                            />
                          </svg>
                        </span>
                        <span>60</span>
                      </h5>
                      <h6>Per month</h6>
                      <h1>Premium Solo Plan</h1>
                      <p>Make your pick and enjoy all its content!</p>
                      <div class="get_started">
                        <button class="btn-action" (click)="scrollToBottom()">
                          Get started
                        </button>
                      </div>
                      <ul>
                        <li>Browse all Content</li>
                        <li>
                          Ad-free Access to <b>any one</b> for Music OR Video
                          OR Podcast
                        </li>
                        <li>Listen Online</li>
                        <li>Share Playlists with friends</li>
                        <li>Lyrics View</li>
                        <li>See What Friends are listening to</li>
                        <li>Offline Download</li>
                        <li>Single Device</li>
                      </ul>
                    </div>
                  </div>
                  <div class="swiper-slide">
                    <div class="plan_details">
<!--                      <h5>$0.00</h5>-->
                      <h5 class="d-flex align-items-center">
                        <span class="pe-1">
                          <svg
                            width="10"
                            height="10"
                            viewBox="0 0 10 10"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M0.200195 3.05763V5.29018V6.94228C0.200195 8.51846 2.35192 9.79995 5.0002 9.79995C7.64847 9.79995 9.8002 8.51846 9.8002 6.94228V5.29018V3.05763C9.8002 2.28963 9.2904 1.56628 8.37012 1.01707C7.46309 0.490185 6.27137 0.199951 5.0002 0.199951C3.72902 0.199951 2.53068 0.490184 1.63027 1.02153C0.736572 1.55054 0.229988 2.24799 0.201469 2.99119C0.200735 3.01031 0.200318 3.02945 0.200219 3.04863C0.200203 3.05163 0.200195 3.05463 0.200195 3.05763ZM8.80709 4.74888V5.29018C8.80709 6.46005 7.17178 7.34414 5.0002 7.34414C3.10006 7.34414 1.61051 6.66725 1.26781 5.71432C1.21885 5.57818 1.1933 5.43642 1.1933 5.29018V4.74888C2.07907 5.39565 3.47507 5.78135 5.0002 5.78135C6.52532 5.78135 7.92132 5.39565 8.80709 4.74888ZM1.1933 3.05763C1.1933 3.39251 1.3257 3.70507 1.57729 3.98637C2.20626 4.68293 3.51716 5.11158 5.0002 5.11158C6.48323 5.11158 7.79414 4.67846 8.4231 3.98637C8.68131 3.70507 8.80709 3.39251 8.80709 3.05763C8.80709 3.02191 8.80559 2.98629 8.80261 2.9508C8.75783 2.41849 8.37882 1.91511 7.71468 1.52163C7.62448 1.46916 7.53054 1.41963 7.43321 1.37311C6.75189 1.04749 5.90392 0.869719 5.0002 0.869719C4.0373 0.869719 3.15663 1.07649 2.48542 1.41672C2.41465 1.45297 2.34583 1.49092 2.2791 1.53056C2.14278 1.61035 2.01844 1.69521 1.90658 1.78433C1.45896 2.14244 1.19464 2.58097 1.1933 3.05402C1.1933 3.05522 1.1933 3.05643 1.1933 3.05763ZM1.89713 7.40088C2.72247 7.78722 3.79655 8.01391 5.0002 8.01391C5.55189 8.01391 6.07636 7.96628 6.56213 7.87749C7.47161 7.71146 8.2462 7.40168 8.80709 6.99139C8.76075 8.17465 7.07247 9.13018 5.0002 9.13018C2.92792 9.13018 1.23964 8.17465 1.1933 6.99139C1.40102 7.14218 1.63684 7.27939 1.89713 7.40088Z"
                              fill="white"
                            />
                          </svg>
                        </span>
                        <span>0.00</span>
                      </h5>
                      <h6>Per month</h6>
                      <h1>Free Plan</h1>
                      <p>All the content you can consume for FREE!</p>
                      <div class="get_started">
                        <button class="btn-action" (click)="scrollToBottom()">
                          Get started
                        </button>
                      </div>
                      <ul>
                        <li>Browse all Content</li>
                        <li>Listen Online</li>
                        <li>Single Device</li>
                        <li>Radio</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </swiper>
            </div>
          </div>
        </div>
      </div>
      <div class="row pricing_for_web">
        <div class="col-lg-3 col-md-12">
          <div class="plan_details">
            <h5>
              <svg
                width="10"
                height="10"
                viewBox="0 0 10 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M0.200195 3.05763V5.29018V6.94228C0.200195 8.51846 2.35192 9.79995 5.0002 9.79995C7.64847 9.79995 9.8002 8.51846 9.8002 6.94228V5.29018V3.05763C9.8002 2.28963 9.2904 1.56628 8.37012 1.01707C7.46309 0.490185 6.27137 0.199951 5.0002 0.199951C3.72902 0.199951 2.53068 0.490184 1.63027 1.02153C0.736572 1.55054 0.229988 2.24799 0.201469 2.99119C0.200735 3.01031 0.200318 3.02945 0.200219 3.04863C0.200203 3.05163 0.200195 3.05463 0.200195 3.05763ZM8.80709 4.74888V5.29018C8.80709 6.46005 7.17178 7.34414 5.0002 7.34414C3.10006 7.34414 1.61051 6.66725 1.26781 5.71432C1.21885 5.57818 1.1933 5.43642 1.1933 5.29018V4.74888C2.07907 5.39565 3.47507 5.78135 5.0002 5.78135C6.52532 5.78135 7.92132 5.39565 8.80709 4.74888ZM1.1933 3.05763C1.1933 3.39251 1.3257 3.70507 1.57729 3.98637C2.20626 4.68293 3.51716 5.11158 5.0002 5.11158C6.48323 5.11158 7.79414 4.67846 8.4231 3.98637C8.68131 3.70507 8.80709 3.39251 8.80709 3.05763C8.80709 3.02191 8.80559 2.98629 8.80261 2.9508C8.75783 2.41849 8.37882 1.91511 7.71468 1.52163C7.62448 1.46916 7.53054 1.41963 7.43321 1.37311C6.75189 1.04749 5.90392 0.869719 5.0002 0.869719C4.0373 0.869719 3.15663 1.07649 2.48542 1.41672C2.41465 1.45297 2.34583 1.49092 2.2791 1.53056C2.14278 1.61035 2.01844 1.69521 1.90658 1.78433C1.45896 2.14244 1.19464 2.58097 1.1933 3.05402C1.1933 3.05522 1.1933 3.05643 1.1933 3.05763ZM1.89713 7.40088C2.72247 7.78722 3.79655 8.01391 5.0002 8.01391C5.55189 8.01391 6.07636 7.96628 6.56213 7.87749C7.47161 7.71146 8.2462 7.40168 8.80709 6.99139C8.76075 8.17465 7.07247 9.13018 5.0002 9.13018C2.92792 9.13018 1.23964 8.17465 1.1933 6.99139C1.40102 7.14218 1.63684 7.27939 1.89713 7.40088Z"
                  fill="white"
                />
              </svg>
              100
            </h5>
            <h6>Per month</h6>
            <h1>Elite Plan</h1>
            <p class="plan_des">Our most affordable plan offers so much!</p>
            <div class="get_started">
              <button class="btn-action" (click)="scrollToBottom()">
                Get started
              </button>
            </div>
            <ul>
              <li>Browse all Content</li>
              <li>
                Uninterrupted-Ad-free Access to <b>all -</b> from Music,Vidoe
                and Podcast
              </li>
              <li>Listen Online</li>
              <li>Share Playlists with friends</li>
              <li>Lyrics View</li>
              <li>See What Friends are listening to</li>
              <li>Offline Download</li>
              <li>Up to 4 devices</li>
              <li>Up to 4 Profiles</li>
            </ul>
          </div>
        </div>

        <div class="col-lg-3 col-md-12">
          <div class="plan_details">
            <h5>
              <svg
                width="10"
                height="10"
                viewBox="0 0 10 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M0.200195 3.05763V5.29018V6.94228C0.200195 8.51846 2.35192 9.79995 5.0002 9.79995C7.64847 9.79995 9.8002 8.51846 9.8002 6.94228V5.29018V3.05763C9.8002 2.28963 9.2904 1.56628 8.37012 1.01707C7.46309 0.490185 6.27137 0.199951 5.0002 0.199951C3.72902 0.199951 2.53068 0.490184 1.63027 1.02153C0.736572 1.55054 0.229988 2.24799 0.201469 2.99119C0.200735 3.01031 0.200318 3.02945 0.200219 3.04863C0.200203 3.05163 0.200195 3.05463 0.200195 3.05763ZM8.80709 4.74888V5.29018C8.80709 6.46005 7.17178 7.34414 5.0002 7.34414C3.10006 7.34414 1.61051 6.66725 1.26781 5.71432C1.21885 5.57818 1.1933 5.43642 1.1933 5.29018V4.74888C2.07907 5.39565 3.47507 5.78135 5.0002 5.78135C6.52532 5.78135 7.92132 5.39565 8.80709 4.74888ZM1.1933 3.05763C1.1933 3.39251 1.3257 3.70507 1.57729 3.98637C2.20626 4.68293 3.51716 5.11158 5.0002 5.11158C6.48323 5.11158 7.79414 4.67846 8.4231 3.98637C8.68131 3.70507 8.80709 3.39251 8.80709 3.05763C8.80709 3.02191 8.80559 2.98629 8.80261 2.9508C8.75783 2.41849 8.37882 1.91511 7.71468 1.52163C7.62448 1.46916 7.53054 1.41963 7.43321 1.37311C6.75189 1.04749 5.90392 0.869719 5.0002 0.869719C4.0373 0.869719 3.15663 1.07649 2.48542 1.41672C2.41465 1.45297 2.34583 1.49092 2.2791 1.53056C2.14278 1.61035 2.01844 1.69521 1.90658 1.78433C1.45896 2.14244 1.19464 2.58097 1.1933 3.05402C1.1933 3.05522 1.1933 3.05643 1.1933 3.05763ZM1.89713 7.40088C2.72247 7.78722 3.79655 8.01391 5.0002 8.01391C5.55189 8.01391 6.07636 7.96628 6.56213 7.87749C7.47161 7.71146 8.2462 7.40168 8.80709 6.99139C8.76075 8.17465 7.07247 9.13018 5.0002 9.13018C2.92792 9.13018 1.23964 8.17465 1.1933 6.99139C1.40102 7.14218 1.63684 7.27939 1.89713 7.40088Z"
                  fill="white"
                />
              </svg>
              80
            </h5>
            <h6>Per month</h6>
            <h1>Premium Duo Plan</h1>
            <p class="plan_des">Enjoy 2 or more content types!</p>
            <div class="get_started">
              <button class="btn-action" (click)="scrollToBottom()">
                Get started
              </button>
            </div>
            <ul>
              <li>Browse all Content</li>
              <li>
                Ad-free Access to <b>any two</b> From Music, Video OR Podcast
              </li>
              <li>Listen Online</li>
              <li>Share Playlists with friends</li>
              <li>Lyrics View</li>
              <li>See What Friends are listening to</li>
              <li>Offline Download</li>
              <li>Up to Two Devices</li>
              <li>Up to Two Profiles</li>
            </ul>
          </div>
        </div>

        <div class="col-lg-3 col-md-12">
          <div class="plan_details">
            <h5>
              <svg
                width="10"
                height="10"
                viewBox="0 0 10 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M0.200195 3.05763V5.29018V6.94228C0.200195 8.51846 2.35192 9.79995 5.0002 9.79995C7.64847 9.79995 9.8002 8.51846 9.8002 6.94228V5.29018V3.05763C9.8002 2.28963 9.2904 1.56628 8.37012 1.01707C7.46309 0.490185 6.27137 0.199951 5.0002 0.199951C3.72902 0.199951 2.53068 0.490184 1.63027 1.02153C0.736572 1.55054 0.229988 2.24799 0.201469 2.99119C0.200735 3.01031 0.200318 3.02945 0.200219 3.04863C0.200203 3.05163 0.200195 3.05463 0.200195 3.05763ZM8.80709 4.74888V5.29018C8.80709 6.46005 7.17178 7.34414 5.0002 7.34414C3.10006 7.34414 1.61051 6.66725 1.26781 5.71432C1.21885 5.57818 1.1933 5.43642 1.1933 5.29018V4.74888C2.07907 5.39565 3.47507 5.78135 5.0002 5.78135C6.52532 5.78135 7.92132 5.39565 8.80709 4.74888ZM1.1933 3.05763C1.1933 3.39251 1.3257 3.70507 1.57729 3.98637C2.20626 4.68293 3.51716 5.11158 5.0002 5.11158C6.48323 5.11158 7.79414 4.67846 8.4231 3.98637C8.68131 3.70507 8.80709 3.39251 8.80709 3.05763C8.80709 3.02191 8.80559 2.98629 8.80261 2.9508C8.75783 2.41849 8.37882 1.91511 7.71468 1.52163C7.62448 1.46916 7.53054 1.41963 7.43321 1.37311C6.75189 1.04749 5.90392 0.869719 5.0002 0.869719C4.0373 0.869719 3.15663 1.07649 2.48542 1.41672C2.41465 1.45297 2.34583 1.49092 2.2791 1.53056C2.14278 1.61035 2.01844 1.69521 1.90658 1.78433C1.45896 2.14244 1.19464 2.58097 1.1933 3.05402C1.1933 3.05522 1.1933 3.05643 1.1933 3.05763ZM1.89713 7.40088C2.72247 7.78722 3.79655 8.01391 5.0002 8.01391C5.55189 8.01391 6.07636 7.96628 6.56213 7.87749C7.47161 7.71146 8.2462 7.40168 8.80709 6.99139C8.76075 8.17465 7.07247 9.13018 5.0002 9.13018C2.92792 9.13018 1.23964 8.17465 1.1933 6.99139C1.40102 7.14218 1.63684 7.27939 1.89713 7.40088Z"
                  fill="white"
                />
              </svg>
              60
            </h5>
            <h6>Per month</h6>
            <h1>Premium Solo Plan</h1>
            <p class="plan_des">Make your pick and enjoy all its content!</p>
            <div class="get_started">
              <button class="btn-action" (click)="scrollToBottom()">
                Get started
              </button>
            </div>
            <ul>
              <li>Browse all Content</li>
              <li>
                Ad-free Access to <b>any one</b> from Music OR Video OR Podcast
              </li>
              <li>Listen Online</li>
              <li>Share Playlists with friends</li>
              <li>Lyrics View</li>
              <li>See What Friends are listening to</li>
              <li>Offline Download</li>
              <li>Single Device</li>
              <li>Single Profile</li>
            </ul>
          </div>
        </div>
        <div class="col-lg-3 col-md-12">
          <div class="plan_details">
            <h5>
              <svg
                width="10"
                height="10"
                viewBox="0 0 10 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M0.200195 3.05763V5.29018V6.94228C0.200195 8.51846 2.35192 9.79995 5.0002 9.79995C7.64847 9.79995 9.8002 8.51846 9.8002 6.94228V5.29018V3.05763C9.8002 2.28963 9.2904 1.56628 8.37012 1.01707C7.46309 0.490185 6.27137 0.199951 5.0002 0.199951C3.72902 0.199951 2.53068 0.490184 1.63027 1.02153C0.736572 1.55054 0.229988 2.24799 0.201469 2.99119C0.200735 3.01031 0.200318 3.02945 0.200219 3.04863C0.200203 3.05163 0.200195 3.05463 0.200195 3.05763ZM8.80709 4.74888V5.29018C8.80709 6.46005 7.17178 7.34414 5.0002 7.34414C3.10006 7.34414 1.61051 6.66725 1.26781 5.71432C1.21885 5.57818 1.1933 5.43642 1.1933 5.29018V4.74888C2.07907 5.39565 3.47507 5.78135 5.0002 5.78135C6.52532 5.78135 7.92132 5.39565 8.80709 4.74888ZM1.1933 3.05763C1.1933 3.39251 1.3257 3.70507 1.57729 3.98637C2.20626 4.68293 3.51716 5.11158 5.0002 5.11158C6.48323 5.11158 7.79414 4.67846 8.4231 3.98637C8.68131 3.70507 8.80709 3.39251 8.80709 3.05763C8.80709 3.02191 8.80559 2.98629 8.80261 2.9508C8.75783 2.41849 8.37882 1.91511 7.71468 1.52163C7.62448 1.46916 7.53054 1.41963 7.43321 1.37311C6.75189 1.04749 5.90392 0.869719 5.0002 0.869719C4.0373 0.869719 3.15663 1.07649 2.48542 1.41672C2.41465 1.45297 2.34583 1.49092 2.2791 1.53056C2.14278 1.61035 2.01844 1.69521 1.90658 1.78433C1.45896 2.14244 1.19464 2.58097 1.1933 3.05402C1.1933 3.05522 1.1933 3.05643 1.1933 3.05763ZM1.89713 7.40088C2.72247 7.78722 3.79655 8.01391 5.0002 8.01391C5.55189 8.01391 6.07636 7.96628 6.56213 7.87749C7.47161 7.71146 8.2462 7.40168 8.80709 6.99139C8.76075 8.17465 7.07247 9.13018 5.0002 9.13018C2.92792 9.13018 1.23964 8.17465 1.1933 6.99139C1.40102 7.14218 1.63684 7.27939 1.89713 7.40088Z"
                  fill="white"
                />
              </svg>
              0.00
            </h5>
            <h6>Per month</h6>
            <h1>Free Plan</h1>
            <p class="plan_des">All the content you can consume for FREE!</p>
            <div class="get_started">
              <button class="btn-action" (click)="scrollToBottom()">
                Get started
              </button>
            </div>
            <ul>
              <li>Browse all Content</li>
              <li>Listen Online</li>
              <li>Single Device</li>
              <li>Radio</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="live_show" id="social">
    <div class="container live_show_cont">
      <div class="row align-items-center live_show_sec">
        <div class="col-md-12 col-lg-4 order-md-2 order-2 order-lg-1">
          <div class="slide_mobile">
            <img src="assets/images/slideMobil3.png" />
            <div class="fav_list for_mobile">
              <ul>
                <li>
                  <img src="assets/images/live_show_1.png" />
                  <p>Watch <br />out!</p>
                </li>
                <li>
                  <img src="assets/images/live_show_2.png" />
                  <p>
                    FUN FUN <br />
                    FUN!
                  </p>
                </li>
                <li>
                  <img src="assets/images/live_show_3.png" />
                  <p>
                    What<br />
                    about?
                  </p>
                </li>
                <li>
                  <img src="assets/images/live_show_4.png" />
                  <p>OUF</p>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div class="col-md-12 col-lg-8 order-md-1 order-1 order-lg-2">
          <div class="slide_mobile_detials">
            <div class="wifi_icon"><img src="assets/images/fav.svg" /></div>
            <h1>
              Enjoy live shows from <br />
              your favorite creator.
            </h1>
            <p>Watch the show live or save for later</p>
          </div>
          <div class="fav_list for_detop">
            <ul>
              <li>
                <img src="assets/images/live_show_1.png" />
                <p>Watch <br />out!</p>
              </li>
              <li>
                <img src="assets/images/live_show_2.png" />
                <p>
                  FUN FUN <br />
                  FUN!
                </p>
              </li>
              <li>
                <img src="assets/images/live_show_3.png" />
                <p>
                  What<br />
                  about?
                </p>
              </li>
              <li>
                <img src="assets/images/live_show_4.png" />
                <p>OUF</p>
              </li>
              <!-- <li>
                                <img src="assets/images/live_show_5.png" />
                                <p>This is a <br>joke!</p>
                            </li> -->
            </ul>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 col-lg-8 offset-lg-4">
          <div class="playlist">
            <div class="play_list_heading">
              <div class="playlist_icon">
                <img src="assets/images/playlist_book.png" />
              </div>
              <div class="playlist_heading">
                <h1>Check the playlists</h1>
                <p>
                  Curated playlists from Africa's <br />
                  tastemakers!
                </p>
              </div>
            </div>

            <div class="playlist_slider_mobile">
              <swiper [config]="config">
                <div class="swiper-wrapper">
                  <div class="swiper-slide">
                    <div class="playlist pt-0">
                      <ul>
                        <li>
                          <img src="assets/images/album_1.png" />
                          <div class="list_head">
                            <h1>No Stress</h1>
                            <p>Robert Fox</p>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="swiper-slide">
                    <div class="playlist pt-0">
                      <ul>
                        <li>
                          <img src="assets/images/album_2.png" />
                          <div class="list_head">
                            <h1>Sport Motivation</h1>
                            <p>Jacob Jones</p>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="swiper-slide">
                    <div class="playlist pt-0">
                      <ul>
                        <li>
                          <img src="assets/images/album_3.png" />
                          <div class="list_head">
                            <h1>Soirée Afro</h1>
                            <p>Brooklyn Simmons</p>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </swiper>
            </div>

            <ul class="playlist_for_destop">
              <li>
                <img src="assets/images/album_1.png" />
                <div class="list_head">
                  <h1>No Stress</h1>
                  <p>Robert Fox</p>
                </div>
              </li>
              <li>
                <img src="assets/images/album_2.png" />
                <div class="list_head">
                  <h1>Sport Motivation</h1>
                  <p>Jacob Jones</p>
                </div>
              </li>
              <li>
                <img src="assets/images/album_3.png" />
                <div class="list_head">
                  <h1>Soirée Afro</h1>
                  <p>Brooklyn Simmons</p>
                </div>
              </li>
              <li>
                <img src="assets/images/album_4.png" />
                <div class="list_head">
                  <h1>New Afro</h1>
                  <p>Jerome Bell</p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="music_section_heading">
        <div class="music_icon d-flex justify-content-center mt-3 mt-md-5">
          <img src="assets/images/music.svg" />
        </div>
        <h3 class="text-white text-center mt-3">
          <strong>Find the music you were looking for</strong>
        </h3>
      </div>
      <div class="phone_slider phone_slider_destop">
        <img src="assets/images/screens_grid.png" />
      </div>
    </div>
  </section>

  <section class="playlist_radio">
    <div class="container-fluid">
      <div class="singer_slider_main">
        <swiper [config]="config">
          <div class="swiper-wrapper">
            <div class="swiper-slide">
              <div class="playlist_radio_main">
                <img src="assets/images/card_1.png" />
                <div class="playlist_detail_main">
                  <h1>What are your friends listen to?</h1>
                  <p>
                    Share your playlist with your friends or checkout what they
                    are listening to
                  </p>
                </div>
              </div>
            </div>
            <div class="swiper-slide">
              <div class="playlist_radio_main">
                <img src="assets/images/card_2.png" />
                <div class="playlist_detail_main">
                  <h1>Discover trending albums</h1>
                  <p>Don't miss out on the latest music and trending content</p>
                </div>
              </div>
            </div>
            <div class="swiper-slide">
              <div class="playlist_radio_main">
                <img src="assets/images/card_3.png" />
                <div class="playlist_detail_main">
                  <h1>Top African Creators</h1>
                  <p>Discover music from Africa's top creators.</p>
                </div>
              </div>
            </div>
          </div>
        </swiper>
      </div>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-md-12 col-lg-6">
          <div class="radio_icon"><img src="assets/images/radio.svg" /></div>
          <div class="radio_Detail">
            <h1>
              Playlist, Radio, Video, <br />podcast... Do you <br />
              need someting more?
            </h1>

            <p>
              If you are thinking Video, we have got that too... <br />
              and much more.
            </p>
          </div>
        </div>
        <div class="col-md-12 col-lg-6">
          <div class="radio_img">
            <img src="assets/images/screens_playlists_radio.png" />
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="faqs">
    <div class="container faq_bg">
      <div class="row">
        <div class="col-md-12 col-lg-4">
          <div class="faqs_details">
            <div class="faq_icon">
              <img src="assets/images/chat.svg" />
            </div>
            <h1>Frequently asked questions</h1>
            <p>Have questions? We have answers!</p>
            <div class="check_ques_btn">
              <button class="btn-action">Check FAQs section</button>
            </div>
          </div>
        </div>
        <div class="col-md-12 col-lg-8">
          <div class="faqs_list">
            <ul *ngIf="!isShowAll">
              <li *ngFor="let faq of faqs.slice(0, 4); let i = index">
                {{ faq }}
              </li>
            </ul>

            <ul *ngIf="isShowAll">
              <li *ngFor="let faq of faqs; let i = index">{{ faq }}</li>
            </ul>

            <div class="all_faq cursor-pointer" [routerLink]="['/support']">
              <a (click)="showAllFaqs()"
                >{{ !isShowAll ? 'See all FAQs' : 'See less  FAQs' }}
                <span><img src="assets/images/arrow.png" /></span
              ></a>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="artist_banner">
            <div class="art_banner">
              <div class="art_img">
                <img src="assets/images/avatar_girl.png" />
              </div>
              <div class="art_banner_heading">
                <h1>
                  Hi!!!<br />
                  Are you A Musician, Podcaster or Comedian?
                </h1>
                <p>
                  Share your content on the Kwot App now and gain access to a
                  wide
                  <br />
                  global audience with the ability to monetize your content,<br />
                  grow your audience and connect with new listeners from around
                  the world.
                </p>
                <div class="share_music_btn">
                  <button class="btn-action" [routerLink]="['/artist']">
                    Join Kwot
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!-- <section class="artist_banner">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="art_banner">
                        <div class="art_img"><img src="assets/images/avatar_girl.png" /></div>
                        <div class="art_banner_heading">
                            <h1>Hi!!!<br> Are you an artist?</h1>
                            <p>Donec vel orci ut leo ultricies congue et sit amet dolor.<br> Curabitur eget faucibus sem. Suspendisse blandit,<br> augue non.</p>
                            <div class="share_music_btn"><button class="btn-action">Share your music</button></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section> -->

  <section class="review_section">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="community">
            <h1>Local Content for a Global Audience</h1>
            <p>
              From Africa to the world our mission is to give the gift of
              African sound to listeners from all over the world.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="tutorial_slider container-fluid">
      <swiper [config]="config">
        <div class="swiper-wrapper">
          <div class="swiper-slide">
            <div class="slide_data">
              <div class="client_star">
                <img src="assets/images/client_star.png" />
              </div>
              <div class="client_feedback">
                <p>
                  “Hands down the best streaming app for all things African!”
                </p>
                <h1>Tunde Balogun</h1>
              </div>
            </div>
          </div>

          <div class="swiper-slide">
            <div class="slide_data">
              <div class="client_star">
                <img src="assets/images/client_star.png" />
              </div>
              <div class="client_feedback">
                <p>“Finally an audio comedy experience! Kwot for the win!”</p>
                <h1>Mary Okoye</h1>
              </div>
            </div>
          </div>
          <div class="swiper-slide">
            <div class="slide_data">
              <div class="client_star">
                <img src="assets/images/client_star.png" />
              </div>
              <div class="client_feedback">
                <p>
                  “I can't believe I can finally listen to my favorite Nigeria
                  radio stations right here in New York!”
                </p>
                <h1>Funke Ladipo</h1>
              </div>
            </div>
          </div>
        </div>
      </swiper>
    </div>
  </section>

  <section class="contact_us">
    <div class="container footer_bg_sec">
      <div class="row">
        <div class="col-md-12 col-lg-4">
          <div class="contact_icon">
            <img src="assets/images/message.svg" />
          </div>
        </div>
        <div class="col-md-12 col-lg-8">
          <div class="contact_detail">
            <h1>Contact us</h1>
            <div class="cont_fields">
              <div class="name_fields">
                <div class="name_inputfield">
                  <label>Name</label>
                  <input type="text" placeholder="Your name here" />
                </div>
                <div class="name_inputfield">
                  <label>Email</label>
                  <input type="Email" placeholder="email@example.com" />
                </div>
              </div>
              <div class="msg_field">
                <textarea
                  placeholder="What do you wanna talk about?"
                ></textarea>
              </div>
              <div class="submit_btn">
                <button class="btn-action">Submit now</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <frontend-footer></frontend-footer>
    </div>
  </section>
</div>

import {Component, HostListener} from '@angular/core';
import {SwiperOptions} from 'swiper';

@Component({
  selector: 'frontend-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.scss'],
})
export class SupportComponent {
  config: SwiperOptions = {
    // pagination: {
    //   el: '.swiper-pagination',
    //   clickable: true
    // },
    // navigation: {
    //   nextEl: '.swiper-button-next',
    //   prevEl: '.swiper-button-prev'
    // },
    autoplay: true,
    spaceBetween: 20,
    breakpoints: {
      992: {
        slidesPerView: 3,
      },
    },
  };
  searchFaq: string;
  selectedFAQ: any;
  faq: any[] = [
    {
      question: 'Do I need to make a payment every month?',
      answer: 'No; you need to make the payment during the first month, thereafter you plan is renewed every subsequent month automatically.',
      type: 'started'
    },
    {
      question: 'How can I cancel my Premium or Ultra Premium plan?',
      answer: 'You can cancel your plan anytime. You will continue to enjoy the benefits of your plan till your next payment is due. Thereafter, you will be on the free plan.',
      type: 'started'
    },
    {
      question: 'Can I experience the Premium features before I pay?',
      answer: 'Yes; we offer a free trial period for the Premium plan.You can activate the free trial of Premium plan after you create your account on Kwot.',
      type: 'started'
    },
    {
      question: 'Can I choose to make my playlists public or keep them private?',
      answer: 'Yes; you can create public as well as private playlists.Public playlists will be visible to your friends and people who follow you.',
      type: 'payment'
    },
    {
      question: 'How can I collaborate with my friends to create playlists?',
      answer: 'Create a playlist and invite your friends to edit the same. You will be able to specify who has edit rights and only those people will be able to make change to your playlist.',
      type: 'payment'
    },
    {
      question: 'How will I know about changes to my playlists?',
      answer: 'You can choose to be notified every time a change is made to the playlists you follow.',
      type: 'payment'
    },
    {
      question: 'Can I see what my friends are listening to?',
      answer: 'Yes; you can see what your friends are listening to by following them and viewing their public playlists. You can also follow artists and other Kwot subscribers.',
      type: 'social'
    },
    {
      question: 'How will I get updates from Kwot?',
      answer: 'You can choose to receive notification through SMS, email, or both. You can make your choice in your account settings on Kwot.',
      type: 'social'
    },
    {
      question: 'What are the charges for Kwot?',
      answer: 'Kwot has a range of plans, starting from a Free plan to the Ultra Premium plan that is loaded with feature and provides access for up to four people.',
      type: 'social'
    },
    {
      question: 'Which special features are available for Kwot listeners?',
      answer: 'The social features of Kwot allow listeners to view the playlist of their friends and also make their playlists public for their friends to follow and edit.',
      type: 'social'
    },
    {
      question: 'Can I keep my playlist private and not show it to others?',
      answer: 'Yes; you can create private playlist, which will not be visible to others. You can also block specific followers from viewing your playlist. Similarly, you can also follow or unfollow other\'s playlists.',
      type: 'social'
    }
  ];

  constructor(
  ) {}

  dataSelected(started: any, payment: any, social: any) {
    if (!this.selectedFAQ) {
      return;
    }
    if (this.selectedFAQ.type === 'payment') {
      payment.scrollIntoView({behavior: "smooth"});
    }
    if (this.selectedFAQ.type === 'started') {
      started.scrollIntoView({behavior: "smooth"});
    }
    if (this.selectedFAQ.type === 'social') {
      social.scrollIntoView({behavior: "smooth"});
    }
  }

  getFAQ(type: string) {
    return this.faq.filter(t => t.type === type);
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    const number =
      window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop ||
      0;
    if (number > 100) {
      document.querySelectorAll('.appendClass').forEach((c) => {
        c.classList.add('text-smaller');
      });
    } else {
      document.querySelectorAll('.appendClass').forEach((c) => {
        c.classList.remove('text-smaller');
      });
    }
  }
}

import {Pipe, PipeTransform} from '@angular/core';
import {CurrencyPipe} from "@angular/common";
import {Observable} from "rxjs";
import {CurrencyData} from "@frontend/data-models";
import {UtilService} from "../../../../payment-gatways/src/lib/services/util.service";
import {PaymentGatwayService} from "../../../../payment-gatways/src/lib/services/payment-gatway.service";
import {allowedCurrency} from "../../../../data-models/src/lib/consts/AllowedCurrency";
import {LoaderService, LocalstorageService} from "@frontend/app-config";

@Pipe({
  name: 'currencyConvert'
})
export class CurrencyConvertPipe implements PipeTransform {
  defaultConvertParams: any = {
    onlyPrice: false,
    type: '',
    item: null
  };
  currencyData: CurrencyData = null;
  currencyRate: any;

  constructor(
    private currencyPipe: CurrencyPipe,
    private utilService: UtilService,
    private paymentService: PaymentGatwayService,
    private loaderService: LoaderService,
    private localStorageService: LocalstorageService,
) {
    this.loaderService.show();

    let currency = this.localStorageService.updateCurrencyData('get');
    if (!this.currencyData && !currency) {
      this.paymentService.getConversationRates().subscribe((data: any) => {
        this.loaderService.hide();
        this.currencyData = data.data;
        this.localStorageService.updateCurrencyData('store', data.data);

        // this.currencyData.location = {countryName: 'Nigeria', countryCode: 'NG', currencyCode: 'NGN', currencySymbol: '₦'};
      });
    } else {
      this.loaderService.hide();
      this.currencyData = currency;
    }


  }

  transform(input: any, convertParameters: any = {}): Observable<any> {
    convertParameters = {...this.defaultConvertParams, currencyData: this.currencyData, ...convertParameters};
    return new Observable(observable => {
      this.setData(input, convertParameters, observable);
    })
  }

  setData(input: any, convertParameters: any, observable: any) {
    let finalAmount = Number(input);
    if (this.currencyData && this.currencyData.rates) {
      let currencyData = this.getCurrencyData(convertParameters, finalAmount);
      let convertedAmount = currencyData.amount;
      if (!convertParameters.onlyPrice) {
        observable.next(this.currencyPipe.transform(convertedAmount, currencyData.currencyCode, currencyData.currencySymbol));
      } else {
        observable.next(convertedAmount);
      }
    } else {
      setTimeout(() => {
        this.setData(input, convertParameters, observable);
      }, 1000);
    }
  }

  getCurrencyData(convertParameters: any, finalAmount: any) {
    let currencyData: any = {currencySymbol: '$', currencyCode: 'USD', amount: finalAmount};
    let rate = 1;
    let currencyCode = 'USD';

    if (this.currencyData.rates) {
      const values = Object.values(this.currencyData.rates);
      if (allowedCurrency.includes(this.currencyData.location.currencyCode) ) {
        this.currencyRate = values.find(
          (item: any) => item.code === this.currencyData.location?.currencyCode
        );
      } else {
        this.currencyRate = values.find((item: any) => item.code === 'USD');
      }
    }

    if (convertParameters.type) {
        currencyData = this.utilService.getCurrencyCodeAndSymbolAndAmountWithRates(
          finalAmount,
          currencyCode,
          this.currencyRate?.code || currencyCode,
          rate,
          this.currencyRate?.value || rate
        );
    }
    return currencyData;
  }
}

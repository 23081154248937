import {createReducer, on, Action} from '@ngrx/store';
import {
  Register,
  RegisterError,
  RegisterSuccess,
  SendOtp,
  SendOtpError,
  SendOtpSuccess,
  ValidateOtp,
  ValidateOtpError,
  ValidateOtpSuccess,
  Login,
  LoginError,
  LoginSuccess,
  ForgotPassword,
  ForgotPasswordError,
  ForgotPasswordSuccess,
  ResetPassword,
  ResetPasswordSuccess,
  ResetPasswordError,
  CheckAccountVerifyLink,
  CheckAccountVerifyLinkError,
  CheckAccountVerifyLinkSuccess,
  VerifyData,
  VerifyDataError,
  VerifyDataSuccess,
  LogoutUser,
  PurchaseToken,
  PurchaseTokenSuccess,
  PurchaseTokenError,
  LoginWithToken,
  LoginWithTokenSuccess,
  LoginWithTokenError,
  SignInWithSocial,
  SignInWithSocialSuccess,
} from './auth.actions';
import {User} from "@frontend/data-models";

export interface AuthState {
  registerUser:  User | any | null;
  loginUser: User | any | null;
  signInUserSocial: any;
  success: string;
  error: string;
  isforgotPassword?: boolean;
  isPasswordUpdated?: boolean;
}

const data = localStorage.getItem('[KWOT]-kwotUser');
const initialAuthState: AuthState = {
  loginUser: data ? JSON.parse(data) : null,
  registerUser: null,
  signInUserSocial: null,
  error: '',
  success: '',
  isforgotPassword: false,
  isPasswordUpdated: false
};

const authReducer = createReducer(
  initialAuthState,
  on(Register, (state, {user}) => {
    return {
      ...state,
      registerUser: null,
      signInUserSocial: null,
      error: '',
      success: '',
    };
  }),
  on(RegisterSuccess, (state, {registerUser, message}) => {
    return {
      ...state,
      registerUser,
      signInUserSocial: null,
      success: message,
      error: '',
    };
  }),
  on(RegisterError, (state, {error}) => ({
    ...state,
    error,
    registerUser: null,
    signInUserSocial: null,
    success: '',
  })),
  on(ValidateOtp, (state) => ({
    ...state,
    registerUser: null,
    signInUserSocial: null,
    success: '',
    error: '',
  })),
  on(ValidateOtpSuccess, (state, {success}) => ({
    ...state,
    registerUser: success.data,
    signInUserSocial: null,
    success: success.message,
    isforgotPassword: true,
    error: '',
  })),
  on(ValidateOtpError, (state, {error}) => ({
    ...state,
    registerUser: null,
    signInUserSocial: null,
    success: '',
    error,
  })),

  on(Login, (state, {user}) => {
    return {
      ...state,
      success: '',
      error: '',
    };
  }),

  on(LoginSuccess, (state, {loginUser, message}) => {
    return {
      ...state,
      loginUser,
      signInUserSocial: null,
      success: message,
      error: '',
    };
  }),
  on(LoginError, (state, {error}) => ({
    ...state,
    loginUser: null,
    signInUserSocial: null,
    error,
    success: '',
  })),
  on(SendOtp, (state) => ({
    ...state,
    signInUserSocial: null,
    registerUser: null,
    success: '',
    error: '',
  })),
  on(SendOtpSuccess, (state, {success}) => ({
    ...state,
    registerUser: null,
    signInUserSocial: null,
    success,
    error: '',
  })),
  on(SendOtpError, (state, {error}) => ({
    ...state,
    registerUser: null,
    signInUserSocial: null,
    success: '',
    error,
  })),
  on(ForgotPassword, (state, {user}) => {
    return {
      ...state,
      registerUser: user,
      signInUserSocial: null,
      success: '',
      error: '',
    };
  }),
  on(ForgotPasswordSuccess, (state, {success}) => ({
    ...state,
    signInUserSocial: null,
    success,
    error: '',
  })),
  on(ForgotPasswordError, (state, {error}) => {
    return {
      ...state,
      registerUser: null,
      signInUserSocial: null,
      success: '',
      error,
    };
  }),
  on(ResetPassword, (state) => ({
    ...state,
    registerUser: null,
    signInUserSocial: null,
    success: '',
    error: '',
  })),
  on(ResetPasswordSuccess, (state, {success}) => ({
    ...state,
    registerUser: null,
    signInUserSocial: null,
    success,
    isPasswordUpdated: true,
    error: '',
  })),
  on(ResetPasswordError, (state, {error}) => ({
    ...state,
    registerUser: null,
    signInUserSocial: null,
    success: '',
    error,
  })),

  on(CheckAccountVerifyLink, (state) => ({
    ...state,
    success: '',
    error: '',
  })),
  on(CheckAccountVerifyLinkSuccess, (state, {success}) => ({
    ...state,
    success: '---IGNORE---',
    error: '',
  })),

  on(CheckAccountVerifyLinkError, (state) => ({
    ...state,
    success: '',
    error: '---IGNORE---',
  })),
  on(VerifyData, (state) => ({...state, error: '', success: ''})),
  on(VerifyDataSuccess, (state, {success}) => ({
    ...state,
    error: '',
    success,
  })),
  on(LogoutUser, (state) => ({
    ...state,
    registerUser: null,
    loginUser: null,
    signInUserSocial: null,
    success: '',
    error: '',
  })),
  on(VerifyDataError, (state, {error}) => ({...state, error, success: ''})),

  on(PurchaseToken, (state) => ({...state, error: '', success: ''})),
  on(PurchaseTokenSuccess, (state) => ({...state, error: '', success: 'Purchase token Successfully'})),
  on(PurchaseTokenError, (state, {error}) => ({...state, error, success: ''})),

  on(LoginWithToken, (state) => ({
    ...state,
    user: null,
    registerUser: null,
    signInUserSocial: null,
    error: '',
    success: ''
  })),
  on(LoginWithTokenSuccess, (state, {loginUser, message}) => ({
    ...state,
    user: loginUser,
    registerUser: null,
    signInUserSocial: null,
    error: '',
    success: message
  })),
  on(LoginWithTokenError, (state, {error}) => ({
    ...state,
    user: null,
    registerUser: null,
    signInUserSocial: null,
    error,
    success: ''
  })),

  on(SignInWithSocial, (state) => ({
    ...state,
    user: null,
    registerUser: null,
    signInUserSocial: null,
    error: '',
    success: ''
  })),
  on(SignInWithSocialSuccess, (state, {signInUserSocial}) => ({
    ...state,
    user: null,
    registerUser: null,
    signInUserSocial,
    success: '',
    error: ''
  })),

);

export function reducer(state = initialAuthState, action: Action) {
  return authReducer(state, action);
}

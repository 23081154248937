import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'frontend-custom-select',
  templateUrl: './custom-select.component.html',
  styleUrls: ['./custom-select.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CustomSelectComponent implements OnChanges {
  @Input() selectOptions: any[] = [];
  @Input() labelKey = 'name';
  @Input() valueKey = 'id';
  @Input() selectedValue: any;
  @Output() selectedValueChange = new EventEmitter<any>();
  @Input() placeholder: any;
  @Output() optionSelected = new EventEmitter();
  @Input() isDisabled: any = false;
  @Input() isMultiple = false;
  @Input() formGroup: UntypedFormGroup;
  @Input() searchable = false;
  @Input() headerTemplate: any;
  @Input() customClass: any;
  @Input() appendTo: any;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['selectedValue'] && changes['selectedValue'].currentValue) {
      this.selectedValue = changes['selectedValue'].currentValue;
    }
    if (changes['selectOptions'] && changes['selectOptions'].currentValue) {
      this.selectOptions = changes['selectOptions'].currentValue;
    }
  }

  onSelected(event: any) {
    let option = null;
    if (event) {
      option = { value: event[this.valueKey], isNew: event.isNew };
    }
    this.optionSelected.emit(option);
    if (!this.formGroup) {
      this.selectedValueChange.emit(event[this.valueKey]);
    }
  }
}

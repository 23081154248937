import {Component, OnInit} from '@angular/core';
import {Observable} from "rxjs";
import {RouteConfigLoadEnd, RouteConfigLoadStart, Router} from "@angular/router";
import {LoaderService} from "@frontend/app-config";

@Component({
  selector: 'frontend-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'Kwot';
  loader$: Observable<boolean>;
  loadingRouteConfig!: boolean;

  constructor(private router: Router, private loaderService: LoaderService,) {
    this.router.events.subscribe((event: any) => {
      if (event instanceof RouteConfigLoadStart) {
        this.loadingRouteConfig = true;
      } else if (event instanceof RouteConfigLoadEnd) {
        this.loadingRouteConfig = false;
      }
    })

  }

  ngOnInit() {
    this.loader$ = this.loaderService.loader$;
  }
}

<div class="form-group text-left row mt-4">
  <ng-container *ngIf="elementsOptions?.clientSecret as clientSecret">
    <div class="payment-request-button">
      <ngx-stripe-payment-request-button
        [paymentOptions]="paymentRequestOptions"
        (paymentMethod)="onPaymentMethod($event)"
        (notavailable)="onNotAvailable()"
        (change)="validatePayment($event)"
        (source)="onSource($event)"
        (ready)="onReady()"
        (cancel)="cancelEvent()">
      </ngx-stripe-payment-request-button>
      <p class="or" *ngIf="showOr">OR</p>
    </div>
    <ngx-stripe-payment [appearance]="appearance" (change)="invalidEvents($event)"
                        [doNotCreateUntilClientSecretIsSet]="true"
                        [clientSecret]="clientSecret">
    </ngx-stripe-payment>
  </ng-container>
</div>

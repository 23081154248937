import {Component, EventEmitter, Inject, Input, OnInit, Output} from '@angular/core';
import {AsyncPaymentOptions, Flutterwave} from "flutterwave-angular-v3";
import {APP_CONFIG} from "@frontend/app-config";
import {UtilService} from "../../services/util.service";

@Component({
  selector: 'frontend-flutterwave-payment',
  templateUrl: './flutterwave-payment.component.html',
  styleUrls: ['./flutterwave-payment.component.scss']
})
export class FlutterwavePaymentComponent implements OnInit {
  customerDetails: any;
  customizations: any;
  meta: any;
  paymentData: AsyncPaymentOptions;
  currencyRate: any;

  @Input() tokenDetails: any;
  @Input() currentuser: any;
  @Input() calculatedAmount: any;
  @Input() currencyData: any;
  @Output() paymentConfirmed = new EventEmitter();

  constructor(
    @Inject(APP_CONFIG) public appConfig: any,
    private flutterwave: Flutterwave,
    private utilService: UtilService
  ) {
  }

  ngOnInit(): void {
    let name = 'Guest';
    if (this.currentuser) {
      if (this.currentuser.name) {
        name = this.currentuser.name;
      } else {
        name = (this.currentuser.first_name || '') + (this.currentuser.last_name || '')
      }
      this.customerDetails = {name, email: this.currentuser?.email || '', phone_number: this.currentuser?.phone || ''}
    } else {
      this.customerDetails = {name, email: 'guest@yopmail.com' || ''}
    }

    this.customizations = {
      title: 'KWOT',
      description: 'Payment for Kwot Tokens',
      logo: `${window.location.origin}/assets/images/k.png`
    }

    this.meta = {}

    if (this.currencyData.rates) {
      const values = Object.values(this.currencyData.rates);
      if (this.currencyData.location.currencyCode === 'NGN') {
        this.currencyRate = values.find(
          (item: any) => item.code === this.currencyData.location?.currencyCode
        );
      } else {
        this.currencyRate = values.find((item: any) => item.code === 'USD');
      }
    }

    const convertedAmount = this.utilService.getCurrencyCodeAndSymbolAndAmountWithRates( Number(Number(this.calculatedAmount).toFixed(2)), 'USD', this.currencyRate?.code || 'USD', 1, this.currencyRate?.value || 1);

    this.paymentData = {
      public_key: this.appConfig.flutterwave_public_key,
      tx_ref: this.generateReference(),
      amount: convertedAmount.amount || Number(Number(this.calculatedAmount).toFixed(2)),
      currency: 'NGN',
      payment_options: 'card',
      meta: {
        tokenDetails: JSON.stringify(this.meta)
      },
      customer: this.customerDetails,
      customizations: this.customizations,
    }
  }

  payViaPromise() {
    this.flutterwave.asyncInlinePay(this.paymentData).then(
      (response) => {
        if (response !== 'closed') {
          this.paymentConfirmed.next(response);
          this.flutterwave.closePaymentModal(0);
        } else {
          this.flutterwave.closePaymentModal(5)
        }
      }
    ).catch(e => {
      console.log(e);
    })
  }

  generateReference(): string {
    const date = new Date();
    return date.getTime().toString();
  }

}

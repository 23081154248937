import { Injectable} from "@angular/core";
import {currenciesWithSymbol} from "@frontend/data-models";

@Injectable({
  providedIn: "root"
})
export class UtilService {

  getCurrencyCodeAndSymbolAndAmountWithRates(amount: number, from: string, to: string, fromRate: number, toRate: number) {
    let currency: any = currenciesWithSymbol.find(t => t.name === to);
    if (from === to) {
      if (!currency) {
        currency = {symbol: '$', name: 'USD'};
      }
      return {currencySymbol: currency.symbol, currencyCode: currency.name, amount};
    }
    amount = Number(Number(amount).toFixed(2));
    // convert from currency to USD
    const fromAmountToUsd = amount / fromRate;
    // Convert USD to to currency
    let convertedAmount: any = fromAmountToUsd * toRate
    if (convertedAmount) {
      convertedAmount = convertedAmount.toFixed(2)
    }
    return {currencySymbol: currency.symbol, currencyCode: currency.name, amount: Number(convertedAmount)};
  }
}

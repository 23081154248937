import {AfterViewInit, Component, Inject, Input, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {DataTableDirective} from "angular-datatables";
import {Subject} from "rxjs";
import {TranslateService} from "@ngx-translate/core";
import {DOCUMENT} from "@angular/common";

@Component({
  selector: 'frontend-custom-table',
  templateUrl: './custom-table.component.html',
  styleUrls: ['./custom-table.component.scss']
})
export class CustomTableComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(DataTableDirective, {static: true})
  private dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  private datatableInstance: DataTables.Api;
  public ajaxCallback: any;
  private searchDelay: any;

  @Input() tableHead: TemplateRef<any>;
  @Input() tableBody: TemplateRef<any>;
  @Input() customOptions: DataTables.Settings | any = {};
  @Input() ajaxFunction: any = null;

  constructor(
    private translateService: TranslateService,
    @Inject(DOCUMENT) private document: Document
  ) {
  }

  ngOnInit(): void {
    this.datatableSettings();
    if (this.dtElement && this.dtElement.dtInstance) {
      this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.destroy();
        this.dtTrigger.next(null);
      })
    } else {
      this.dtTrigger.next(null);
    }
  }

  ngAfterViewInit() {
    this.dtTrigger.next(null);
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      this.datatableInstance = dtInstance;
      this.reAdjustColumnsInTable();
    })
  }

  reAdjustColumnsInTable() {
    if (this.document.readyState === "complete") {
      if (this.datatableInstance && this.datatableInstance.columns) {
        this.datatableInstance.columns.adjust();
      }
    } else {
      this.document.onreadystatechange = () => {
        if (this.document.readyState === "complete") {
          if (this.datatableInstance && this.datatableInstance.columns) {
            this.datatableInstance.columns.adjust();
          }
        }
      };
    }
  }

  datatableSettings() {
    this.dtOptions = {
      drawCallback(setting) {
        // @ts-ignore
        const totalPages = this.api()?.page?.info()?.pages || 0;
        const pagination = document.querySelectorAll('.dataTables_paginate');
        if (pagination && pagination.length > 0) {
          if (totalPages <= 1) {
            pagination.forEach(item => item && item.classList.add('d-none'));
          } else {
            pagination.forEach(item => item && item.classList.remove('d-none'));
          }
        }
      },
      pagingType: 'full_numbers',
      responsive: false,
      processing: false,
      serverSide: false,
      ordering: true,
      pageLength: 10,
      info: false,
      paging: true,
      scrollX: true,
      searching: true,
      lengthChange: false,
      order: [[0, 'desc']],
      scrollCollapse: true,
      language: {
        search: '',
        searchPlaceholder: this.customOptions.searchPlaceholder || this.translateService.instant('search'),
        emptyTable: this.translateService.instant('No data found')
      },
      ...this.customOptions,
      ...(this.customOptions.addAjax ? {
        ajax: (dataTablesParameters: any, callback: any) => {
          this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
            this.datatableInstance = dtInstance;
            this.ajaxCallback = callback;
            const pageLength = 10;
            const pageNumber = (dataTablesParameters.start / pageLength);
            dtInstance.page.len(pageLength);
            const searchBox: any = $('div.dataTables_filter input');
            searchBox.off('keyup.DT input.DT');
            searchBox.on('keyup', () => {
              const search: any = searchBox.val();
              clearTimeout(this.searchDelay);
              this.searchDelay = setTimeout(() => {
                if (search != null) {
                  dtInstance.search(search).draw();
                }
              }, 1000);
            });
            let orderBy = {};
            if (this.customOptions.orderColumns) {
              orderBy = {
                filterBy: this.customOptions.orderColumns[dataTablesParameters.order[0].column],
                filterType: dataTablesParameters.order[0].dir === 'asc' ? 'inc' : 'dec'
              };
            }
            if (this.ajaxFunction) {
              if (this.customOptions.withPage) {
                this.ajaxFunction(pageNumber, pageLength, dataTablesParameters.search.value, orderBy);
              } else {
                this.ajaxFunction(dataTablesParameters.search.value, orderBy);
              }
            }
          })
        }
      } : {})
    };
  }

  ngOnDestroy() {
    this.dtOptions = {};
    this.datatableInstance = null;
    this.dtTrigger.unsubscribe();
  }
}

import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { APP_CONFIG } from '@frontend/app-config';

@Injectable({
  providedIn: 'root',
})
export class PublicService {
  ApiUrl!: string;

  constructor(
    private http: HttpClient,
    @Inject(APP_CONFIG) private appConfig: any
  ) {
    this.ApiUrl = `${this.appConfig.apiUrl}`;
  }

  getToken() {
    return this.http.get(`${this.ApiUrl}/tokens/get-token-data`);
  }

  getAllCategories(params: any = {}) {
    if (params?.type === 'skit') {
      return this.http.get(`${this.ApiUrl}skits/categories`, { params });
    } else {
      return this.http.get(`${this.ApiUrl}podcasts/categories`, { params });
    }
  }

  getShowById(params: any = {}) {
      return this.http.get(`${this.ApiUrl}fan-club/${params.params.show_id}`);
  }

  GenerateRtmTokens(params: any) {
    return this.http.get(`${this.ApiUrl}public/rtmToken`, { params });
  }

  getArtistDetails(artist_id: any) {
    return this.http.get(`${this.ApiUrl}public/artist-profile-for-public/${artist_id}`);
  }
}

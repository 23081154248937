import {Component} from '@angular/core';
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";
import {PrivacyTermComponent} from "@frontend/shared";
import * as dayjs from "dayjs";

@Component({
  selector: 'frontend-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  year: any;

  constructor(private modelService: BsModalService) {
    this.year = dayjs().year();
  }

  showPrivacyTerms(activeTab: string) {
    const modelRef: BsModalRef = this.modelService.show(PrivacyTermComponent, {
      keyboard: true,
      animated: true,
      ignoreBackdropClick: false,
      class: 'modal-xl modal-dialog-centered',
      initialState: {
        showHeader: false
      }
    });
    modelRef.content.activeTab = activeTab;
  }
}

import {Component, Inject, OnInit} from '@angular/core';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {PrivacyTermComponent} from "@frontend/shared";
import {APP_CONFIG} from "@frontend/app-config";
import * as dayjs from 'dayjs';

@Component({
  selector: 'frontend-artist-footer',
  templateUrl: './artist-footer.component.html',
  styleUrls: ['./artist-footer.component.scss'],
})
export class ArtistFooterComponent implements OnInit {
  year: any;

  constructor(private modelService: BsModalService, @Inject(APP_CONFIG) public appConfig: any) {
  }

  showPrivacyTerms(activeTab: string) {
    const modelRef: BsModalRef = this.modelService.show(PrivacyTermComponent, {
      keyboard: true,
      animated: true,
      ignoreBackdropClick: false,
      class: 'modal-xl modal-dialog-centered',
      initialState: {
        showHeader: false
      }
    });
    modelRef.content.activeTab = activeTab;
  }

  ngOnInit(): void {
    this.year = dayjs().year();
  }
}

<div class="body_bg">
  <frontend-artist-header *ngIf="showHeader"></frontend-artist-header>
  <div class="modal-body privacy_popup">
    <div class="close-icon mb-3">
      <span id="close" *ngIf="showCrossIcon">
        <img
          class="cursor-pointer"
          src="assets/images/Cross1.png"
          alt=""
          (click)="bsModalRef.hide()"
        />
      </span>
    </div>
    <div class="tabs">
      <ul class="nav nav-pills">
        <li
          routerLink="/terms-conditions"
          class="cursor-pointer"
          [ngClass]="{ active: activeTab === 'terms' }"
          (click)="activeTab = 'terms'; changeTab()"
        >
          <a data-toggle="tab">Terms of use</a>
        </li>
        <li
          routerLink="/privacy-policy"
          class="cursor-pointer"
          [ngClass]="{ active: activeTab === 'privacy' }"
          (click)="activeTab = 'privacy'; changeTab()"
        >
          <a data-toggle="tab">Privacy policy</a>
        </li>
      </ul>
    </div>
    <div class="tab-content text-md-start text-center">
      <div
        id="terms"
        class="tab-pane fade"
        [ngClass]="{ 'active in show': activeTab === 'terms' }"
      >
        <p [innerHTML]="selectedContent?.content">
          {{ selectedContent?.content }}
        </p>
      </div>
      <div
        id="privacy"
        class="tab-pane fade"
        [ngClass]="{ 'active in show': activeTab === 'privacy' }"
      >
        <p [innerHTML]="selectedContent?.content">
          {{ selectedContent?.content }}
        </p>
      </div>
    </div>
  </div>
</div>

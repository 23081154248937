import {InjectionToken} from "@angular/core";

export * from './services/http-intercept.service'
export * from './services/loader.service'
export * from './services/localstorage.service'
export * from './services/upload-image.service'
export * from './component/toast.component';
export * from './services/window.service';
export * from './services/socket.service';

export const APP_CONFIG = new InjectionToken("Application config");

import {Component, ElementRef, ViewChild, HostListener} from '@angular/core';
import { SwiperOptions } from 'swiper';
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'frontend-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent {
  @ViewChild('sectionContainer') sectionContainer!: ElementRef;

  config: SwiperOptions = {
    // pagination: {
    //   el: '.swiper-pagination',
    //   clickable: true,
    // },
    // navigation: {
    //   nextEl: '.swiper-button-next',
    //   prevEl: '.swiper-button-prev'
    // },
    autoplay: true,
    spaceBetween: 20,
    breakpoints: {
      992: {
        slidesPerView: 3,
      },
    },
  };

  faqs = [
    'What are the charges for Kwot?',
    'Which special features are available for Kwot listeners?',
    'Can I keep my playlist private and not show it to others? ',
    'What are the special features you offer to African creators?',
  ];

  isShowAll = false;

  showAllFaqs = () => {
    this.isShowAll = !this.isShowAll;
  };

  constructor(
    private route: ActivatedRoute
  ) {
    this.scrollToSection('social');
  }

  ngOnInit() {
    this.route.fragment.subscribe(fragment => {
     if(fragment) {
       setTimeout(() => {
         this.scrollToSection(fragment);
       }, 600)
     }
    });
  }

  private scrollToSection(fragment: string) {
    if(fragment && document.getElementById(fragment)) {
      document.getElementById(fragment).scrollIntoView();
    }
  }

  scrollToBottom() {
    window.scrollTo(0, document.body.scrollHeight);
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    const number =
      window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop ||
      0;
    if (number > 100) {
      document.querySelectorAll('.appendClass').forEach((c) => {
        c.classList.add('text-smaller');
      });
    } else {
      document.querySelectorAll('.appendClass').forEach((c) => {
        c.classList.remove('text-smaller');
      });
    }
  }
}

<ng-container *ngIf="formGroup else noFromGroup" placement="auto">
  <div [formGroup]="formGroup">
    <div class="creatable-select {{customClass}}">
      <ng-select #select [placeholder]="placeholder" placement="auto" [multiple]="isMultiple" [items]="selectOptions"
                 formControlName="{{selectedValue}}" (change)="onSelected($event)"
                 [readonly]="isDisabled"
                 [searchable]="searchable"
                 [appendTo]="appendTo" [dropdownPosition]="'auto'"
                 [clearable]="false" [bindLabel]="labelKey"
                 [bindValue]="valueKey">
        <ng-template ng-header-tmp [ngIf]="headerTemplate">
          <ng-container [ngTemplateOutlet]="headerTemplate"></ng-container>
        </ng-template>
      </ng-select>
    </div>
  </div>
</ng-container>
<ng-template #noFromGroup>
  <div class="creatable-select {{customClass}}">
    <ng-select #select [placeholder]="placeholder" [multiple]="isMultiple" [items]="selectOptions"
               [(ngModel)]="selectedValue" (change)="onSelected($event)"
               [readonly]="isDisabled"
               [searchable]="searchable"
               [appendTo]="appendTo"
               [dropdownPosition]="'auto'"
               [clearable]="false" [bindLabel]="labelKey"
               [bindValue]="valueKey">
      <ng-template ng-header-tmp [ngIf]="headerTemplate">
        <ng-container [ngTemplateOutlet]="headerTemplate"></ng-container>
      </ng-template>
    </ng-select>
  </div>
</ng-template>


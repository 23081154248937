import {SocialUser, User } from '@frontend/data-models';
import {createAction, props} from '@ngrx/store';

const AuthActions = {
  REGISTER: '[AUTH] Register',
  REGISTER_SUCCESS: '[AUTH] Register Success',
  REGISTER_ERROR: '[AUTH] Register Error',

  VALIDATE_OTP: '[AUTH] Validate Otp',
  VALIDATE_OTP_SUCCESS: '[AUTH] Validate Otp Success',
  VALIDATE_OTP_ERROR: '[AUTH] Validate Otp Error',

  LOGIN: '[AUTH] Login',
  LOGIN_SUCCESS: '[AUTH] Login Success',
  LOGIN_ERROR: '[AUTH] Login Error',

  SEND_OTP: '[AUTH] Send Otp',
  SEND_OTP_SUCCESS: '[AUTH] Send Otp Success',
  SEND_OTP_ERROR: '[AUTH] Send Otp Error',

  FORGOT_PASSWORD: '[AUTH] Forgot Password',
  FORGOT_PASSWORD_SUCCESS: '[AUTH] Forgot Password Success',
  FORGOT_PASSWORD_ERROR: '[AUTH] Forgot Password Error',

  RESET_PASSWORD: '[AUTH] Reset Password',
  RESET_PASSWORD_SUCCESS: '[AUTH] Reset Password Success',
  RESET_PASSWORD_ERROR: '[AUTH] Reset Password Error',

  RESET_AUTH_STATE: '[AUTH] Reset Auth State',

  CHECK_ACCOUNT_VERIFY_LINK: '[AUTH] Check Account verify link',
  CHECK_ACCOUNT_VERIFY_LINK_SUCCESS: '[AUTH] Check Account verify link Success',
  CHECK_ACCOUNT_VERIFY_LINK_ERROR: '[AUTH] Check Account verify link Error',

  LOGOUT_USER: '[AUTH] Logout User',

  VERIFY_DATA: '[AUTH] Verify Data',
  VERIFY_DATA_SUCCESS: '[AUTH] Verify Data success',
  VERIFY_DATA_ERROR: '[AUTH] Verify Data error',

  PURCHASE_TOKEN: '[AUTH] Purchase Token',
  PURCHASE_TOKEN_SUCCESS: '[AUTH] Purchase Token Success',
  PURCHASE_TOKEN_ERROR: '[AUTH] Purchase Token Error',

  LOGIN_WITH_TOKEN: '[AUTH] Login With Token',
  LOGIN_WITH_TOKEN_SUCCESS: '[AUTH] Login With Token Success',
  LOGIN_WITH_TOKEN_ERROR: '[AUTH] Login With Token Error',

  SIGNIN_WITH_SOCIAL: '[AUTH] Sign in With Social Account',
  SIGNIN_WITH_SOCIAL_SUCCESS: '[AUTH] Sign in With Social Account Success',
};

export const Register = createAction(
  AuthActions.REGISTER,
  props<{ user: any }>()
);
export const RegisterSuccess = createAction(
  AuthActions.REGISTER_SUCCESS,
  props<{ registerUser: any; message: string }>()
);
export const RegisterError = createAction(
  AuthActions.REGISTER_ERROR,
  props<{ error: string }>()
);

export const ValidateOtp = createAction(
  AuthActions.VALIDATE_OTP,
  props<{ otp: any }>()
);
export const ValidateOtpSuccess = createAction(
  AuthActions.VALIDATE_OTP_SUCCESS,
  props<{ success: any }>()
);
export const ValidateOtpError = createAction(
  AuthActions.VALIDATE_OTP_ERROR,
  props<{ error: string }>()
);

export const Login = createAction(AuthActions.LOGIN, props<{ user: any }>());
export const LoginSuccess = createAction(
  AuthActions.LOGIN_SUCCESS,
  props<{ loginUser: any; message: string }>()
);
export const LoginError = createAction(
  AuthActions.LOGIN_ERROR,
  props<{ error: string }>()
);

export const SendOtp = createAction(
  AuthActions.SEND_OTP,
  props<{ phone: string; countryCode: string }>()
);
export const SendOtpSuccess = createAction(
  AuthActions.SEND_OTP_SUCCESS,
  props<{ success: string }>()
);
export const SendOtpError = createAction(
  AuthActions.SEND_OTP_ERROR,
  props<{ error: string }>()
);

export const ForgotPassword = createAction(
  AuthActions.FORGOT_PASSWORD,
  props<{ user: any }>()
);
export const ForgotPasswordSuccess = createAction(
  AuthActions.FORGOT_PASSWORD_SUCCESS,
  props<{ success: string }>()
);
export const ForgotPasswordError = createAction(
  AuthActions.FORGOT_PASSWORD_ERROR,
  props<{ error: string }>()
);

export const ResetAuthState = createAction(
  AuthActions.RESET_AUTH_STATE,
  (params: any = {}) => params
);

export const ResetPassword = createAction(
  AuthActions.RESET_PASSWORD,
  props<{ passwordBody: any }>()
);
export const ResetPasswordSuccess = createAction(
  AuthActions.RESET_PASSWORD_SUCCESS,
  props<{ success: any }>()
);
export const ResetPasswordError = createAction(
  AuthActions.RESET_PASSWORD_ERROR,
  props<{ error: string }>()
);

export const CheckAccountVerifyLink = createAction(
  AuthActions.CHECK_ACCOUNT_VERIFY_LINK,
  props<{ id: string }>()
);
export const CheckAccountVerifyLinkSuccess = createAction(
  AuthActions.CHECK_ACCOUNT_VERIFY_LINK_SUCCESS,
  props<{ success: any }>()
);
export const CheckAccountVerifyLinkError = createAction(
  AuthActions.CHECK_ACCOUNT_VERIFY_LINK_ERROR,
  props<{ error: string }>()
);

export const VerifyData = createAction(
  AuthActions.VERIFY_DATA,
  props<{ params: any }>()
);
export const VerifyDataSuccess = createAction(
  AuthActions.VERIFY_DATA_SUCCESS,
  props<{ success: string }>()
);
export const VerifyDataError = createAction(
  AuthActions.VERIFY_DATA_ERROR,
  props<{ error: string }>()
);

export const PurchaseToken = createAction(AuthActions.PURCHASE_TOKEN, props<{ body: any }>());
export const PurchaseTokenSuccess = createAction(AuthActions.PURCHASE_TOKEN_SUCCESS);
export const PurchaseTokenError = createAction(AuthActions.PURCHASE_TOKEN_ERROR, props<{ error: string }>());

export const LoginWithToken = createAction(AuthActions.LOGIN_WITH_TOKEN, props<{ token: string }>());
export const LoginWithTokenSuccess = createAction(AuthActions.LOGIN_WITH_TOKEN_SUCCESS, props<{ loginUser: User, message: string }>());
export const LoginWithTokenError = createAction(AuthActions.LOGIN_WITH_TOKEN_ERROR, props<{ error: string }>());

export const SignInWithSocial = createAction(AuthActions.SIGNIN_WITH_SOCIAL, props<{ providerString: string, actionType: string }>());
export const SignInWithSocialSuccess = createAction(AuthActions.SIGNIN_WITH_SOCIAL_SUCCESS, props<{ signInUserSocial: SocialUser }>());

export const LogoutUser = createAction(AuthActions.LOGOUT_USER);

import { Component, OnInit } from '@angular/core';
import { SwiperOptions } from 'swiper';
@Component({
  selector: 'frontend-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss'],
})
export class AboutUsComponent implements OnInit {
  config: SwiperOptions = {
    // pagination: {
    //   el: '.swiper-pagination',
    //   clickable: true
    // },
    // navigation: {
    //   nextEl: '.swiper-button-next',
    //   prevEl: '.swiper-button-prev'
    // },
    autoplay: true,
    spaceBetween: 20,
    breakpoints: {
      992: {
        slidesPerView: 3,
      },
    },
  };
  constructor() {}
  OrganiceMusicConfig: SwiperOptions = {
    autoplay: true,
    spaceBetween: 20,
    breakpoints: {
      1366: {
        slidesPerView: 7,
      },
      1199: {
        slidesPerView: 5,
      },
      991: {
        slidesPerView: 4,
      },
      768: {
        slidesPerView: 3,
      },
      767: {
        slidesPerView: 2,
      },
      375: {
        slidesPerView: 2,
      },
    },
  };
  ngOnInit(): void {
    window.scroll(0, 0);
  }
}

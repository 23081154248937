<div class="body_bg">
  <frontend-artist-header></frontend-artist-header>
  <section class="contact_us_section">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="contact">
            <h1>Contact us</h1>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-md-6">
          <form (ngSubmit)="onSubmitContact()" [formGroup]="contactForm">
            <div class="contact_fields">
              <h1>How may we help you?</h1>
              <div class="fileds_details">
                <div class="filed_label_name">
                  <label>Name *</label>
                  <ng-container *ngIf="(form['name'].touched || form['name'].dirty) && form['name'].errors">
                    <label
                      *ngIf="form['name'].errors['required']" class="text-danger mb-0 me-2 fs-10 fw-bold">
                      Name is required
                    </label>
                  </ng-container>
                </div>
                <input
                  type="text"
                  placeholder="Type your name"
                  formControlName="name"
                  inputRef
                />
              </div>
              <div class="fileds_details">
                <div class="filed_label_name">
                  <label>Email *</label>
                  <ng-container *ngIf="(form['email'].touched || form['email'].dirty) && form['email'].errors">
                    <label *ngIf="form['email'].errors['required']" class="text-danger mb-0 me-2 fs-10 fw-bold">
                      Email is required
                    </label>
                    <label *ngIf="form['email'].errors['pattern']" class="text-danger mb-0 me-2 fs-10 fw-bold">
                      Email address is invalid
                    </label>
                  </ng-container>
                </div>
                <input
                  inputRef
                  type="email"
                  formControlName="email"
                  placeholder="Type your email address"
                />
              </div>

              <div class="fileds_details">
                <div class="filed_label_name">
                  <label>Mobile number *</label>
                  <ng-container *ngIf="form['phone_number'].dirty && form['phone_number'].touched && form['phone_number'].errors">
                    <label *ngIf="form['phone_number'].errors['validatePhoneNumber']" class="text-danger mb-0 ms-2 fs-10 fw-bold">
                      Phone number is invalid
                    </label>
                  </ng-container>
                </div>
                <ngx-intl-tel-input
                  class="phone-input-control form-control w-100 p-0"
                  cssClass="phone-input form-control border-0"
                  formControlName="phone_number"
                  [enablePlaceholder]="true"
                  customPlaceholder="Type your phone number"
                  [phoneValidation]="true"
                  [numberFormat]="PhoneNumberFormat.International"
                  [enableAutoCountrySelect]="true"
                  [searchCountryFlag]="true"
                  [separateDialCode]="true"
                  [selectFirstCountry]="false"
                  [selectedCountryISO]="CountryISO.Nigeria"
                  name="phone_number"
                >
                </ngx-intl-tel-input>
              </div>

              <div class="fileds_details">
                <div class="filed_label_name">
                  <label>Contact reason *</label>
                  <ng-container *ngIf="(form['reason_id'].touched || form['reason_id'].dirty) && form['reason_id'].errors">
                    <label *ngIf="form['reason_id'].errors['required']" class="text-danger mb-0 me-2 fs-10 fw-bold">
                      Contact Reason is required
                    </label>
                  </ng-container>
                </div>

                <div class="choose_role">
                  <div class="role_select">
                    <frontend-custom-select
                      class="custom width"
                      [formGroup]="contactForm"
                      [selectOptions]="contactUs"
                      placeholder="Contact reason"
                      (optionSelected)="reasonData($event)"
                    >
                    </frontend-custom-select>
                  </div>
                </div>
              </div>

              <div class="fileds_details">
                <div class="filed_label_name">
                  <label>Message *</label>
                  <ng-container *ngIf="(form['message'].touched || form['message'].dirty) && form['message'].errors">
                    <label *ngIf="form['message'].errors['required']" class="text-danger mb-0 me-2 fs-10 fw-bold">
                      Message is required
                    </label>
                  </ng-container>
                </div>

                <textarea
                  inputRef
                  class="form-control"
                  placeholder="Write your message here"
                  rows="5"
                  formControlName="message"
                ></textarea>
              </div>

              <div class="fileds_details">
                <button type="submit">Send</button>
              </div>
            </div>
          </form>
        </div>

        <div class="col-md-6 position-relative">
          <div class="contact_img">
            <div class="img_wrappers">
              <img src="assets/images/msg.png"/>
            </div>
          </div>

          <div class="contact-sec sec-pad">
            <div class="contact-detail">
              <i class="fa fa-plus me-md-2"></i>
              <ul class="contact-ul">
                <li>
                  <span class="img-wrapper location-icon me-2">
                    <img src="assets/images/place_icon.png" alt="">
                  </span>
                  32c Cameron road, Ikoyi, Lagos. Nigeria
                </li>
                <li>
                  <span class="img-wrapper call-icon me-2">
                    <img src="assets/images/phone-call.png" alt="">
                  </span>
                  <img class="me-2" src="assets/images/whatsapp.svg" alt="">

                  <a href="tel:+2348187674561"><b>+234 818 767 4561</b></a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <frontend-artist-footer></frontend-artist-footer>
    </div>
  </section>
</div>

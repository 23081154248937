import {Component, ElementRef, HostListener, Inject} from '@angular/core';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {SwiperOptions} from 'swiper';
import {PrivacyTermComponent} from '@frontend/shared';
import {APP_CONFIG} from "@frontend/app-config";

@Component({
  selector: 'frontend-artist-landing-page',
  templateUrl: './artist-landing-page.component.html',
  styleUrls: ['./artist-landing-page.component.scss'],
})
export class ArtistLandingPageComponent {
  configPriceConfig: SwiperOptions = {
    autoplay: true,
    spaceBetween: 20,
    slidesPerView: 1.5,
    breakpoints: {
      992: {
        slidesPerView: 1,
      },
    },
  };
  shareMusicConfig: SwiperOptions = {
    autoplay: true,
    spaceBetween: 20,
    breakpoints: {
      992: {
        slidesPerView: 1,
      },
    },
  };
  OrganiceMusicConfig: SwiperOptions = {
    autoplay: true,
    spaceBetween: 20,
    breakpoints: {
      1366: {
        slidesPerView: 7,
      },
      1199: {
        slidesPerView: 5,
      },
      991: {
        slidesPerView: 3,
      },
      768: {
        slidesPerView: 3,
      },
      767: {
        slidesPerView: 2,
      },
      375: {
        slidesPerView: 1,
      },
    },
  };

  TutorialConfig: SwiperOptions = {
    autoplay: true,
    spaceBetween: 20,
    breakpoints: {
      992: {
        slidesPerView: 3,
      },
    },
  };

  faqs = [
    'How to upload your music to Kwot.',
    'Sharing your music on other platforms.',
    'Payment model for artists.',
    'Managing your artist profile',
  ];

  isShowAll = false;

  showAllFaqs = () => {
    this.isShowAll = !this.isShowAll;
  };

  constructor(
    private modelService: BsModalService,
    @Inject(APP_CONFIG) public appConfig: any,
    private elementRef: ElementRef,
    ) {
  }

  ngOnInit() {
    const sectionId = window.location.hash;
    if (sectionId) {
      setTimeout(() => {
        const section = this.elementRef.nativeElement.querySelector(sectionId);
        if (section) {
          section.scrollIntoView({ behavior: 'smooth' });
        }
      },600)
    }
  }

  showPrivacyTerms(activeTab: string) {
    const modelRef: BsModalRef = this.modelService.show(PrivacyTermComponent, {
      keyboard: true,
      animated: true,
      ignoreBackdropClick: false,
      class: 'modal-xl modal-dialog-centered',
      initialState: {
        showHeader: false
      }
    });
    modelRef.content.activeTab = activeTab;
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    const number =
      window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop ||
      0;
    if (number > 100) {
      document.querySelectorAll('.appendClass').forEach((c) => {
        c.classList.add('text-smaller');
      });
    } else {
      document.querySelectorAll('.appendClass').forEach((c) => {
        c.classList.remove('text-smaller');
      });
    }
  }
}

import {Component, OnInit} from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {AuthService} from "@frontend/auth-store";

@Component({
  selector: 'frontend-privacy-term',
  templateUrl: './privacy-term.component.html',
  styleUrls: ['./privacy-term.component.scss'],
})
export class PrivacyTermComponent implements OnInit {
  privacyData: any;
  activeTab: 'terms' | 'privacy' = 'terms';
  showCrossIcon = true;
  showHeader = true
  selectedContent: any;

  constructor(
    public bsModalRef: BsModalRef,
    private authService: AuthService
  ) {
    const url = window.location.href;
    if (url.includes('privacy-policy')) {
      this.activeTab = 'privacy';
    } else if (url.includes('terms-condition-seller')) {
      this.activeTab = 'terms';
    } else if (url.includes('terms-condition')) {
      this.activeTab = 'terms';
    }
  }

  ngOnInit(): void {
    this.getPrivacyData();
  }

  getPrivacyData() {
    this.authService.getPrivacy().subscribe((res: any) => {
      this.privacyData = res.data;
      this.changeTab();
    });
  }

  changeTab() {
    const policyName = this.activeTab === 'privacy' ? 'Privacy policy' : 'User aggrement'
    this.selectedContent = this.privacyData.find((t: any) => t.policy_name.toLowerCase() === policyName.toLowerCase());
  }
}

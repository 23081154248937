<div class="row">
  <div class="col-lg-8">
    <div>
      <h2 class="left-align text-white">Get started with interactive live streaming</h2>

      <button class="End_live_show" type="button" id="leave" (click)="leveFromShow()">
        Leave from live show
      </button>
    </div>

    <div id="live-stream">

    </div>
  </div>
  <div class="col-lg-4">
    <div class="joined-area">
      <div class="chat_container" #chatContainer>
        <div
          *ngFor="let message of messagesList; let i = index"
          class="chat_body"
        >
          <div class="text-white artist-comment">
            <div class="img-wrapper me-2 mb-1">
              <img [src]="message.sender.thumbnail || message.sender.photo || 'assets/images/Avatar.png'" />
            </div>
            <span>
                  {{
              ' ' + message.sender.first_name + ' ' + message.sender.last_name
              }}
                  </span>
          </div>
          <span class="text-white">{{ message.comment }}</span>
        </div>
      </div>
      <div class="Text-feild-area-chat">
        <form class="live-video-feild">
                <span class="btn-wrapper">
                  <input
                    type="text"
                    placeholder="Write here"
                    [(ngModel)]="message"
                    name="message"
                  />
                  <img src="/assets/images/text-send-icon.png" class="img-fluid cursor-pointer" (click)="sendMessage($event)">
                </span>
        </form>
      </div>
    </div>
  </div>
</div>

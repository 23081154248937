import { NgModule } from '@angular/core';
import {CommonModule, CurrencyPipe} from '@angular/common';
import { PrivacyTermComponent } from './components/privacy-term/privacy-term.component';
import { ArtistHeaderComponent } from './components/artist-header/artist-header.component';
import { CustomSelectComponent } from './components/custom-select/custom-select.component';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DataTablesModule } from 'angular-datatables';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import {
  TranslateLoader,
  TranslateModule,
  TranslateStore,
} from '@ngx-translate/core';
import { SanitizeHtmlPipe } from './pipes/sanitize.pipe';
import { SanitizeUrlPipe } from './pipes/sanitizeUrl.pipe';
import { RouterModule } from '@angular/router';
import { CustomTableComponent } from './components/custom-table/custom-table.component';
import { CustomModalComponent } from './components/custom-model/custom-modal.component';
import {LazyLoadImageModule} from "ng-lazyload-image";
import {CurrencyConvertPipe} from "./pipes/currencyConvert.pipe";
import {CurrencyComponent} from "./components/currency/currency.component";

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    PrivacyTermComponent,
    ArtistHeaderComponent,
    CustomSelectComponent,
    SanitizeHtmlPipe,
    SanitizeUrlPipe,
    CustomTableComponent,
    CustomModalComponent,
    CurrencyConvertPipe,
    CurrencyComponent
  ],
  imports: [
    CommonModule,
    NgxIntlTelInputModule,
    NgSelectModule,
    FormsModule,
    DataTablesModule,
    ReactiveFormsModule,
    RouterModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
      defaultLanguage: 'en',
    }),
    LazyLoadImageModule
  ],
  exports: [
    PrivacyTermComponent,
    ArtistHeaderComponent,
    CustomSelectComponent,
    TranslateModule,
    SanitizeHtmlPipe,
    SanitizeUrlPipe,
    CustomTableComponent,
    CustomModalComponent,
    CurrencyConvertPipe,
    CurrencyComponent
  ],
  providers: [TranslateStore, CurrencyPipe, CurrencyConvertPipe],
})
export class SharedModule {}

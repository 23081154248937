import {
  Component,
  ElementRef,
  OnInit,
  EventEmitter,
  ViewChild, Input,
} from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'frontend-video-custom-modal',
  templateUrl: './video-modal.component.html',
  styleUrls: ['./video-modal.component.scss'],
})
export class VideoModalComponent implements OnInit {
  @Input() tokenDetail: any;
  public closeEvent: EventEmitter<any> = new EventEmitter();
  totalToken: number = 0;

  @ViewChild('videoPlayer') videoPlayer: ElementRef<HTMLVideoElement>;
  videourl: string;
  isPlaying = false;

  constructor(public modalRef: BsModalRef<VideoModalComponent>) {}

  ngOnInit(): void {
    this.totalToken = Number(this.tokenDetail.billing_details?.user_id?.tokens) + Number(this.tokenDetail.tokens)
  }


  confirm() {
    this.closeEvent.emit({close: true});
    this.modalRef?.hide()
  }
}

import {DOCUMENT} from '@angular/common';
import {Component, HostListener, Inject} from '@angular/core';
import {SwiperOptions} from 'swiper';
import {APP_CONFIG} from "@frontend/app-config";

@Component({
  selector: 'frontend-artist-support',
  templateUrl: './artist-support.component.html',
  styleUrls: ['./artist-support.component.scss'],
})
export class ArtistSupportComponent {
  config: SwiperOptions = {
    autoplay: true,
    spaceBetween: 20,
    breakpoints: {
      992: {
        slidesPerView: 3,
      },
    },
  };

  searchFaq: string;
  selectedFAQ: any;
  faq: any[] = [
    {
      question: 'What are the benefits of creating an artist profile on Kwot?',
      answer: 'Musicians, comedians and other artists can setup and manage their profile on Kwot. You can add your content, engage with your followers, and also earn money.',
      type: 'started'
    },
    {
      question: 'How can I earn money on Kwot?',
      answer: 'Artists can do live shows on Kwot and charge for these shows. Artists can also earn money from content streaming.',
      type: 'started'
    },
    {
      question: 'How do I create my artist profile on Kwot?',
      answer: 'You need to register once on the Kwot platform. If you have a created a profile for the Kwot Marketplace then the same can be used to login to Kwot too. Remember to upload your content library on Kwot so that your audience can find it.',
      type: 'started'
    },
    {
      question: 'How do you pay distributors for the content?',
      answer: 'Payment to distributors is made as per the terms of the contract with them.',
      type: 'payment'
    },
    {
      question: 'How can independent artists make money on Kwot?',
      answer: 'Independent creators can do paid live shows on the Kwot platform. Also, they will receive stream share revenue depending on how many times their content is streamed.',
      type: 'payment'
    },
    {
      question: 'Will I have access to data about my audience?',
      answer: 'Yes; creators will have access to detailed dashboards with data and trends about their content, including count of streams, listeners, followers, visits, and downloads',
      type: 'payment'
    },
    {
      question: 'Will I have access to usage data about my content?',
      answer: 'Yes; creators will be able to see data about their top songs and top playlists through the dashboard in their Kwot account.',
      type: 'social'
    },
    {
      question: 'Will I able to target my content to specific audience on Kwot platform?',
      answer: 'Yes; creators will have options to specify details about the content that they are uploading on Kwot. You can specify whether the content includes explicit content and if it\'s for audience above 16/ above 18/ or all audiences.',
      type: 'social'
    },
    {
      question: 'What are the special features you offer to African creator?',
      answer: 'Our platform enables Artists to reach a global audience and also make money. Artists can do live shows and upload their content for audience to stream.',
      type: 'social'
    }
  ];

  dataSelected(started: any, payment: any, social: any) {
    if (!this.selectedFAQ) {
      return;
    }
    if (this.selectedFAQ.type === 'payment') {
      payment.scrollIntoView({behavior: "smooth"});
    }
    if (this.selectedFAQ.type === 'started') {
      started.scrollIntoView({behavior: "smooth"});
    }
    if (this.selectedFAQ.type === 'social') {
      social.scrollIntoView({behavior: "smooth"});
    }
  }

  getFAQ(type: string) {
    return this.faq.filter(t => t.type === type);
  }

  constructor(@Inject(DOCUMENT) private document: Document, @Inject(APP_CONFIG) public appConfig: any) {
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    const number =
      window.pageYOffset ||
      this.document.documentElement.scrollTop ||
      this.document.body.scrollTop ||
      0;
    if (number > 100) {
      this.document.querySelectorAll('.appendClass').forEach((c) => {
        c.classList.add('text-smaller');
      });
    } else {
      this.document.querySelectorAll('.appendClass').forEach((c) => {
        c.classList.remove('text-smaller');
      });
    }
  }
}

<div class="body_bg">
  <frontend-header></frontend-header>
  <section class="support_header_section">
    <div class="container support_banner">
      <div class="row">
        <div class="col-12">
          <div class="support_help">
            <h1>How can we help you?</h1>
            <p>Get in touch for all your enquiries and support.</p>
            <div class="ask_question">
              <input [(ngModel)]="searchFaq" [typeahead]="faq" typeaheadOptionField="question"
                     (typeaheadOnSelect)="selectedFAQ = $event.item"
                     type="text" placeholder="Ask a question..."/>
              <button (click)="dataSelected(started, payment, social)"><img
                src="assets/images/search.svg"/></button>
            </div>
          </div>
        </div>
      </div>

      <div class="row support_box_for_web">
        <div class="col-lg-3">
          <div class="support_box">
            <div class="support_ic">
              <img src="assets/images/support_ic.svg"/>
            </div>
            <h1 class="cursor-pointer" [routerLink]="['']">
              Getting <br/>
              started
            </h1>
            <span class="down_arrow">
              <img src="assets/images/down_arrow.svg"/>
            </span>
          </div>
        </div>
        <div class="col-lg-3">
          <div class="support_box">
            <div class="support_ic">
              <img src="assets/images/support_ic.svg"/>
            </div>
            <h1 class="cursor-pointer" [routerLink]="['/artist']" fragment="payments">Manage<br/>payments</h1>
            <span class="down_arrow">
              <img src="assets/images/down_arrow.svg"/>
            </span>
          </div>
        </div>
        <div class="col-lg-3">
          <div class="support_box">
            <div class="support_ic">
              <img src="assets/images/support_ic.svg"/>
            </div>
            <h1 class="cursor-pointer" [routerLink]="['/']" fragment="social">Social<br/>features</h1>
            <span class="down_arrow">
              <img src="assets/images/down_arrow.svg"/>
            </span>
          </div>
        </div>
        <div class="col-lg-3">
          <div class="support_box">
            <div class="support_ic">
              <img src="assets/images/support_ic.svg"/>
            </div>
            <h1 class="cursor-pointer" [routerLink]="['/plans']">Available<br/>plans</h1>
            <span class="down_arrow">
              <img src="assets/images/down_arrow.svg"/>
            </span>
          </div>
        </div>
      </div>
      <div class="row support_box_for_mobile">
        <div class="col-12">
          <swiper [config]="config">
            <div class="swiper-wrapper">
              <div class="swiper-slide">
                <div class="support_box">
                  <div class="support_ic">
                    <img src="assets/images/support_ic.svg"/>
                  </div>
                  <h1 class="cursor-pointer" [routerLink]="['']">
                    Getting <br/>
                    started
                  </h1>
                  <span class="down_arrow">
                    <img src="assets/images/down_arrow.svg"/>
                  </span>
                </div>
              </div>
              <div class="swiper-slide">
                <div class="support_box">
                  <div class="support_ic">
                    <img src="assets/images/support_ic.svg"/>
                  </div>
                  <h1 class="cursor-pointer" [routerLink]="['/artist']" fragment="payments">Manage<br/>payments</h1>
                  <span class="down_arrow">
                    <img src="assets/images/down_arrow.svg"/>
                  </span>
                </div>
              </div>

              <div class="swiper-slide">
                <div class="support_box">
                  <div class="support_ic">
                    <img src="assets/images/support_ic.svg"/>
                  </div>
                  <h1 class="cursor-pointer" [routerLink]="['/']" fragment="social">Social<br/>features</h1>
                  <span class="down_arrow">
                    <img src="assets/images/down_arrow.svg"/>
                  </span>
                </div>
              </div>

              <div class="swiper-slide">
                <div class="support_box">
                  <div class="support_ic">
                    <img src="assets/images/support_ic.svg"/>
                  </div>
                  <h1 class="cursor-pointer" [routerLink]="['/plans']">Available<br/>plans</h1>
                  <span class="down_arrow">
                    <img src="assets/images/down_arrow.svg"/>
                  </span>
                </div>
              </div>
            </div>
          </swiper>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-4">
          <div class="find_us">
            <div class="find_us_ic">
              <img src="assets/images/faq.svg"/>
            </div>
            <h1>Find us</h1>
            <p>
              You can connect with us and the community of Kwot users on
              social media platfroms
              <a href="#">hello@kwot.com</a>
            </p>

            <ul class="social_links">
              <li>
                <a href="https://www.facebook.com/kwotmusic" target="_blank"
                ><img src="assets/images/facebook.svg"
                /></a>
              </li>
              <li>
                <a
                  href="https://www.linkedin.com/company/kwotmusic"
                  target="_blank"
                ><img src="assets/images/linkedin.svg"
                /></a>
              </li>
              <li>
                <a href="https://instagram.com/kwotmusic" target="_blank"
                ><img src="assets/images/instagram.svg"
                /></a>
              </li>
              <li>
                <a href="https://twitter.com/kwotmusic" target="_blank"
                ><img src="assets/images/twitter.svg"
                /></a>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-lg-8">
          <div class="questions_section">
            <h1 #started>Getting started</h1>
            <div class="mainfaq_section">
              <div class="accordion" id="accordionExample">
                <div class="accordion-item" *ngFor="let faq of getFAQ('started'); let i = index">
                  <h2 class="accordion-header" id="heading{{i}}">
                    <button class="accordion-button" [ngClass]="{collapsed: i !== 0}" type="button"
                            data-bs-toggle="collapse"
                            [attr.data-bs-target]="'#collapse' + i" aria-expanded="true" aria-controls="collapse">
                      {{faq.question}}
                    </button>
                  </h2>
                  <div id="collapse{{i}}" class="accordion-collapse collapse" [ngClass]="{show: i === 0}"
                       aria-labelledby="heading"
                       data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                      <p>{{faq.answer}}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <h1 #payment>Manage payments</h1>

            <div class="mainfaq_section">
              <div class="accordion" id="accordionExample1">
                <div class="accordion-item" *ngFor="let faq of getFAQ('payment'); let i = index">
                  <h2 class="accordion-header" id="headingOne1{{i}}">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                            [attr.data-bs-target]="'#collapseOne' + i" aria-expanded="true" aria-controls="collapseOne">
                      {{faq.question}}
                    </button>
                  </h2>
                  <div id="collapseOne{{i}}" class="accordion-collapse collapse" aria-labelledby="headingOne"
                       data-bs-parent="#accordionExample1">
                    <div class="accordion-body">
                      <p>{{faq.answer}}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <h1 #social>Social features</h1>

            <div class="mainfaq_section">
              <div class="accordion" id="accordionExample2">
                <div class="accordion-item" *ngFor="let faq of getFAQ('social'); let i = index">
                  <h2 class="accordion-header" id="headingOne11{{i}}">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                            [attr.data-bs-target]="'#collapseOne2' + i" aria-expanded="true"
                            aria-controls="collapseOne">
                      {{faq.question}}
                    </button>
                  </h2>
                  <div id="collapseOne2{{i}}" class="accordion-collapse collapse" aria-labelledby="headingOne11"
                       data-bs-parent="#accordionExample2">
                    <div class="accordion-body">
                      <p>{{faq.answer}}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="contact_us support_content">
    <div class="container footer_bg_sec">
      <div class="row">
        <div class="col-md-12 col-lg-8 order-2 order-lg-1">
          <div class="contact_detail">
            <h1>Can't find what you are looking for?</h1>
            <p>Contact us!</p>
            <div class="cont_fields">
              <div class="name_fields">
                <div class="name_inputfield">
                  <label>Name</label>
                  <input type="text" placeholder="Your name here"/>
                </div>
                <div class="name_inputfield">
                  <label>Email</label>
                  <input type="Email" placeholder="email@example.com"/>
                </div>
              </div>
              <div class="msg_field">
                <textarea
                  placeholder="What do you wanna talk about?"
                ></textarea>
              </div>
              <div class="submit_btn">
                <button class="btn-action">Submit now</button>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-12 col-lg-4 order-1 order-lg-2">
          <div class="contact_icon text-right">
            <img src="assets/images/message.svg"/>
          </div>
        </div>
      </div>
      <frontend-footer></frontend-footer>
    </div>
  </section>
</div>

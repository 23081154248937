import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {StoreModule} from "@ngrx/store";
import * as fromAuth from "@frontend/auth-store";
import {EffectsModule} from "@ngrx/effects";
import {AuthEffects} from "@frontend/auth-store";

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('auth', fromAuth.reducer),
    EffectsModule.forFeature([AuthEffects])],
})
export class AuthStoreModule {
}

import {Component, OnInit} from '@angular/core';
import {PhoneNumberFormat, CountryISO} from 'ngx-intl-tel-input';
// import {ToastrService} from 'ngx-toastr';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';

@Component({
  selector: 'frontend-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss'],
})
export class ContactUsComponent implements OnInit {
  contactForm!: UntypedFormGroup;
  PhoneNumberFormat = PhoneNumberFormat;
  CountryISO = CountryISO;
  selectedPhoneCountry: any;
  reasonId: any;
  contactData: any;

  contactUs: any[] = [
    {
      id: '6305f85e3b398ee0892d87c0',
      name: 'Request/Suggestion',
    },
    {
      id: '6305f8fd3b398ee0892d87c5',
      name: 'Ask Question',
    },
    {
      id: '6305fcbb840878a2da9b44cc',
      name: 'Raise Complaint',
    },
  ];

  constructor(
    private formBuilder: UntypedFormBuilder,
    // private toastr: ToastrService,
    // private authService: AuthService
  ) {
  }

  ngOnInit(): void {
    window.scroll(0, 0);
    this.contactForm = this.formBuilder.group({
      name: ['', Validators.required],
      email: [
        '',
        [
          Validators.required,
          Validators.pattern(
            RegExp(/^[a-zA-z0-9._%+-]+@[a-zA-z0-9.-]+\.[a-zA-z]{2,4}$/)
          ),
        ],
      ],
      phone_number: [''],
      reason_id: [null, Validators.required],
      message: ['', Validators.required],
    });
  }

  reasonData(id: any) {
    this.reasonId = id.value;
  }

  get form() {
    return this.contactForm?.controls;
  }

  // onSubmitContact() {
  //   if (this.contactForm.invalid) {
  //     return;
  //   }
  //   this.authStore.dispatch(
  //     contactUs({
  //       body: {
  //         name: this.contactForm.value.name
  //           ? this.contactForm.value.name
  //           : '',
  //         email: this.contactForm.value.email
  //           ? this.contactForm.value.email
  //           : '',
  //         reason_id: this.reasonId,
  //         message: this.contactForm.value.message,
  //         phone_number: this.contactForm.value.phone_number?.number || '',
  //       },
  //     })
  //   );

  //   this.toastr.success(
  //     `Thank you for contacting with us . You will recieve a email soon on ${this.contactForm.value.partner_email}`
  //   );
  //   this.contactForm.reset();
  //   this.bsModalRef.hide();
  // }

  onSubmitContact() {
    // TODO:- UPDATED THE CODE.
    // const payload = {
    //   name: this.contactForm.value.name ? this.contactForm.value.name : '',
    //   email: this.contactForm.value.email ? this.contactForm.value.email : '',
    //   // reason_id: '6305fcbb840878a2da9b44cc',
    //   reason_id: this.reasonId,
    //   message: this.contactForm.value.message,
    //   phone_number: (this.contactForm.value.phone_number?.number || '').replace(
    //     /\s/g,
    //     ''
    //   ),
    // };
    //
    // this.authService.contactUs(payload).subscribe({
    //   next: (res: any) => {
    //     this.contactData = res;
    //     this.toastr.success(res.message);
    //     this.contactForm.reset();
    //   },
    //   error: (error: any) => {
    //     this.toastr.error(error.error.message, '', {
    //       timeOut: 2000,
    //       positionClass: 'toast-top-right',
    //       progressBar: true,
    //     });
    //   },
    // });
  }
}

import {Component, ElementRef, HostListener, Inject} from '@angular/core';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {SwiperOptions} from 'swiper';
import {PrivacyTermComponent} from '@frontend/shared';
import {APP_CONFIG} from "@frontend/app-config";
import {ActivatedRoute} from "@angular/router";
import {select, Store} from "@ngrx/store";
import {getArtistDetails, GetArtistDetails, PublicState} from "@frontend/public-store";
import {Subject, takeUntil} from "rxjs";

@Component({
  selector: 'frontend-profile-page',
  templateUrl: './artist-profile-page.component.html',
  styleUrls: ['./artist-profile-page.component.scss'],
})
export class ArtistProfilePageComponent {
  OrganiceMusicConfig: SwiperOptions = {
    autoplay: false,
    spaceBetween: 15,
    breakpoints: {
      1199: {
        slidesPerView: 6,
      },
      991: {
        slidesPerView: 6,
      },
      767: {
        slidesPerView: 4,
      },
      375: {
        slidesPerView: 2.3,
      },
      320: {
        slidesPerView: 2.3,
      },
    },
  };
  OrganiceMusicConfigtwo: SwiperOptions = {
    autoplay: false,
    spaceBetween: 15,
    breakpoints: {
      1199: {
        slidesPerView: 6,
      },
      991: {
        slidesPerView: 6,
      },
      767: {
        slidesPerView: 4,
      },
      375: {
        slidesPerView: 2.3,
      },
      320: {
        slidesPerView: 2.3,
      },
    },
  };

  TutorialConfig: SwiperOptions = {
    autoplay: false,
    spaceBetween: 15,
    breakpoints: {
      992: {
        slidesPerView: 3,
      },
    },
  };

  faqs = [
    'How to upload your music to Kwot.',
    'Sharing your music on other platforms.',
    'Payment model for artists.',
    'Managing your artist profile',
  ];

  isShowAll = false;
  artistId: any = null;
  artist: any = null;
  unsubscriber = new Subject();

  showAllFaqs = () => {
    this.isShowAll = !this.isShowAll;
  };

  constructor(
    private modelService: BsModalService,
    @Inject(APP_CONFIG) public appConfig: any,
    private elementRef: ElementRef,
    private route: ActivatedRoute,
    private publicStore: Store<PublicState>
  ) {
    this.route.paramMap.subscribe(params => {
      // Extract the ID parameter from the URL
      this.artistId = params.get('id');
      if (this.artistId) {
        this.publicStore.dispatch(GetArtistDetails({artist_id: this.artistId}))
      }
    });

    this.publicStore
      .pipe(select(getArtistDetails))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe((artist) => {
        if (artist) {
          this.artist = artist;
        }
      });
  }

  ngOnInit() {
    const sectionId = window.location.hash;
    if (sectionId) {
      setTimeout(() => {
        const section = this.elementRef.nativeElement.querySelector(sectionId);
        if (section) {
          section.scrollIntoView({ behavior: 'smooth' });
        }
      },600)
    }
  }

  showPrivacyTerms(activeTab: string) {
    const modelRef: BsModalRef = this.modelService.show(PrivacyTermComponent, {
      keyboard: true,
      animated: true,
      ignoreBackdropClick: false,
      class: 'modal-xl modal-dialog-centered',
      initialState: {
        showHeader: false
      }
    });
    modelRef.content.activeTab = activeTab;
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    const number =
      window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop ||
      0;
    if (number > 100) {
      document.querySelectorAll('.appendClass').forEach((c) => {
        c.classList.add('text-smaller');
      });
    } else {
      document.querySelectorAll('.appendClass').forEach((c) => {
        c.classList.remove('text-smaller');
      });
    }
  }

  ngOnDestroy() {
    this.unsubscriber.next(true);
    this.unsubscriber.complete();
  }

}
